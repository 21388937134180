import * as d3 from "d3";
import { scoreToColor } from "../helpers/chartHelpers";


const prepareData = (data) => {
  const categoryNames = {
    juniors: 'Jr. Teammates',
    peers: 'Peers',
    seniors: 'Sr. Teammates',
    clients: 'Clients'
  };

  return Object.entries(data.categories).map(([key, categoryItems]) => {
    return {
      category: categoryNames[key],
      total_credence: d3.sum(categoryItems, (d) => d.credence),
      mean_score: d3.mean(categoryItems, (d) => d.score),
    };
  });
};

export const drawDonutChart = (chartGroup, inputData, dimensions) => {

  const data = prepareData(inputData);
  const radius = Math.min(dimensions.width, dimensions.height) / 2;
  const cornerRadius = 5;

  // sort data in descending order based on 'total'
  const sortedData = [...data].sort((a, b) => b.total_credence - a.total_credence);

  // Find the largest slice and its angle position
  const largestSlice = sortedData[0];
  const categoryToAngle = {
    'Sr. Teammates': 0,
    'Clients': 90,
    'Jr. Teammates': 180,
    'Peers': 270,
  };
  const largestSliceAngle = categoryToAngle[largestSlice.category];


  const color = d3.scaleOrdinal()
    .domain(data.map(d => d.category))
    .range(data.map(d => scoreToColor(d.mean_score)));

  // Calculate the angles used by d3.pie for each slice based on
  // total multipy by credence
  const pie = d3.pie()
    .value(d => d.total_credence)
    .sort(null);

  const arc = d3.arc()
    .cornerRadius(cornerRadius)
    .innerRadius(radius * 0.8)
    .outerRadius(radius * 0.86);

  const arcs = pie(data);

  // Calculate the midpoint angle of the largest slice
  let largestArc;
  for (const arc of arcs) {
    if (arc.data.category === largestSlice.category) {
      largestArc = arc;
      break;
    }
  }
  const largestArcMidpoint = ((largestArc.endAngle - largestArc.startAngle) / 2) + largestArc.startAngle;

  // Calculate the offset angle in radians
  const offsetAngle = (Math.PI / 180) * (largestSliceAngle - largestArcMidpoint * (180 / Math.PI));
  // Update pie generator with new start and end angles
  pie.startAngle(offsetAngle).endAngle(offsetAngle + 2 * Math.PI);
  const updatedArcs = pie(data);
  // remove empty slices from the pie chart
  const filteredArcs = updatedArcs.filter(d => d.data.total_credence > 0);
  const isSingleSlice = filteredArcs.length === 1;

  chartGroup.selectAll('allSlices')
    .data(updatedArcs)
    .enter()
    .append('path')
    .attr('class', 'donutSlice')
    .attr('d', arc)
    .attr('fill', d => color(d.data.category))
    .attr('stroke', 'white')
    .style('stroke-width', isSingleSlice? '0px':'3px')
    .style('opacity', 1)
    .each(function(d, i){
      const pathD = d3.select(this).attr("d");
      const matches = /(^.+?)L/.exec(pathD);
      const newArc = (matches && matches[1]) ? matches[1] : pathD;
      chartGroup.append("path")
        .attr("class", "hiddenDonutArcs")
        .attr("id", "donutArc"+i)
        .attr("d", newArc)
        .style("fill", "none");
    });

  chartGroup.selectAll(".donutText")
    .data(data)
    .enter().append("text")
    .attr("class", "donutText")
    .attr("dy", -13)
    .append("textPath")
      .attr("startOffset", isSingleSlice ? "25.5%" : "50%")
      .style("text-anchor", "middle")
      .attr("xlink:href", function(d, i) { return "#donutArc" + i; })
      .text(d => d.category);

  // return arc angles and offset angle
  return {
    arcAngles: updatedArcs.map(d => d.endAngle - d.startAngle),
    offsetAngle: offsetAngle
  }
}
