import React from 'react';

const ReflectionBody = ({ children }) => {
    return (
        <div className="reflection_body relative flex flex-column gap-5 align-items-center justify-content-center">
            {children}
        </div>
    );
};

export default ReflectionBody;
