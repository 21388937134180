import { scoreToColor } from "../helpers/chartHelpers";
import * as d3 from "d3";

const extractScore = (data) => {
  const scores = Object.values(data.categories).map(categoryItems => {
    return d3.mean(categoryItems, (d) => d.score);
  });
  return d3.mean(scores);
};

export const drawScoreCircle = (chartGroup, data, radius = 60) => {
  // Draw circle in the middle of the radial chart with score out of total
  const totalScore = data.totalScore;
  const meanScore = extractScore(data);

  const scoreGroup = chartGroup.append('g');

  scoreGroup.append('circle')
    .attr('class', 'scoreCircle')
    .attr('r', radius)
    .attr('fill', 'black')
    .attr('stroke', scoreToColor(meanScore))
    .attr('stroke-width', '10px')
    .attr('transform', `translate(${0}, ${0})`);

  const scoreText = scoreGroup.append('text')
    .attr('class', 'scoreText')
    .attr('text-anchor', 'middle')
    .attr('font-family', 'Montserrat')
    .attr('fill', 'white')
    .attr('alignment-baseline', 'middle');

  scoreText.append('tspan')
    .attr('font-size', '25px')
    .attr('font-family', 'Montserrat')
    .attr('font-weight', 'bold')
    .attr('x', 0)
    .attr('y', 7)  // Slightly shift upwards
    .text(totalScore);

  scoreText.append('tspan')
    .attr('font-size', '12px')
    .attr('font-family', 'Montserrat')
    .attr('font-weight', 'normal')
    .attr('x', 0)
    .attr('dy', '18')
    .text('/360');
};