import React from 'react';

const AuthWrapper = ({ children, className }) => {
    return (
        <div className={`auth_wrapper w-full lg:w-6 flex flex-column gap-5 align-items-center justify-content-between p-3 md:p-5 ${className}`}>
            {children}
        </div>
    );
};

export default AuthWrapper;
