import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../header/Header";
import { Dialog } from "primereact/dialog";
import ReflectorsLayout from "./ReflectorsLayout";
import ReflectorsContainer from "./ReflectorsContainer";
import AddRelationshipForm from "./AddRelationshipForm";
import EditRelationshipForm from "./EditRelationshipForm";
import {
  RelationshipsState,
  createRelationship,
  deleteRelationship,
  loadRelationships,
  updateRelationship,
} from "../store_providers/relationships-store";
import { Toast } from "primereact/toast";
import AddReflectorsMobile from "./AddReflectorsMobile";
import Reflectors7Dialog from "./Reflectors7Dialog";
import AddReflectorsDesktop from "./AddReflectorsDesktop";
import { useScreenDetector } from "../../hooks/useScreenDetector";
import { UserDataState } from "../store_providers/user-data-store";
import Loading from "../Loading";

function AddReflectors() {
  const [count, setCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [editingCircle, setEditingCircle] = useState({});
  const [relationships, dispatch] = useContext(RelationshipsState);
  const [fetchedFromApi, setFetchedFromApi] = useState(false);
  const { isMobile } = useScreenDetector();
  const [position, setPosition] = useState("center");
  const [userData] = useContext(UserDataState);
  const [loading, setLoading] = useState(true)
  const toast = useRef(null);

  useEffect(() => {
    async function loadData() {
      const relationshipsFromApi = await loadRelationships();
      //console.log(relationshipsFromApi);

      setLoading(false)
      if (relationshipsFromApi) {
        setCount(relationshipsFromApi.length);
        dispatch({ type: "set", payload: relationshipsFromApi });
      }

      setFetchedFromApi(true);
    }

    if (!fetchedFromApi && (!relationships || relationships.length === 0)) {
      loadData();
    }else{
      setLoading(false)
    }
  }, [dispatch, relationships, fetchedFromApi]);

  useEffect(() => {
    setCount(relationships.length);
  }, [relationships]);

  let maxValue = 7;

  if (count >= maxValue) {
    maxValue = userData.reflectorsCap;
  }

  function setEditRelationship(relationship) {
    setEditingCircle(relationship);

    setVisible2(true);
  }

  function hideAndInc() {
    setVisible(false);
  }

  async function onRelationshipAddSubmit(values) {
    const newRelationship = await createRelationship(values).catch((err) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err.message,
        life: 5000,
      });
      return null;
    });

    if (newRelationship) {
      dispatch({ type: "add", payload: newRelationship });
      hideAndInc();
    }
  }

  async function onRelationshipEditSubmit(values) {
    const updatedRelationship = await updateRelationship(values);

    dispatch({ type: "update", payload: updatedRelationship });
    setVisible2(false);
  }

  async function onDeleteRelationship(relationship) {
    const didDelete = await deleteRelationship(relationship.id);

    if (didDelete) {
      dispatch({ type: "delete", payload: relationship });
    }
    setVisible2(false);
  }

  return (
    <Loading visible={loading}>
    <ReflectorsLayout>
      <Header />
      <ReflectorsContainer variant="setup">
        {isMobile ? (
          <AddReflectorsMobile
            count={count}
            maxValue={maxValue}
            relationships={relationships}
            setEditRelationship={setEditRelationship}
            showAdd={setVisible}
            show7Dialog={setVisible1}
            position={setPosition}
          />
        ) : (
          <AddReflectorsDesktop
            count={count}
            maxValue={maxValue}
            relationships={relationships}
            setEditRelationship={setEditRelationship}
            showAdd={setVisible}
            show7Dialog={setVisible1}
          />
        )}
      </ReflectorsContainer>
      <Dialog
        position={isMobile ? "bottom" : "center"}
        className="z-2 w-full max-w-lg p-input-filled text-center"
        visible={visible1}
        headerStyle={{ padding: "0" }}
        modal={true}
        style={{ width: "40rem" }}
        onHide={() => setVisible1(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <Reflectors7Dialog />
      </Dialog>
      <Dialog
        position={isMobile ? "bottom" : "center"}
        className="w-full max-w-lg p-input-filled text-center"
        header="Whose perspective matters to you?"
        headerStyle={{ paddingLeft: "4rem" }}
        visible={visible}
        modal={true}
        style={{ width: "40rem" }}
        onHide={() => setVisible(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <AddRelationshipForm
          onSubmit={onRelationshipAddSubmit}
          allowEditingType={false}
        />
      </Dialog>
      <Dialog
        position={isMobile ? "bottom" : "center"}
        id="woho"
        className="w-full max-w-lg p-input-filled text-center"
        header="Modify Your Reflector"
        headerStyle={{
          alignItems:'left',
          paddingLeft: isMobile ? "" : "4rem",
          paddingBottom: "1rem"
        }}
        visible={visible2}
        modal={true}
        style={{ width: "40rem" }}
        onHide={() => setVisible2(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <EditRelationshipForm
          user={editingCircle}
          onSubmit={onRelationshipEditSubmit}
          onDelete={onDeleteRelationship}
        />
      </Dialog>
      <Toast position="bottom-center" ref={toast} />
    </ReflectorsLayout>
    </Loading>
  );
}

export default AddReflectors;
