import React from "react";
import TooltipSlider from "./TooltipSlider";
import Questions from "./Questions";

function QuestionsContainer({ text, questionId, isSelf }) {
  return (
    <div className="flex justify-content-center align-items-center gap-2 lg:gap-3 mb-3">
      <Questions text={text} />
      {!isSelf && <TooltipSlider questionId={questionId} />}
    </div>
  );
}

export default QuestionsContainer;
