import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from "primereact/tooltip";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ReflectorsWrapper from "./ReflectorsWrapper";
import YouCircle from "./YouCircle";
import ReflectorsSidebar from "./ReflectorsSidebar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { DataTable } from "primereact/datatable";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { RelationshipProximity } from "../../service/enums";
import PersonIcon from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Dropdown } from "primereact/dropdown";
import MirrorImageProgress from "./MirrorImageProgress";
import { UserDataState } from "../store_providers/user-data-store";
import { Button } from "primereact/button";

function DesktopDashboard({
  count,
  circles,
  onCellClicked,
  filters,
  globalFilterValue,
  onGlobalFilterChange,
  setEditRelationship,
  setVisible,
  setVisible2,
  types,
  updateRelationship,
  dispatch,
}) {
  const renderFooter = () => {
    return "Total rows: " + (circles?.length ?? 0);
  };
  const [userData] = useContext(UserDataState);

  const statusBodyTemplate = (circle) => {
    return (
      <Dropdown
        className="dropdownTableOverview"
        value={circle.relationshipType}
        options={types}
        optionLabel="name"
        optionValue="id"
        style={{ fontFamily: 'Open Sans', backgroundImage: "none",backgroundColor:'inherit' }}
        onChange={async (e) => {
          const updatedRelationship = await updateRelationship({
            ...circle,
            relationshipType: e.value,
          });

          dispatch({ type: "update", payload: updatedRelationship });
          setVisible2(false);
        }}
      />
    );
  };

  const proximityBodyTemplate = (circle) => {
    if (circle.relationshipProximity === RelationshipProximity.Often) {
      return (
        <div className={`cursor-pointer flex flex-column text-lg proximityColor`}>
          <div className="flex ">
            <PersonIcon fontSize="inherit" />
            <PersonIcon fontSize="inherit" />
          </div>
        </div>
      );
    } else if (
      circle.relationshipProximity === RelationshipProximity.Occasionally
    ) {
      return (
        <div className={`cursor-pointer flex flex-column text-lg proximityColor`}>
          <div className="flex ">
            <PersonIcon fontSize="inherit" />
            <MoreHorizIcon fontSize="inherit" />
            <PersonIcon fontSize="inherit" />
          </div>
        </div>
      );
    } else {
      return (
        <div className={`cursor-pointer flex flex-column text-lg proximityColor`}>
          <div className="flex ">
            <PersonIcon fontSize="inherit" />
            <span className="flex p-0">
              <MoreHorizIcon fontSize="inherit" className="rightDots" />
              <MoreHorizIcon fontSize="inherit" />
            </span>
            <PersonIcon fontSize="inherit" />
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <ReflectorsWrapper>
        <div className="reflectors_cross-wrapper">
          <div className="reflectors_cross-bar reflectors_cross-bar-left" />
          <div className="reflectors_cross-bar reflectors_cross-bar-right" />
        </div>
        <div className="circle_wrapper flex flex-column align-items-center justify-content-center">
          <div className=" n-circle flex align-items-center justify-content-center">
            {circles?.map((reflector, index) => (
              <div
                key={index}
                className={`db-circle circle-${reflector.id} tooltip-button-${index} text-900 font-semibold circle `}
                onClick={() => {
                  setEditRelationship(reflector);
                }}
              >
                <Tooltip
                  event="both"
                  target={`.tooltip-button-${index}`}
                  autoHide={false}
                >
                  <div className="flex flex-column align-items-center ">
                    <div className="font-bold text-xs">
                      {reflector.reflectorAlias}
                    </div>
                    <div
                      onClick={() => {
                        setEditRelationship(reflector);
                      }}
                      className="cursor-pointer text-primary"
                    >
                      (Click to edit)
                    </div>
                  </div>
                </Tooltip>
                <div className="text-sm">{reflector.initials}</div>
              </div>
            ))}
          </div>
          <div className="big-rectangle big-rectangle-1"></div>
          <div className="big-rectangle big-rectangle-2"></div>
          <div className="big-rectangle big-rectangle-3 "></div>
          <div className="big-rectangle big-rectangle-4 ">
            <div className="rectangle-words word-peers text-sm opacity-60">
              PEERS
            </div>
            <div className="rectangle-words word-clients text-sm opacity-60">
              CLIENTS
            </div>
            <div className="rectangle-words word-senior text-sm opacity-60">
              SENIOR
            </div>
            <div className="rectangle-words word-junior text-sm opacity-60">
              JUNIOR
            </div>
          </div>
          <YouCircle
            count={userData.reflectorsCap}
            maxValue={userData.reflectorsCap}
          />
        </div>
      </ReflectorsWrapper>
      <ReflectorsSidebar>
        <div className="flex flex-column gap-2">
          <div className="flex flex-column gap-5">
            <div className="flex flex-row gap-5 align-items-center justify-content-between">
              <h1 className="font-bold">YOUR REFLECTORS</h1>
              <div className="flex flex-row gap-3 align-items-center">
                <div className="font-montserrat">
                  <span className="text-2xl font-bold">{circles.length}</span> /
                  <span className="text-1xl"> {userData.reflectorsCap}</span>
                </div>
                <HelpOutlineIcon
                  fontSize="inherit"
                  className="cursor-pointer tooltip-help-top"
                />
                <Tooltip
                  position="bottom"
                  event="both"
                  target=".tooltip-help-top"
                  autoHide={true}
                  style={{ width: "100%", maxWidth: "20rem" }}
                >
                  <p className="p-2 text-xs">
                    To increase the cap on your reflectors, take a moment to
                    self-reflect (each week you do so, the cap will increase by
                    one).
                    <br />
                    <br />
                    Watch for self-reflection invitations on Fridays. Over time,
                    this cap can reach up to 36 reflectors!
                  </p>
                </Tooltip>
              </div>
            </div>
            <DataTable
              onSelectionChange={onCellClicked}
              value={circles}
              footer={renderFooter}
              tableStyle={{
                minWidth: "24rem",
                width: "100%",
                fontSize: "0.8rem",
              }}
              scrollable
              scrollHeight="20rem"
              style={{ fontFamily: "Montserrat" }}
              cellSelection
              selectionMode="single"
              filters={filters}
              filterDisplay="none"
              removableSort
              globalFilterFields={[
                "name",
                "country.name",
                "representative.name",
                "status",
              ]}
              emptyMessage="No reflectors found."
            >
              <Column field="reflectorAlias" sortable header="Name"></Column>
              <Column
                field="relationshipProximity"
                body={proximityBodyTemplate}
                header="Proximity"
                sortable
              ></Column>
              <Column
                field="relationshipType"
                body={statusBodyTemplate}
                sortable
                header="Relation to you"
              />
            </DataTable>
          </div>
          <div className="flex gap-2 justify-content-between pt-2 ">
            <Button
              className="font-bold"
              label="Add more"
              text
              icon={<AddIcon font="inherit" />}
              onClick={() => setVisible(true)}
              disabled={userData.reflectorsCap === circles.length}
            ></Button>

            <div className=" p-input-icon-right align-items-center flex">
              <InputText
                className="bg-white border-none"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Keyword Search"
              />
              <SearchIcon />
            </div>
          </div>
        </div>
        <MirrorImageProgress />
      </ReflectorsSidebar>
    </>
  );
}

export default DesktopDashboard;
