import React from 'react';

const AuthLayout = ({ children }) => {
    return (
        <div className="auth_layout flex flex-row h-full">
            {children}
        </div>
    );
};

export default AuthLayout;
