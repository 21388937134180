import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import completed from "../../assets/completed.png";
import done from "../../assets/done.png";
import { Button } from "primereact/button";
import Logo from "../Logo";
import backgroundImage from "../../assets/fulfillment_bg-img.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MirrorImageProgress from "../addReflectors/MirrorImageProgress";
import { AppHttp } from "../../service/app-http";
import { isSelfAwarenessReportReadyEndpoint } from "../../service/api-temp";

function CompletedSelf() {
  const completedStyle = {
    maxWidth: "40rem",
  };

  const [isSelfAwarenessReportReady, setIsSelfAwarenessReportReady] =
    useState(false);
  let navigate = useNavigate();
  const location = useLocation();

  if (!location?.state?.token) {
    navigate("/dashboard/reflectors-overview");
  }

  useEffect(() => {
    AppHttp.get(isSelfAwarenessReportReadyEndpoint, {
      token: location?.state?.token,
    }).then((result) => {
      if (result.success) {
        setIsSelfAwarenessReportReady(result.data);
      }
    });
  }, [location?.state?.token]);

  return (
    <div className="min-h-screen flex flex-column align-items-center gap-5 ">
      <div
        className="absolute w-full h-full bg-cover lg:bg-left-bottom bg-center-bottom z-0"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <Link
        to="https://www.mymirror360.com/"
        target="_blank"
        className="relative mt-5 mx-auto mb-auto"
      >
        <Logo />
      </Link>
      <div className="relative w-full flex flex-column align-items-center gap-5 my-auto px-5 z-2">
        <div className="flex flex-column gap-3">
          <img
            style={completedStyle}
            className="hidden w-full xl:block relative"
            alt="Completed"
            src={completed}
          ></img>
          <img
            style={completedStyle}
            className="w-full block relative xl:hidden"
            alt="Done"
            src={done}
          ></img>
          <div className="text-center text-sm">
            You have <span className="font-bold">already submitted</span> your
            self-reflection this week!
          </div>
        </div>
        <div className="flex flex-column align-items-center md:flex-row gap-2">
          <Link to="/dashboard/reflectors-overview">
            <Button label="Your Reflectors" outlined></Button>
          </Link>
          {isSelfAwarenessReportReady && (
            <Link to="/dashboard/self-awareness-report">
              <Button label="See your Mirror Image"></Button>
            </Link>
          )}
        </div>
        {!isSelfAwarenessReportReady && (
          <div className="max-w-sm mx-auto">
            <MirrorImageProgress token={location?.state?.token} />
          </div>
        )}
      </div>
      <Footer className="w-full z-2"></Footer>
    </div>
  );
}

export default CompletedSelf;
