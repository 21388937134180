import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import backgroundImage from "../assets/auth_bg-full-img.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AppHttp } from "../service/app-http";
import { subscribeUrl, unsubscribeUrl } from "../service/api-temp";
import { Button } from "primereact/button";

function Unsubscribe() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isSubscribed, setIsSubscribed] = useState(true);

  useEffect(() => {
    if (!searchParams.has("token")) {
      navigate("/dashboard/reflectors-overview");
    }

    unsubscribe();
  }, []);

  function resubscribe() {
    AppHttp.get(subscribeUrl, {
      token: searchParams.get("token"),
    }).then((result) => {
      setIsSubscribed(result.success);
    });
  }

  function unsubscribe() {
    AppHttp.get(unsubscribeUrl, {
      token: searchParams.get("token"),
    }).then((result) => {
      setIsSubscribed(!result.success);
    });
  }

  const unsubscribedText = (
    <>
      <div className="relative z-2 max-w-md mx-auto mt-8 px-4 md:px-0">
        <p>
          You've Been Unsubscribed We're sorry to see you go. Your email has
          been successfully removed from our list. We appreciate the time you
          spent with us and hope you had a valuable experience. Remember, you're
          always welcome back anytime. If there was anything specific you
          weren't satisfied with, we'd love to hear your feedback. It helps us
          improve and serve our users better. Thank you for being a part of our
          community. Wishing you all the best in your future endeavors.
        </p>
        <p className="mt-3">Warm regards,</p>
        <p className="font-bold">My Mirror 360</p>
        <Button
          link
          className="block text-center mt-4 text-sm w-full"
          onClick={resubscribe}
        >
          Click here to resubscribe
        </Button>
      </div>
    </>
  );

  const subscribedText = (
    <>
      <div className="relative z-2 max-w-md mx-auto mt-8 px-4 md:px-0">
        <p>You have been resubscribed!</p>
        <Button
          link
          className="block text-center mt-4 text-sm w-full"
          onClick={unsubscribe}
        >
          Click here to unsubscribe
        </Button>
      </div>
    </>
  );

  return (
    <div className="flex h-full flex-column min-h-screen relative lg:gap-0 gap-5">
      <div
        className="absolute w-full h-full bg-cover lg:bg-left-bottom bg-center-bottom z-0"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <Link
        to="https://www.mymirror360.com/"
        target="_blank"
        className="relative z-2 mt-5 mx-auto"
      >
        <Logo />
      </Link>
      {isSubscribed ? subscribedText : unsubscribedText}
    </div>
  );
}

export default Unsubscribe;
