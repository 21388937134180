import React from "react";

const ReflectorsMobar = ({ children }) => {
  return (
    <aside className="reflectors_mobar fixed z-4 bottom-0 left-0 right-0 p-input-filled flex flex-column justify-content-between  px-3 md:px-5 pt-2 pb-3">
      {children}
    </aside>
  );
};

export default ReflectorsMobar;
