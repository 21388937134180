import React from 'react';

const ReflectorsWrapper = ({ children }) => {
    return (
        <div className="reflectors_wrapper relative align-items-center flex flex-column sm:gap-5 justify-content-center p-5 overflow-hidden">
            {children}
        </div>
    );
};

export default ReflectorsWrapper;
