import React from "react";
import backgroundImage from "../../assets/auth_bg-img.webp";
import SignupRigtSideContent from "./SignupRigtSideContent";

function SignupRightSide() {
  return (
    <div
      className="w-6 hidden lg:flex flex-column align-items-center justify-content-center text-left bg-cover bg-right-bottom p-5"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <SignupRigtSideContent></SignupRigtSideContent>
    </div>
  );
}

export default SignupRightSide;
