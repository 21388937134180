import { Tooltip } from "primereact/tooltip";
import React from "react";

function TooltipSlider({ questionId }) {
  const textForQuestionId1 = () => {
    return (
      <p className="p-2 text-xs">
        Consider their propensity to <strong>consistently convey</strong>{" "}
        information <strong>clearly and concisely</strong>,{" "}
        <strong>willingness to listen</strong>,{" "}
        <strong>openness to feedback</strong>, and likelihood to respond in a{" "}
        <strong>thoughtful and respectful </strong>manner.
      </p>
    );
  };

  const textForQuestionId2 = () => {
    return (
      <p class="p-2 text-xs">
        Consider their<strong> </strong>likelihood to
        <strong> identify, analyze, and overcome </strong>new and challenging
        situations, leveraging:{" "}
        <strong>
          critical thinking, logical reasoning, creativity, resourcefulness
        </strong>
        , overall <strong>knowledge and experience, </strong>and their
        propensity to <strong>work independently</strong> and{" "}
        <strong>make sound decisions.</strong>
      </p>
    );
  };

  const textForQuestionId3 = () => {
    return (
      <p class="p-2 text-xs">
        Consider their adaptability to{" "}
        <strong>work harmoniously and respectfully</strong> with diverse
        personalities and perspectives, their{" "}
        <strong>positive and cooperative attitude</strong>, their willingness to{" "}
        <strong>contribute proactively </strong>while respecting the
        contributions of others, and their{" "}
        <strong>commitment to the team’s vision</strong> and objectives.
      </p>
    );
  };

  const textForQuestionid4 = () => {
    return (
      <p class="p-2 text-xs">
        Consider their
        <strong> level of dependability, integrity and professionalism</strong>,
        their willingness to <strong>hold themselves accountable</strong>,
        propensity to <strong>stay organized and on-track</strong>, and their
        consistent
        <strong> attention to detail, precision, and accuracy </strong>in their
        tasks and responsibilities.
      </p>
    );
  };

  const textForQuestionId5 = () => {
    return (
      <p class="p-2 text-xs">
        Consider their likelihood to <strong>take initiative</strong> and new{" "}
        <strong>responsibilities</strong>, their willingness to
        <strong> take charge</strong> and effectiveness in{" "}
        <strong>inspiring and guiding </strong>others towards shared goals,
        their <strong>confidence and persistence</strong> as well as eagerness
        to<strong> continuously learn and grow.</strong>
      </p>
    );
  };

  function getTooltipForQuestion() {
    switch (questionId) {
      case 1:
        return textForQuestionId1();
      case 2:
        return textForQuestionId2();
      case 3:
        return textForQuestionId3();
      case 4:
        return textForQuestionid4();
      default:
        return textForQuestionId5();
    }
  }

  return (
    <div className="flex">
      <svg
        className={'info' + questionId}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
      >
        <g opacity="0.8">
          <path
            d="M9.32697 12.25H7.92297V5.78904H9.32697V12.25ZM9.32697 5.03504H7.92297V3.54004H9.32697V5.03504Z"
            fill="black"
          />
          <rect x="1" y="0.5" width="15" height="15" rx="7.5" stroke="black" />
        </g>
      </svg>
      <Tooltip position="bottom" className="w-full max-w-20rem" target={'.info' + questionId}>
        {getTooltipForQuestion()}
      </Tooltip>
    </div>
  );
}

export default TooltipSlider;
