import React, { useEffect, useState } from "react";
import sorryDesktop from "../../assets/sorry-desktop.png";
import sorryMoible from "../../assets/sorry-mobile.png";
import { Button } from "primereact/button";
import Logo from "../Logo";
import Footer from "../footer/Footer";
import backgroundImage from "../../assets/performance_bg-img.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppHttp } from "../../service/app-http";
import { performanceReflectionGetDataForCompleted } from "../../service/api-temp";

function Sorry() {
  const reactLocation = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  if (!reactLocation?.state?.token) {
    navigate("/dashboard/reflectors-overview");
  }

  useEffect(() => {
    async function getDataForReflection() {
      const data = await AppHttp.get(performanceReflectionGetDataForCompleted, {
        token: reactLocation.state.token,
      }).then((response) => {
        if (response.success) {
          setData(response.data);
        } else {
          navigate("/dashboard/reflectors-overview");
        }
      });
    }

    if (!data) {
      getDataForReflection();
    }
  });

  return (
    <div className="flex flex-column min-h-screen justify-content-between relative lg:gap-0 gap-5">
      <div
        className="absolute w-full h-full bg-cover lg:bg-left-bottom bg-center-bottom z-0"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <Link
        to="https://www.mymirror360.com/"
        target="_blank"
        className="relative z-2 mt-5 mx-auto mb-auto"
      >
        <Logo />
      </Link>
      <div className="my-auto align-items-center flex flex-column gap-6 md:gap-5 justify-content-between z-2 px-5 max-w-6xl mx-auto z-2">
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={sorryMoible}
            sizes="768px"
          />
          <source srcSet={sorryDesktop} sizes="1280px" />
          <img alt="" src={sorryDesktop} />
        </picture>
        <div className="text-center max-w-lg text-sm">
          This opportunity to reflect on {data?.name}
          <span className="font-bold"> has expired!</span>{" "}
          <br className="hidden lg:block" /> We may check back with you another
          time.
        </div>
        <div className="max-w-2xl flex align-items-center justify-content-between flex-wrap gap-3 max-w-lg">
          <div className="w-8 text-justify md:text-left text-xs opacity-80">
            My Mirror 360 is an innovative feedback tool that empowers you to
            drive your own growth.
          </div>
          <Link to="https://www.mymirror360.com/" target="_blank">
            <Button label="Learn More" outlined></Button>
          </Link>
        </div>
      </div>
      <Footer className="z-2"></Footer>
    </div>
  );
}

export default Sorry;
