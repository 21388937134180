import Header from "../header/Header";
import "chartjs-adapter-moment";
import Footer from "../footer/Footer";
import SidebarMobile from "./SidebarMobile";
import SidebarDesktop from "./SidebarDesktop";
import Tips from "./Tips";
import Contributors from "./Contributors";
import Trends from "./Trends";
import Feedback from "./Feedback";
import Strengths from "./Strengths";
import { useScreenDetector } from "../../hooks/useScreenDetector";
import { useEffect, useState } from "react";
import RadialMirrorImage from "./radialMirror/mirrorImage";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { AppHttp } from "../../service/app-http";
import { getSelfAwarenessReportData } from "../../service/api-temp";
import Loading from "../Loading";

function ImageLayout() {
  const [showInactivePageDialog] = useState(false);
  const navigate = useNavigate();

  const { isMobile } = useScreenDetector();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AppHttp.get(getSelfAwarenessReportData).then((result) => {
      if (result.success) {
        setData({
          ...result.data,
          lastUpdateDate: new Date(result.data.lastUpdateDate),
          fulfillmentTrends: result.data.fulfillmentTrends
            ? result.data.fulfillmentTrends.map((ft) => {
                ft.date = new Date(ft.date);

                return ft;
              })
            : null,
          performanceTrends: result.data.performanceTrends
            ? result.data.performanceTrends.map((pt) => {
                pt.date = new Date(pt.date);

                return pt;
              })
            : null,
        });
      } else {
        if (result.error.message.startsWith("Token: ")) {
          const token = result.error.message.split(" ")[1];

          navigate("/self-reflection?token=" + token, {
            state: {
              isFromAwarenessReport: true,
            },
          });
        } else {
          navigate("/");
        }
      }
    });

    setIsLoading(false);
  }, []);

  return (
    <Loading visible={isLoading}>
        <Dialog
          position="center"
          className="z-2 w-full max-w-lg p-input-filled text-center"
          visible={showInactivePageDialog}
          headerStyle={{ padding: "0" }}
          modal={true}
          style={{ width: "40rem" }}
          onHide={() => navigate("/")}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className="flex flex-column align-items-center gap-5">
            This page is currently unavailable.
            <Button label="Reflectors" onClick={() => navigate("/")} />
          </div>
        </Dialog>
        <div className="flex flex-row self-awareness_component surface-200">
          <Header>
            {isMobile && (
              <SidebarMobile
                performanceScore={data.mirror360PerformanceScore}
                fulfillmentScore={data.mirror360FulfillmentScore}
              />
            )}
          </Header>
          <SidebarDesktop
            performanceScore={data.mirror360PerformanceScore}
            fulfillmentScore={data.mirror360FulfillmentScore}
          />
          <div className="w-full">
            <main className="p-3 md:p-5 flex flex-column gap-8 mb-8 sm:mt-5 lg:mt-0 mt-8">
              {/* <MirrorImage data={result} /> */}
              <RadialMirrorImage
                data={data.mirror360ChartData}
                analyses={data.mirror360PerformanceAnalyses}
                lastUpdatedDate={data.lastUpdateDate}
              />
              <Strengths strengthsAndWeaknesses={data.strengthsAndWeaknesses} />
              <Feedback dateTo={data.lastUpdateDate} />
              <Trends
                performanceTrends={data.performanceTrends}
                fulfillmentTrends={data.fulfillmentTrends}
              />
              <Contributors
                bestCategory={data.bestCategory}
                fulfillmentCategories={data.fulfillmentCategories}
              />
              <Tips />
            </main>
            <Footer></Footer>
          </div>
        </div>
        </Loading>
  );
}

export default ImageLayout;


