import React, { useContext, useRef, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import EmailIcon from "@mui/icons-material/Email";
import { Button } from "primereact/button";
import google from "../../assets/google.png";
import linkedin from "../../assets/linked-in.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import {
  loginUrl,
  loginWithGoogleUrl,
  loginWithLinkedInUrl,
} from "../../service/api-temp";
import { setAuthDataObj } from "../../service/UserAuthData";
import { loginPageSchema } from "../../service/validationschemas/validation-schemas";
import { AppHttp } from "../../service/app-http";
import { UserDataState } from "../store_providers/user-data-store";
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";

function LoginForm() {
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [userData, dispatch] = useContext(UserDataState);
  const [loading, setLoading] = useState(false);

  const [socialLoginError, setSocialLoginError] = useState(null);

  const preventMultipleRegisterSubmits = useRef(false);
  const { linkedInLogin } = useLinkedIn({
    clientId: "77y30r1toybqpz",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile email",
    onSuccess: (code) => {
      if (preventMultipleRegisterSubmits.current) {
        return;
      }

      preventMultipleRegisterSubmits.current = true;

      setLoading(true);
      setSocialLoginError(null);

      AppHttp.post(loginWithLinkedInUrl, {
        token: code,
      }).then((response) => {
        preventMultipleRegisterSubmits.current = false;

        if (response.success) {
          setAuthDataObj({
            token: response.data.token,
            expires: response.data.expires,
          });

          delete response.data.token;
          delete response.data.expires;

          dispatch({ type: "set", payload: response.data });

          navigate("/home");
        } else {
          setSocialLoginError(response.error.message);
          setLoading(false);
        }
      });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => registerWithGoogle(tokenResponse),
    flow: "auth-code",
  });

  function signInWithGoogle() {
    googleLogin();
  }

  function registerWithGoogle(tokenResponse) {
    setLoading(true);
    setSocialLoginError(null);

    AppHttp.post(loginWithGoogleUrl, {
      token: tokenResponse.code,
    }).then((response) => {
      if (response.success) {
        setAuthDataObj({
          token: response.data.token,
          expires: response.data.expires,
        });

        delete response.data.token;
        delete response.data.expires;

        dispatch({ type: "set", payload: response.data });

        navigate("/dashboard/reflectors-overview");
      } else {
        setSocialLoginError(response.error.message);
        setLoading(false);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginPageSchema,
    onSubmit: async (values) => {
      setLoading(true);
      AppHttp.post(loginUrl, values).then((response) => {
        if (response.success) {
          setAuthDataObj({
            token: response.data.token,
            expires: response.data.expires,
          });

          delete response.data.token;
          delete response.data.expires;

          dispatch({ type: "set", payload: response.data });

          navigate("/dashboard/reflectors-overview");
        } else {
          setLoading(false);
          formik.setFieldError("email", response.error.message);
        }
      });
    },
  });

  function onRememberMeChanged(e) {
    setRememberMe(e.target.checked);
  }

  const isFormFieldInvalid = (msg) =>
    !!(formik.touched[msg] && formik.errors[msg]);

  const getFormErrorMessage = (msg) => {
    return isFormFieldInvalid(msg) ? (
      <small className={`${formik.errors[msg] ? "block" : "hidden"} p-error`}>
        {formik.errors[msg]}
      </small>
    ) : (
      ""
    );
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="max-w-sm p-input-filled flex flex-column gap-5"
    >
      <div className="flex flex-row gap-3 align-items-center justify-content-center mb-5">
        <h1 className="text-2xl">Log in</h1>
        <div className="horizontal-div" />
        <Link to="/auth/redeem-invite" className="no-underline text-900">
          <h2 className="text-2xl opacity-50">Sign up</h2>
        </Link>
      </div>
      <div className="flex flex-column gap-3">
        <div className="flex flex-column gap-2">
          <label htmlFor="Email" className="text-xs opacity-60">
            Email*
          </label>
          <div className="p-input-icon-left">
            <EmailIcon fontSize="inherit" />
            <InputText
              className={classNames({
                "p-invalid": !!formik.errors.email && !!formik.touched.email,
              })}
              placeholder="name@company.com"
              type="email"
              aria-label="email"
              name="email"
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
              }}
            />
          </div>
          {getFormErrorMessage("email")}
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="Password" className="text-xs opacity-60">
            Password*
          </label>
          <div className="p-input-icon-left">
            <LockIcon fontSize="inherit" className="z-1" />
            <Password
              className={classNames({
                "w-full p-invalid":
                  !!formik.errors.password && !!formik.touched.password,
              })}
              inputId="in_value"
              placeholder="********"
              type="password"
              aria-label="password field"
              name="password"
              value={formik.values.password}
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value);
              }}
              hideIcon={<VisibilityOffIcon fontSize="inherit" />}
              showIcon={<VisibilityIcon fontSize="inherit" />}
              feedback={false}
              toggleMask
              inputStyle={{ paddingLeft: "40px" }}
              onFocus={function (e) {
                var val = e.target.value;
                e.target.value = "";
                e.target.value = val;
              }}
            />
          </div>
          {getFormErrorMessage("password")}
        </div>
      </div>
      <div className="flex flex-row justify-content-between">
        <div className="flex flex-row align-items-center gap-1">
          <input
            id="remember-me"
            type="checkbox"
            value={rememberMe}
            onChange={(e) => onRememberMeChanged(e)}
          />
          <label htmlFor="remember-me" className="text-sm opacity-80">
            Remember me
          </label>
        </div>
        <Link
          className="no-underline text-sm"
          to="/auth/password-recovery/find-account"
        >
          <p className="font-bold"> Forgot Password?</p>
        </Link>
      </div>

      <div className="flex flex-column gap-2">
        <Button type="submit" label="LOG IN" loading={loading} />
        <Button
          type="button"
          label="Log in with Google"
          className="flex flex-row-reverse bg-white text-700 border-gray-500 border-1"
          onClick={signInWithGoogle}
        >
          <img alt="google" className="w-1rem" src={google} />
        </Button>

        <Button
          label="Log in with LinkedIn"
          className="flex flex-row-reverse bg-white text-700 border-gray-500 border-1"
          type="button"
          onClick={linkedInLogin}
        >
          <img alt="linked in" className="w-1rem" src={linkedin} />
        </Button>
        {socialLoginError && <div className="p-error">{socialLoginError}</div>}
      </div>
    </form>
  );
}

export default LoginForm;
