import React, { useEffect, useLayoutEffect, useState } from "react";
import arrow from "../../assets/performance-range-score_indicator-arrow-icon.png";
import { Accordion, AccordionTab } from "primereact/accordion";

function Strengths({ strengthsAndWeaknesses }) {
  const [saw, setSaw] = useState(null);
  
  useEffect(() => {
    setSaw(strengthsAndWeaknesses?.map(s => ({ p0: 0, p100: 1000, ...s })))
  }, [strengthsAndWeaknesses]);

  useLayoutEffect(() => {
    updateGradientForAll();
  }, [saw]);

  function updateGradientForAll() {
    const wrappers = document.querySelectorAll(".performance-range-score_component");
  
    if (!wrappers) {
      return;
    }

    wrappers.forEach((wrapper) => {
        const divs = wrapper.children;
        let accumulatedWidth = 0;

        for (let i = 0; i < divs.length; i++) {
            const div = divs[i];
            const divWidth = div.clientWidth;

            // Set the flex-basis to the clientWidth in pixels
            div.style.flexBasis = `${divWidth}px`;

            // Set the background size to the clientWidth of the wrapper
            div.style.backgroundSize = `${wrapper.clientWidth}px 100%`;

            // Calculate the background position
            div.style.backgroundPosition = `-${accumulatedWidth}px 0%`;

            accumulatedWidth += divWidth;
        }
    });
}

  function rotateAngle(s) {
    if (s.arrowDirection === "right") {
      return "-90deg";
    } else if (s.arrowDirection === "left") {
      return "90deg";
    } else {
      return "0";
    }
  }

  function calcWidth(s, leftPercentile, rightPercentile) {
    return ((s[rightPercentile] - s[leftPercentile]) / 1000) * 100 + "%";
  }



  return (
    <section
      id="strengthAndWeaknesses"
      className="flex flex-column gap-5 mt-5 max-w-2xl mx-auto"
    >
      <div className="text-center flex flex-column gap-2 max-w-xl mx-auto">
        <h2 className="md:text-2xl text-xl">Strengths and Weaknesses</h2>
        <p className="text-xs opacity-80">
          See how your team perceives you along the five dimensions of
          performance.
        </p>
      </div>

      <div className="flex flex-column gap-6">
        {saw &&
          saw.map((s, index) => (
            <div key={index} className="text-center flex flex-column gap-3">
              {index === 0 ?
                <div className="flex justify-content-between" >
                  <p className="text-gray-500">Not at all</p>
                  <h3 className="text-base">{s.title}</h3>
                  <p className="text-orange-600">Definitely</p>
                </div>
                : <h3 className="text-base">{s.title}</h3>}
              <div className="performance-range-score_component">
                <div
                  style={{
                    width: calcWidth(s, 'p0', "p05"),
                  }}
                  className="performance-range-score_bar  performance-range-score_q1-p1 performance-range-score_outer-bar"
                />
                <div
                  style={{
                    width: calcWidth(s, 'p05', "p25"),
                  }}
                  className="performance-range-score_bar performance-range-score-gradient performance-range-score_q1-p2"
                />
                <div
                  style={{
                    width: calcWidth(s, 'p25', "p40"),
                  }}
                  className="performance-range-score_bar performance-range-score-gradient performance-range-score_q1-p3"
                />
                <div
                  style={{
                    width: calcWidth(s, 'p40', "p60"),
                  }}
                  className="performance-range-score_bar performance-range-score-gradient performance-range-score_q1-p4"
                >
                  <img
                    style={{
                      transform: "rotate(" + rotateAngle(s) + ")",
                    }}
                    className="performance-range-score_indicator-arrow"
                    alt="arrow"
                    src={arrow}
                  ></img>
                </div>
                <div
                  style={{
                    width: calcWidth(s, 'p60', "p75"),
                  }}
                  className="performance-range-score_bar performance-range-score-gradient performance-range-score_q1-p5"
                />
                <div
                  style={{
                    width: calcWidth(s, 'p75', "p90"),
                  }}
                  className="performance-range-score_bar performance-range-score-gradient performance-range-score_q1-p6"
                />
                <div
                  style={{
                    width: calcWidth(s, 'p90', "p100"),
                  }}
                  className="performance-range-score_bar performance-range-score_q1-p7 performance-range-score_outer-bar"
                />
              </div>
            </div>
          ))}
      </div>
      <Accordion className="max-w-lg mx-auto">
        <AccordionTab header="Legend" headerClassName="font-semibold">
          <div className="flex flex-column gap-3 text-sm opacity-80 pb-3 m-0">
            <p>
              Above you see the spread of the-last-100-days responses from your
              reflectors, on the spectrum of Definitely to Not at all. The
              concentration of responses along this continuum is visualized by
              the vertical width of the colored sections.
            </p>
            <p>
              Arrows are located at the median values, with the direction of the
              arrow indicating a trend over last month:
            </p>
            <div className="flex gap-4 font-bold">
              <div className="flex align-items-center gap-3"><img src={arrow} className="h-2rem rotate-90" alt='arrow' /><p>Worsening</p></div>
              <div className="flex align-items-center gap-3"><img src={arrow} className="h-2rem" alt='arrow' />Stable</div>
              <div className="flex align-items-center gap-3"><img src={arrow} className="h-2rem -rotate-90" alt='arrow' />Improving</div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </section>
  );
}

export default Strengths;
