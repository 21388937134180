import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppHttp } from "../../service/app-http";
import { confirmOtherEmailEndpoint } from "../../service/api-temp";

function ConfirmOtherEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (!searchParams.has("token")) {
    navigate("/dashboard/reflectors-overview");
  }

  useEffect(() => {
    async function confirmOtherEmail() {
      AppHttp.get(confirmOtherEmailEndpoint, {
        token: searchParams.get("token"),
      }).then((result) => {
        if (result.success) {
          navigate("/");
        }
      });
    }

    confirmOtherEmail();
  });

  return <></>;
}

export default ConfirmOtherEmail;
