import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext } from "react";
import YouCircle from "./YouCircle";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { RelationshipProximity } from "../../service/enums";
import PersonIcon from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Tag } from "primereact/tag";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "primereact/button";
import MirrorImageProgress from "./MirrorImageProgress";
import { UserDataState } from "../store_providers/user-data-store";
import { getFirstNameFromName } from "../../service/functions/user-name-functions";

function MobileDashboard({
  count,
  circles,
  onCellClicked,
  filters,
  globalFilterValue,
  setEditRelationship,
  setVisible,
  types,
  onClearRelationshipTypeFlter,
  countByType,
  onReflectorAliasFilterChanged,
  onRelationshipTypeFilterChanged,
  lowestIdInType,
  position,
}) {
  const [userData] = useContext(UserDataState);

  function show() {
    position("bottom");
    setVisible(true);
  }
  function showEdit(param) {
    position("bottom");
    setEditRelationship(param);
  }

  const statusBodyTemplateMobile = (circle) => {
    return <MoreHorizIcon onClick={() => showEdit(circle)} />;
  };
  const statusBodyTemplate = (circle) => {
    return (
      <div onClick={() => showEdit(circle)}>
        {types.find((x) => x.id === circle.relationshipType).name}
      </div>
    );
  };

  const proximityBodyTemplate = (circle) => {
    if (circle.relationshipProximity === RelationshipProximity.Often) {
      return (
        <div className={`cursor-pointer flex flex-column text-sm `}>
          <div className="flex ">
            <PersonIcon fontSize="inherit" />
            <PersonIcon fontSize="inherit" />
          </div>
        </div>
      );
    } else if (
      circle.relationshipProximity === RelationshipProximity.Occasionally
    ) {
      return (
        <div className={`cursor-pointer flex flex-column text-sm`}>
          <div className="flex ">
            <PersonIcon fontSize="inherit" />
            <MoreHorizIcon fontSize="inherit" />
            <PersonIcon fontSize="inherit" />
          </div>
        </div>
      );
    } else {
      return (
        <div className={`cursor-pointer flex flex-column text-sm`}>
          <div className="flex ">
            <PersonIcon fontSize="inherit" />
            <span className="flex p-0">
              <MoreHorizIcon fontSize="inherit" className="rightDots" />
              <MoreHorizIcon fontSize="inherit" />
            </span>
            <PersonIcon fontSize="inherit" />
          </div>
        </div>
      );
    }
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div className="text-xs text-left">
          {filters.relationshipType.value ? (
            <Tag className="text-sm surface-700">
              <div className="flex align-items-center gap-1 font-normal ">
                <CloseIcon
                  fontSize="inherit"
                  onClick={onClearRelationshipTypeFlter}
                />
                <span className="text-sm">
                  {types.find((x) => x.id === filters.relationshipType.value)
                    .name + ":"}{" "}
                  {countByType[filters.relationshipType.value]}
                </span>
              </div>
            </Tag>
          ) : (
            <span className="text-sm">
              All Reflectors: {circles?.length ?? 0}
            </span>
          )}
        </div>
        <div
          onClick={show}
          className="flex align-items-center gap-2 text-xs text-bold text-left text-primary"
        >
          <AddIcon font="inherit" />
          <span className="font-bold">Add more</span>
        </div>
      </div>
    );
  };

  return (
    // Render mobile view content here
    <div className="reflectors_wrapper flex flex-column gap-5 align-items-center justify-content-between px-3 md:px-5 pt-8 pb-5">
      <div className="text-center flex flex-column gap-1">
        <p className="text-xs opacity-60">
          Hi, {getFirstNameFromName(userData.name)}
        </p>
        <h1 className="text-xl sm:text-2xl">YOUR REFLECTORS</h1>
        <p className="text-xs opacity-60">See and modify your reflectors.</p>
      </div>
      <div className="reflectors_ellipse-wrapper flex align-items-center justify-content-center my-0 sm:my-5">
        <div className="reflectors_ellipse-background reflectors_square-background" />
        <div className="reflectors_cross-wrapper">
          <div className="reflectors_cross-bar reflectors_cross-bar-left" />
          <div className="reflectors_cross-bar reflectors_cross-bar-right" />
          <YouCircle count={count} maxValue={userData.reflectorsCap} />
        </div>
        <div className=" n-circle flex align-items-center justify-content-center">
          {circles?.map((reflector, index) => (
            <div
              key={index}
              className={`db-circle circle-${reflector.id} ${lowestIdInType[reflector.relationshipType] === reflector.id
                  ? ""
                  : "surface-0 border-1 border-gray-200"
                } text-900 font-semibold circle `}
              onClick={() => {
                //filter the datatable
                onRelationshipTypeFilterChanged(reflector.relationshipType);
              }}
            >
              <div className="text-sm">{countByType[reflector.relationshipType]}</div>
            </div>
          ))}
        </div>
        <div className="big-rectangle big-rectangle-mobile ">
          <div className="rectangle-words word-peers text-sm opacity-60">
            PEERS
          </div>
          <div className="rectangle-words word-clients text-sm opacity-60">
            CLIENTS
          </div>
          <div className="rectangle-words word-senior text-sm opacity-60">
            SENIOR
          </div>
          <div className="rectangle-words word-junior text-sm opacity-60">
            JUNIOR
          </div>
        </div>
      </div>

      <div className="border-blue-700 max-w-md">
        <DataTable
          onSelectionChange={onCellClicked}
          value={circles}
          header={renderHeader}
          tableStyle={{
            minWidth: "100%",
            width: "100%",
            fontSize: "0.78rem",
          }}
          scrollable
          scrollHeight="20rem"
          style={{ fontFamily: "Montserrat" }}
          cellSelection
          selectionMode="single"
          filters={filters}
          filterDisplay="none"
          removableSort
          emptyMessage="No reflectors found."
        >
          <Column field="reflectorAlias" sortable header="Name"></Column>
          <Column
            field="relationshipProximity"
            body={proximityBodyTemplate}
            header="Proximity"
            sortable
          ></Column>
          <Column
            field="relationshipType"
            body={statusBodyTemplate}
            sortable
            header="Relation to you"
          />
          <Column body={statusBodyTemplateMobile} />
        </DataTable>
      </div>

      <MirrorImageProgress />
      <div className="fixed bottom-0 w-screen flex flex-column border-top-1 bottom-0 surface-border z-4 bg-white ">
        <div className="flex w-screen justify-content-between px-2 py-3 align-items-end z-2" >
          <div className="p-input-icon-left align-items-center flex w-4">
            <SearchIcon fontSize="small" />
            <InputText
              className="bg-white border-none"
              value={globalFilterValue}
              onChange={onReflectorAliasFilterChanged}
              placeholder="Search"
            />
          </div>
          <div className="font-montserrat w-4 text-center flex flex-row gap-2 align-items-center justify-content-center my-auto pt-1">
            <span className="text-2xl text-primary font-bold">
              {circles.length}
            </span>{" "}
            /<span className="text-base"> {userData.reflectorsCap}</span>
          </div>
          <div className="w-4 min-h-full flex flex-row relative">
            <div
              onClick={show}
              className="absolute bottom-0 right-0 cursor-pointer text-sm flex flex-column align-items-center gap-1 ml-auto"
            >
              <Button
                className="p-button-rounded p-button-primary flex align-items-center justify-content-center m-circle-button"
                disabled={count >= userData.reflectorsCap}
              >
                <AddIcon />
              </Button>
              <p className="font-bold">Add</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileDashboard;
