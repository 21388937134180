import React from 'react';

const SettingsTabWrapper = ({ children }) => {
    return (
        <div className="settings_tab-wrapper flex flex-column gap-3 lg:gap-5 mt-2">
            {children}
        </div>
    );
};

export default SettingsTabWrapper;
