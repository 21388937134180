import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";

import { InputSwitch } from "primereact/inputswitch";

import { TabMenu } from "primereact/tabmenu";
import { Outlet, useNavigate } from "react-router-dom";
import { AppHttp } from "../../service/app-http";
import {
  getMyMirrorSurveyStatusEndpoint,
  toggleMyMirrorSurveyStatusEndpoint,
} from "../../service/api-temp";
import { UserDataState } from "../store_providers/user-data-store";

function SettingsOverlay() {
  const itemsInitialState = [
    {
      label: "Profile",
      command: () => {
        navigate("/dashboard/settings/profile-info");
      },
    },
    {
      label: "Personal Info",
      command: () => {
        navigate("/dashboard/settings/personal-info");
      },
    },
  ];

  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [userData, dispatch] = useContext(UserDataState);
  const [items, setItems] = useState(itemsInitialState);

  useEffect(() => {
    if (userData.hasPassword) {
      setItems((current) => [
        ...current,
        {
          label: "Password",
          command: () => {
            navigate("/dashboard/settings/password-change");
          },
        },
      ]);
    }

    return () => {
      setItems(itemsInitialState);
    }
  }, [userData]);

  function onStatusChanged(e) {
    AppHttp.post(toggleMyMirrorSurveyStatusEndpoint).then((response) => {
      if (response.success) {
        setChecked(response.data === "active");
      }
    });
  }

  useEffect(() => {
    async function getUserSurveyStatus() {
      AppHttp.get(getMyMirrorSurveyStatusEndpoint).then((response) => {
        if (response.success) {
          setChecked(response.data === "active");
        }
      });
    }

    getUserSurveyStatus();
  }, []);

  return (
    <div className="settings_component min-h-screen justify-content-between p-input-filled flex flex-column text-sm">
      <Header />
      <div className="flex flex-column gap-5 border-round-lg border-solid border-white bg-white px-3 md:px-5 py-5 mt-7 mx-3 md:mx-5">
        <div className="flex flex-column lg:flex-row gap-3 lg:align-items-center justify-content-between">
          <h1 className="text-xl">Settings</h1>
          <div className="flex align-items-center">
            <InputSwitch checked={checked} onChange={onStatusChanged} />
            <div
              className={
                "ml-2 w-9rem font-semibold text-right " +
                (checked ? "" : "text-red-500")
              }
            >
              {checked ? "Reflections Active" : "! Reflections Inactive"}
            </div>
          </div>
        </div>
        <TabMenu className="text-sm" model={items} />
        <Outlet />
      </div>
      <Footer isFullWidth={true} />
    </div>
  );
}

export default SettingsOverlay;
