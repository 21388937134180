import React from 'react';

const ReflectionLayout = ({ children }) => {
    return (
        <div className="reflection_layout relative h-full flex flex-column gap-5 justify-content-between">
            {children}
        </div>
    );
};

export default ReflectionLayout;
