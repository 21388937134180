import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SettingsTabWrapper from "./SettingsTabWrapper";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { AppHttp } from "../../service/app-http";
import {
  getPersonalInfoEndpoint,
  updatePersonalInfoEndpoint,
} from "../../service/api-temp";
import { UserDataState } from "../store_providers/user-data-store";
import Loading from "../Loading";

function PersonalInfo() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1196);
  const [fetchedFromApi, setFetchedFromApi] = useState(false);
  const [today] = useState(new Date());
  const [userData, dispatch] = useContext(UserDataState);
  const [yearsOfBirth, setYearsOfBirth] = useState([]);
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const types = ["Male", "Female", "Other"];

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1196);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      birthDate: "",
      primaryEmployer: "",
      title: "",
    },
    onSubmit: (values) => {
      setLoading(true)
      
      if (!formik.dirty) {
        return;
      }
      
      AppHttp.post(updatePersonalInfoEndpoint, values).then((result) => {
        if (result.success) {
          setLoading(false)
          formik.resetForm({
            values: {
              ...result.data,
            },
          });
          dispatch({
            type: "update",
            payload: {
              nameInitials: result.data.nameInitials,
            },
          });
        }
      });
    },
  });

  useEffect(() => {
     function getPersonalInfo() {
      AppHttp.get(getPersonalInfoEndpoint).then( (result) => {
        setIsLoading(false)
        
        if (result.success) {
          formik.resetForm({
            values: {
              ...result.data,
            },
          });
        }

        setFetchedFromApi(true);
      });
    }

    if (!fetchedFromApi) {
      getPersonalInfo();
    }
  }, [fetchedFromApi, formik]);

  useEffect(() => {
    const yearsOfBirth = [];
    const startDate = new Date().getFullYear() - 18;
    const endDate = startDate - 105;

    for (let i = startDate; i >= endDate; i--) {
      yearsOfBirth.push(i);
    }

    setYearsOfBirth(yearsOfBirth);
  }, []);



  function onNameChanged(e) {
    formik.setFieldValue("name", e.target.value ?? "");
  }

  function onGenderChanged(e) {
    if (e.value) {
      formik.setFieldValue("gender", e.value);
    } else {
      formik.setFieldValue("gender", null);
    }
  }

  function onBirthDateChanged(e) {
    formik.setFieldValue("birthDate", e.value);
  }

  function onEmployerChanged(e) {
    formik.setFieldValue("primaryEmployer", e.target.value ?? "");
  }

  function onTitleChanged(e) {
    formik.setFieldValue("title", e.target.value ?? "");
  }

  function onCancelClick() {
    formik.resetForm();
  }

  const isFormFieldInvalid = (msg) =>
    !!(formik.touched[msg] && formik.errors[msg]);

  const getFormErrorMessage = (msg) => {
    return isFormFieldInvalid(msg) ? (
      <small className={`${formik.errors[msg] ? "block" : "hidden"} p-error`}>
        {formik.errors[msg]}
      </small>
    ) : (
      ""
    );
  };

  return (
    <Loading visible={isLoading}>
      <form onSubmit={formik.handleSubmit}>
        <SettingsTabWrapper>
          <h2 className="py-0 lg:py-2 text-sm font-normal">
            Update your personal details here.
          </h2>

          <hr className="w-full px-5 opacity-20" />

          <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
            <label className="w-full lg:w-16rem font-semibold">Full Name*</label>
            <div className="w-full max-w-md flex gap-3 align-items-center">
              <InputText
                name="name"
                aria-label="name"
                placeholder="Jhon Doe"
                value={formik.values.name}
                onChange={onNameChanged}
              />
            </div>
          </div>

          <hr className="w-full px-5 opacity-20" />

          <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
            <label className="w-full lg:w-16rem font-semibold">Gender</label>
            <div className="w-full max-w-md flex gap-3 align-items-center">
              <Dropdown
                showClear={true}
                value={formik.values.gender}
                onChange={onGenderChanged}
                options={types}
                placeholder="Select gender"
              />
              {getFormErrorMessage("gender")}
            </div>
          </div>

          <hr className="w-full px-5 opacity-20" />

          <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
            <label className="w-full lg:w-16rem font-semibold">
              Year of Birth
            </label>
            <div className="w-full max-w-md flex gap-3 align-items-center p-input p-input-icon-right">
              <Dropdown
                showClear={true}
                value={formik.values.birthDate}
                onChange={onBirthDateChanged}
                options={yearsOfBirth}
                placeholder="YYYY"
              />
            </div>
          </div>

          <hr className="w-full px-5 opacity-20" />

          <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
            <label className="w-full lg:w-16rem font-semibold">
              Primary Employer
            </label>
            <div className="w-full max-w-md flex gap-3 align-items-center p-input p-input-icon-right">
              <InputText
                name="primaryEmployer"
                aria-label="employer"
                placeholder="Company Name"
                value={formik.values.primaryEmployer}
                onChange={onEmployerChanged}
              />
            </div>
          </div>

          <hr className="w-full px-5 opacity-20" />

          <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
            <label className="w-full lg:w-16rem font-semibold">Job Title</label>
            <div className="w-full max-w-md flex gap-3 align-items-center p-input p-input-icon-right">
              <InputText
                name="title"
                aria-label="Job Title"
                placeholder="Your Role"
                value={formik.values.title}
                onChange={onTitleChanged}
              />
            </div>
          </div>

          <hr className="w-full px-5 opacity-20 mt-4 lg:mt-0" />

          <div className="button-group max-w-xs gap-2 lg:ml-auto">
            <Button
              type="button"
              label="Cancel"
              outlined
              onClick={onCancelClick}
            ></Button>
            <Button type="submit" loading={loading} label="Save"></Button>
          </div>
        </SettingsTabWrapper>
      </form>
    </Loading>
  );
}

export default PersonalInfo;
