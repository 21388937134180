import * as d3 from "d3";

class ScoreColorMap {
    constructor() {
        this.scoreColorsArray = [
            {score: -50, color: "#868478", label: "Terribly"},
            {score: 0, color: "#C8C4B7", label: "Not Well"},
            {score: 100, color: "#F1E8CA", label: "So So"},
            {score: 180, color: "#FFE493", label: "Well"},
            {score: 300, color: "#FFC539", label: "Great"},
            {score: 400, color: "#FFAE00", label: "Outstanding"},
        ];
    }

    getRange(score) {
        for (let i = 0; i < this.scoreColorsArray.length - 1; i++) {
            if (score >= this.scoreColorsArray[i].score && score <= this.scoreColorsArray[i+1].score) {
                return {
                    color1: this.scoreColorsArray[i].color,
                    color2: this.scoreColorsArray[i+1].color,
                    score1: this.scoreColorsArray[i].score,
                    score2: this.scoreColorsArray[i+1].score
                };
            }
        }
    }

    getLabel(score) {
        const scoreColorMap = this.scoreColorsArray.reduce((acc, cur) => {
            acc[cur.score] = cur.label;
            return acc;
        }, {});
        return scoreColorMap[score];
    }
}

export const scoreToColor = (score) => {
    if(score === undefined) return;

    const scoreColorMap = new ScoreColorMap();
    const { color1, color2, score1, score2 } = scoreColorMap.getRange(score);

    const t = (score - score1) / (score2 - score1);
    return d3.interpolate(color1, color2)(t);
}

export const scoreToLabel = (score) => {
    if(score === undefined) return;

    const scoreColorMap = new ScoreColorMap();
    return scoreColorMap.getLabel(score);
}
