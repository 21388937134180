import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { AppHttp } from "../../service/app-http";
import { getQualitiveFeedbackEndpoint } from "../../service/api-temp";
import moment from "moment/moment";
import { Button } from "primereact/button";

function Feedback({ dateTo }) {
  const [filter] = useState([
    { ascending: false, name: "Latest" },
    { ascending: true, name: "Oldest" },
  ]);

  const [qualitiveFeedback, setQualitiveFeedback] = useState({
    canLoadMore: false,
    feedbacks: [],
  });
  
  const [qualitiveFeedbackFilter, setQualitiveFeedbackFilter] = useState({
    pageSize: 6,
    ascending: false,
    dateTo: null,
  });

  async function fetchFromApi() {
    // setQualitiveFeedback([]);
    if (!qualitiveFeedbackFilter.dateTo) {
      setQualitiveFeedback([]);
      return;
    }
    
    await AppHttp.post(
      getQualitiveFeedbackEndpoint,
      qualitiveFeedbackFilter
    ).then((result) => {
      if (result.success) {
        setQualitiveFeedback(result.data);
      }
    });
  }

  useEffect(() => {
    if (!dateTo) {
      return;
    }

    setQualitiveFeedbackFilter((current) => ({
      ...current,
      dateTo: new Date(dateTo),
    }));
  }, [dateTo]);

  useEffect(() => {
    fetchFromApi();
  }, [qualitiveFeedbackFilter]);

  async function onSelectedFilterChanged(e) {
    setQualitiveFeedbackFilter((current) => ({
      ...current,
      ascending: e.value,
    }));
  }

  function onLoadMoreFeedback() {
    setQualitiveFeedbackFilter((current) => ({
      ...current,
      pageSize: current.pageSize + 6,
    }));
  }

  return (
    <section
      id="Feedback"
      className="flex flex-column gap-5 mt-5 mx-auto self-awareness_scroll-section"
    >
      <div className="flex flex-row flex-wrap gap-3 justify-content-between">
        <div className="flex flex-column gap-2 max-w-md">
          <h2 className="md:text-2xl text-xl">Qualitative Feedback</h2>
          <p className="text-xs opacity-80">
            Read comments and feedback from your coworkers, meant for your eyes
            only.
          </p>
        </div>
        <div className="flex align-items-center gap-2">
          <p className="font-bold text-sm white-space-nowrap">Sort by:</p>
          <Dropdown
            value={qualitiveFeedbackFilter.ascending}
            onChange={onSelectedFilterChanged}
            options={filter}
            optionValue="ascending"
            optionLabel="name"
            placeholder="Latest"
            font="inherit"
            className="w-full md:w-8rem"
          />
        </div>
      </div>

      <div className="flex gap-2 pb-3 md:block overflow-auto flex-nowrap feedback-grid_wrapper text-sm">
        <ul className="p-0 feedback-grid_list ">
          {qualitiveFeedback &&
            qualitiveFeedback.feedbacks &&
            qualitiveFeedback.feedbacks.map((f, index) => (
              <li
                key={index}
                className="p-3 flex flex-column gap-3 bg-white relative"
              >
                <p>{f.content}</p>
                <span className="opacity-80">
                  {moment(f.date).format("MMM D, yyyy")}
                </span>
                <div
                  className={
                    "feedback-grid_score feedback-grid_score-" + f.overallScore
                  }
                />
              </li>
            ))}
        </ul>
      </div>
        {qualitiveFeedback && qualitiveFeedback.canLoadMore && (
          <Button
            className="w-7rem"
            severity="secondary"
            label="Load More"
            onClick={onLoadMoreFeedback}
          />
        )}
    </section>
  );
}

export default Feedback;
