import React, { useCallback, useEffect, useState } from "react";
import { Tooltip } from "primereact/tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function RisingSun({ performanceScore, fulfillmentScore }) {
  const [fulfilmentPercent, setFulfillmentPercent] = useState(0);
  const [performancePercent, setPerformancePercent] = useState(0);

  const calculatePercentFromLeftFulfillment = useCallback(() => {
    setFulfillmentPercent((fulfillmentScore / 1000) * 100);
  }, [fulfillmentScore]);

  const calculatePercentFromLeftPerformance = useCallback(() => {
    setPerformancePercent((performanceScore / 360) * 100);
  }, [performanceScore]);

  useEffect(() => {
    calculatePercentFromLeftFulfillment();
    calculatePercentFromLeftPerformance();
  }, [calculatePercentFromLeftFulfillment, calculatePercentFromLeftPerformance]);


  function getFulfillmentColor() {
    if (fulfilmentPercent <= 16) {
      return '#2594CC';
    } else if (fulfilmentPercent <= 32) {
      return '#83C0E5';
    } else if (fulfilmentPercent <= 48) {
      return '#D3BAB5';
    } else if (fulfilmentPercent <= 64) {
      return '#E6B07E';
    } else if (fulfilmentPercent <= 80) {
      return '#E1935B';
    } else {
      return '#D87025';
    }
  }

  //#868478, #C8C4B7, #F1E8CA, #FFE493, #FFC539, #FFAE00)

  function getPerformanceColor() {
    if (performancePercent <= 16) {
      return '#868478';
    } else if (performancePercent <= 32) {
      return '#C8C4B7';
    } else if (performancePercent <= 48) {
      return '#F1E8CA';
    } else if (performancePercent <= 64) {
      return '#FFE493';
    } else if (performancePercent <= 80) {
      return '#FFC539';
    } else {
      return '#FFAE00';
    }
  }

  return (
    <div className="rising-sun_component mt-auto">
      <div className="rising-sun_side rising-sun_fulfillment-side">
        <div className="rising-sun_score-line rising-sun_score-line-fulfillment">
          <div
            style={{
              left: fulfilmentPercent + '%',
              backgroundColor: getFulfillmentColor(),
              transform: 'translateX(-50%)'
            }}
            className="rising-sun_score-box rising-sun_score-box-fulfillment"
          />
        </div>
        <div className="flex mb-7 opacity-40 gap-2 z-1">
          <span className="text-sm ">
            Fulfillment
          </span>
          <InfoOutlinedIcon className="tooltipFulfillment text-xl " />
        </div>
        <Tooltip
          target=".tooltipFulfillment"
          autoHide={true}
          my="left top"
          className="text-left"
          style={{ marginTop: '-48px', marginLeft: '7px', width: "100%", maxWidth: "20rem" }}
        >
          <p className="p-2 text-xs ">
            Fulfillment at work is a deep sense of satisfaction and meaning derived from one's job or professional endeavors. It encompasses a sense of purpose, personal growth, and alignment of values with our teammates and the work being performed. It involves feeling a sense of accomplishment, utilizing one's skills and strengths, and making a meaningful contribution to the organization or the larger society.</p>
          <p className="p-2 text-xs ">
            Self-reflecting (on how you feel at work each week) paints a true picture of your overall satisfaction at work: your very fulfillment at work.</p>
        </Tooltip>
        <Tooltip
          target=".rising-sun_fulfillment-circle"
          autoHide={true}
        >
          <p className="p-2 text-xs ">{Math.floor((fulfillmentScore / 1000) * 360)}</p>
        </Tooltip>
        <div className="cursor-pointer rising-sun_fulfillment-circle-wrapper z-0">
          <div data-pr-position='mouse' className="rising-sun_fulfillment-circle rising-sun_fulfillment-circle-5">
            <div className="rising-sun_fulfillment-circle rising-sun_fulfillment-circle-4" />
            <div className="rising-sun_fulfillment-circle rising-sun_fulfillment-circle-3" />
            <div className="rising-sun_fulfillment-circle rising-sun_fulfillment-circle-2" />
            <div className="rising-sun_fulfillment-circle rising-sun_fulfillment-circle-1" />
            <div
              style={{
                width: fulfilmentPercent + '%',
                height: fulfilmentPercent + '%',
                backgroundColor: getFulfillmentColor(),
                
              }}
              className="rising-sun_fulfillment-circle rising-sun_fulfillment-circle-score"
            />
          </div>
        </div>
      </div>
      <div className="rising-sun_side rising-sun_performance-side">
        <div className="rising-sun_performance-circle-wrapper">
          <div className="rising-sun_performance-circle">
            <div
              style={{
                width: fulfilmentPercent + '%',
                height: (fulfilmentPercent / 2) + '%',
                backgroundColor: getPerformanceColor(),
                
              }}
              className="rising-sun_performance-circle-score"
            />
          </div>
        </div>
        <span className="rising-sun_performance-score">{performanceScore}</span>
        <div className="flex mt-7 opacity-40 gap-2">
          <span className="text-sm ">
            Performance
          </span>
          <InfoOutlinedIcon className="tooltipPerformance text-xl " />
        </div>
        <Tooltip
          target=".tooltipPerformance"
          autoHide={true}
          className="text-left "
          my="left bottom"
          style={{ width: "100%", maxWidth: "20rem" }}
        >
          <p className="p-2 text-xs">
            Performance is the extent to which you effectively and efficiently perform your job responsibilities, meet or exceed expectations, and contribute to the overall success of the organization.</p>
          <p className="p-2 text-xs ">
            When we ask your teammates “How is [YOU] working lately” they are effectively asked to consider the above and any other considerations they consider important for you work.</p>
          <p className="p-2 text-xs ">
            The full spectrum of responses (over the past 200 days) is considered for the proprietary M360 performance score, which is on a 0-360 scale.</p>
        </Tooltip>
        <div className="rising-sun_score-line rising-sun_score-line-performance">
          <div
            style={{
              left: performancePercent + '%',
              backgroundColor: getPerformanceColor(),
              transform: 'translateX(-50%)'
            }}
            className="rising-sun_score-box rising-sun_score-box-performance"
          />
        </div>
      </div>

    </div>
  );
}

export default RisingSun;