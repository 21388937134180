import React from "react";
import avatarIcons from "../../assets/avatar-group.webp";
import headline from "../../assets/headline.svg";
import arrow from "../../assets/arrow.png";

function SignupRigtSideContent() {

  return (
    <div className="max-w-md flex flex-column gap-5">
      <img
        className="w-15rem flex align-items-center"
        alt="For a Better You at Work"
        src={headline}
      />
      <p className="text-base opacity-80">
        Claim your account to gain a 360 Mirror Image at work, featuring honest
        reflections from the teammates whose opinions matter.
      </p>
      <div className="flex flex-row flex-wrap align-items-center">
        <img className="h-6 w-8 mr-2" alt="Avatars" src={avatarIcons}></img>
        <p className="text-sm opacity-80">Join the early VIPs</p>
      </div>
      <img
        className="auth_arrow relative hidden xl:block"
        alt="Arrow"
        src={arrow}
      ></img>
    </div>
  );
}

export default SignupRigtSideContent;
