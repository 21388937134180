import React, { useState } from "react";
import { Button } from "primereact/button";
import EmailIcon from "@mui/icons-material/Email";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import classNames from "classnames";
import { forgotPasswordUrl } from "../../service/api-temp";
import { useNavigate } from "react-router-dom";
import { forgotPasswordSchema } from "../../service/validationschemas/validation-schemas";
import { AppHttp } from "../../service/app-http";

function FindAcc() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      setLoading(true);
      formik.setFieldError("email", null);
      AppHttp.post(forgotPasswordUrl, {
        email: values.email,
      }).then((response) => {
        if (response.success) {
          navigate("/auth/password-recovery/recovery-email-success", {
            state: {
              email: values.email,
            },
          });
        } else {
          setLoading(false);
          formik.setFieldError("email", "Email not valid");
        }
      });
    },
  });

  const getFormErrorMessage = (msg) => {
    return <small className="p-error text-left">{formik.errors[msg]}</small>;
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="p-input-filled max-w-sm flex flex-column flex-start gap-5"
    >
      <div className="flex flex-column gap-2">
        <h1 className="text-2xl">Let's Find Your Account</h1>
        <p className="text-sm opacity-80">
          Enter your email and we'll send you a link to reset
          <br className="hidden sm:block" />
          your password.
        </p>
      </div>

      <div className="flex flex-column gap-2">
        <label className="text-left text-sm opacity-80">Your Email</label>
        <div className="p-input-icon-left">
          <EmailIcon fontSize="inherit" />
          <InputText
            className={classNames({ "p-invalid": !!formik.errors.email })}
            placeholder="name@company.com"
            type="email"
            aria-label="email"
            name="email"
            value={formik.values.email}
            onChange={(e) => {
              formik.setFieldValue("email", e.target.value);
            }}
          />
        </div>
        {getFormErrorMessage("email")}
      </div>
      <Button loading={loading} type="submit" label="Send Password Reset Link"></Button>
    </form>
  );
}

export default FindAcc;
