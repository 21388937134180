import React, { useContext } from "react";
import Header from "../header/Header";
import { Button } from "primereact/button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import YouCircle from "../addReflectors/YouCircle";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import oftenIcon from "../../assets/often.svg";
import occIcon from "../../assets/occasionally.svg";
import sporIcon from "../../assets/sporadically.svg";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  getToken,
  setAuthDataObj,
} from "../../service/UserAuthData";
import { setUserPassedToturialUrl } from "../../service/api-temp";
import ReflectorsContainer from "./ReflectorsContainer";
import ReflectorsWrapper from "./ReflectorsWrapper";
import ReflectorsSidebar from "./ReflectorsSidebar";
import { UserDataState } from "../store_providers/user-data-store";
import { AppHttp } from "../../service/app-http";

function Overlay() {
  const navigate = useNavigate();
  const [userData, dispatch] = useContext(UserDataState);

  const [visible, setVisible] = useState(true);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  function show() {
    setVisible1(true);
    setVisible(false);
  }

  function show1() {
    setVisible1(false);
    setVisible2(true);
  }

  function show2() {
    setVisible2(false);
    setVisible3(true);
  }

  function hide() {
    setVisible1(false);
    setVisible(true);
  }

  function hide1() {
    setVisible1(true);
    setVisible2(false);
  }

  function hide2() {
    setVisible2(true);
    setVisible3(false);
  }

  function setTutorialPassed() {
    AppHttp.get(setUserPassedToturialUrl).then((response) => {
      if (response.success) {
        dispatch({
          type: "update",
          payload: {
            hasPassedAddReflectorsTutorial: true,
          },
        });

        navigate("/home");
      }
    });
  }

  return (
    <>
      <div className="surface-200 h-full flex flex-column w-full">
        <Header className="blur" />
        <ReflectorsContainer className="flex flex-row h-full">
          <ReflectorsWrapper>
            <div
              className={`${visible2 ? "border-white border-solid bg-white py-2rem" : "blur"
                }  circle_wrapper flex flex-column align-items-center justify-content-center`}
            >
              <div className="often text-xs">Often</div>
              <div className="big-circle big-circle-1"></div>
              <div className="occ text-xs">Occasionally</div>
              <div className="big-circle big-circle-2"></div>
              <div className="spor text-xs pb-2">Sporadically</div>
              <div className="big-circle big-circle-3 text-xs"></div>
              <YouCircle />
            </div>
          </ReflectorsWrapper>
          <ReflectorsSidebar>
            <p className="blur text-sm opacity-80">
              Welcome to your
              <br />
              very own 360° Mirror.
            </p>
            <div
              className={` ${visible ? "border-white border-solid bg-white" : "blur"
                } flex flex-column gap-5`}
            >
              <h1 className="text-2xl">ADD YOUR REFLECTORS</h1>
              <p className="text-sm opacity-80">
                You will need seven to activate your 360 Mirror. Whose opinion
                matters to you the most?
              </p>
              <div className="flex align-items-center text-xl">
                <Button className="p-button-rounded p-button-primary flex align-items-center justify-content-center m-circle-button">
                  <AddIcon />
                </Button>
                <span className="ml-3 mr-1 text-primary text-2xl font-bold">
                  {0}
                </span>
                <span>/7</span>
              </div>
            </div>
            <div
              className={` ${visible3
                  ? "border-white border-solid bg-white align-items-start"
                  : "blur"
                } flex-column flex gap-3`}
            >
              <div className="flex align-items-center">
                <HelpOutlineIcon />
                <small>What happens next?</small>
              </div>
              <Button disabled label="Activate Reflections" />
            </div>
          </ReflectorsSidebar>
        </ReflectorsContainer>
        <Dialog
         draggable={false}
          className="block text-sm surface-500 reflectors_setup-dialog reflectors_setup-dialog1"
          header='You can add perspectives by clicking on the "plus" icon here.'
          visible={visible}
          modal={false}
          style={{ width: "24rem" }}
          onHide={() => setTutorialPassed()}
        >
          <div className="reflectors_dialog-arrow-right-wrapper">
            <div className="reflectors_dialog-arrow reflectors_dialog-arrow-right my-auto">
              &#9660;
            </div>
          </div>
          <div className=" flex flex-column">
            <hr className="w-full mb-3 opacity-50" />
            <div className="flex align-items-center justify-content-between text-sm">
              <small className="opacity-80">1 of 4 </small>
              <Button label="Next" onClick={show} />
            </div>
          </div>
        </Dialog>
        <Dialog
          className="p-input-filled text-center"
          header="Whose perspective matters to you?"
          visible={visible1}
          modal={false}
          style={{ width: "32rem", position: "absolute" }}
          breakpoints={{ "960px": "24rem", "641px": "100vw" }}
          onHide={() => setTutorialPassed()}
          draggable={false}
        >
          <div className="text-left flex flex-column gap-5">
            <div className="text-left flex flex-column gap-2">
              <label htmlFor="username" className="text-xs opacity-80">
                Full Name*
              </label>
              <InputText disabled id="username" aria-describedby="username" />
            </div>
            <div className="text-left flex flex-column gap-2">
              <label htmlFor="email" className="text-xs opacity-80">
                Email*
              </label>
              <InputText disabled id="email" aria-describedby="email" />
            </div>
            <div className="text-left flex flex-column gap-2">
              <div className="text-xs opacity-80">
                How often do you work with this person? SELECT ONE*
              </div>
              <div className="flex flex-row gap-5 text-xs">
                <img alt="often" src={oftenIcon} />
                <img alt="occasioanlly" src={occIcon} />
                <img alt="sporadically" src={sporIcon} />
              </div>
            </div>
            <Button disabled label="Add"></Button>
          </div>
        </Dialog>
        <Dialog
          className="text-left text-sm reflectors_setup-dialog reflectors_setup-dialog2"
          position="bottom"
          visible={visible1}
          header="All you need is their name and email."
          modal={false}
          style={{ width: "24rem" }}
          onHide={() => setTutorialPassed()}
          draggable={false}
        >
          <div className="reflectors_dialog-arrow-top-wrapper">
            <div className="reflectors_dialog-arrow reflectors_dialog-arrow-top">
              &#9660;
            </div>
          </div>
          <div className="flex flex-column">
            <hr className="w-full mb-3 opacity-50" />
            <div className="flex align-items-center justify-content-between text-sm">
              <span className="cursor-pointer" onClick={hide}>
                <ArrowBackIosIcon color="white" className="w-1rem h-1rem" />
              </span>
              <small className="opacity-80">2 of 4 </small>
              <Button label="Next" onClick={show1} />
            </div>
          </div>
        </Dialog>
        <Dialog
          className="text-left text-sm reflectors_setup-dialog reflectors_setup-dialog3"
          position="center"
          visible={visible2}
          header="They will start showing around you, depending on how closely they work with you."
          modal={false}
          style={{ width: "24rem" }}
          onHide={() => setTutorialPassed()}
          draggable={false}
        >
          <div className="reflectors_dialog-arrow-left-wrapper">
            <div className="reflectors_dialog-arrow reflectors_dialog-arrow-left my-auto">
              &#9660;
            </div>
          </div>
          <div className="flex flex-column">
            <hr className="w-full mb-3 opacity-50" />
            <div className="flex align-items-center justify-content-between text-sm">
              <span className="cursor-pointer" onClick={hide1}>
                {" "}
                <ArrowBackIosIcon
                  color="white"
                  className="w-1rem h-1rem"
                />{" "}
              </span>
              <small className="opacity-80">3 of 4 </small>
              <Button label="Next" onClick={show2} />
            </div>
          </div>
        </Dialog>
        <Dialog
          className="text-left text-sm reflectors_setup-dialog reflectors_setup-dialog4"
          position="bottom-right"
          visible={visible3}
          header="Once you have added at least seven, you will be able to set your mirror in motion."
          modal={false}
          style={{ width: "24rem" }}
          onHide={() => setTutorialPassed()}
          draggable={false}
        >
          <div className="reflectors_dialog-arrow-right-wrapper">
            <div className="reflectors_dialog-arrow reflectors_dialog-arrow-right my-auto">
              &#9660;
            </div>
          </div>
          <div className="flex flex-column">
            <hr className="w-full mb-3 opacity-50" />
            <div className="flex align-items-center justify-content-between text-sm">
              <span className="cursor-pointer" onClick={hide2}>
                <ArrowBackIosIcon color="white" className="w-1rem h-1rem" />
              </span>
              <small className="opacity-80">4 of 4 </small>
              <Button label="Finish" onClick={setTutorialPassed} />
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default Overlay;
