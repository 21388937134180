import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useFormik } from "formik";
import { addOtherEmailSchema } from "../../../service/validationschemas/validation-schemas";
import { AppHttp } from "../../../service/app-http";
import {
  addOtherEmailEndpoint,
  deleteOtherEmailEndpoint,
  setOtherEmailAsPrimaryEndpoint,
} from "../../../service/api-temp";
import { useScreenDetector } from "../../../hooks/useScreenDetector";

function ProfileOtherEmails({
  otherEmails,
  onNewAdded,
  onPrimaryChanged,
  onDeleted,
}) {
  const [addNewDialogVisible, setAddNewDialogVisible] = useState(false);
  const [confirmChangePrimaryVisible, setConfirmChangePrimaryVisible] =
    useState(false);
  const [confirmDeleteDialogVisible, setConfirmDeleteialogVisible] =
    useState(false);
  const [selectedMailForOperation, setSelectedMailForOperation] =
    useState(null);

  const { isMobile } = useScreenDetector();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const addOtherEmailForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: addOtherEmailSchema,
    onSubmit: (values) => {
      addOtherEmailForm.setFieldError("email", null);
      setLoading(true);
      AppHttp.post(addOtherEmailEndpoint, values).then((result) => {
        if (result.success) {
          onNewAdded(result.data);
          setAddNewDialogVisible(false);
          setLoading(false);
        } else {
          setLoading(false)
          addOtherEmailForm.setFieldError("email", result.error.message);
        }
      });
    },
  });

  function submitAddOtherEmailForm() {
    addOtherEmailForm.submitForm();
  }

  const isFormFieldInvalid = (msg) =>
    !!(addOtherEmailForm.touched[msg] && addOtherEmailForm.errors[msg]);

  const getFormErrorMessage = (msg) => {
    return isFormFieldInvalid(msg) ? (
      <small
        className={`${
          addOtherEmailForm.errors[msg] ? "block" : "hidden"
        } p-error`}
      >
        {addOtherEmailForm.errors[msg]}
      </small>
    ) : (
      ""
    );
  };

  function onSetOtherEmailAsPrimaryClicked(email) {
    setSelectedMailForOperation(email);

    setConfirmChangePrimaryVisible(true);
  }

  function onDeleteEmailClicked(email) {
    setSelectedMailForOperation(email);
    setConfirmDeleteialogVisible(true);
  }

  function onSetOtherEmailAsPrimary() {
    if (!selectedMailForOperation) {
      return;
    }

    AppHttp.get(setOtherEmailAsPrimaryEndpoint, {
      id: selectedMailForOperation.id,
    }).then((result) => {
      if (result.success) {
        onPrimaryChanged();

        setConfirmChangePrimaryVisible(false);
        setSelectedMailForOperation(null);
      }
    });
  }

  function onDeleteEmail() {
    if (!selectedMailForOperation) {
      return;
    }
    setLoadingDelete(true);
    AppHttp.delete(deleteOtherEmailEndpoint, {
      id: selectedMailForOperation.id,
    }).then((result) => {
      if (result.success) {
        onDeleted(selectedMailForOperation.id);

        setConfirmDeleteialogVisible(false);
        setSelectedMailForOperation(null);
      }
      setLoadingDelete(false);
    });
  }

  function onDeleteEmailCancel() {
    setConfirmDeleteialogVisible(false);
    setSelectedMailForOperation(null);
  }

  return (
    <>
      <div className="flex flex-column lg:flex-row gap-5">
        <div className="w-full lg:w-16rem flex flex-column gap-2">
          <label className="font-semibold">Other Email Addresses </label>
          <small className="max-w-xs opacity-80">
          For your convenience, we can sync all reflection invitations to your primary email account.
          </small>
        </div>

        <div className="w-full max-w-md flex flex-column gap-3">
          {otherEmails?.map((email) => (
            <div
              key={email.id}
              className="flex flex-column sm:flex-row sm:align-items-center justify-content-between gap-1"
            >
              <div>{email.email}</div>

              <div className="flex gap-3 align-items-center">
                {email.isConfirmed && !email.isPrimary && (
                  <Button
                    link
                    className="font-bold text-primary text-sm"
                    onClick={() => onSetOtherEmailAsPrimaryClicked(email)}
                  >
                    Make primary
                  </Button>
                )}

                {email.canDelete && (
                  <CloseIcon
                    className="text-primary text-base cursor-pointer"
                    onClick={() => onDeleteEmailClicked(email)}
                  />
                )}
              </div>
            </div>
          ))}
          <Button
            className="mr-auto"
            severity="secondary"
            label="+ Add New"
            onClick={() => setAddNewDialogVisible(true)}
          ></Button>
        </div>
      </div>

      {
        // Add new address
      }
      <Dialog
        /*headerStyle={{ padding: "0" }}*/
        position={isMobile ? "bottom" : "center"}
        header="Add alias email"
        className="p-input-filled text-center"
        visible={addNewDialogVisible}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setAddNewDialogVisible(false)}
      >
        <div className="w-full gap-3 flex flex-column text-center align-items-center gap-2 ">
          <p className="text-sm opacity-80">
            We will redirect any reflection requests and notifications from this
            email to your primary email.
          </p>
          <InputText
            className="w-full"
            value={addOtherEmailForm.values.email}
            onChange={(e) =>
              addOtherEmailForm.setFieldValue("email", e.target.value)
            }
          />
          <div className="w-full text-left">{getFormErrorMessage("email")}</div>
          <Button
            className="w-full"
            label="Confirm"
            onClick={submitAddOtherEmailForm}
            loading={loading}
          ></Button>
        </div>
      </Dialog>

      {
        // Confirm primary change
      }
      <Dialog
        position={isMobile ? "bottom" : "center"}
        headerStyle={{ padding: "0" }}
        visible={confirmChangePrimaryVisible}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setConfirmChangePrimaryVisible(false)}
      >
        <div className="flex flex-column gap-3 text-center align-items-center ">
          <p className="font-bold text-2xl">
            Are you sure you want to change your primary email address to:
          </p>
          <p className="font-bold text-xl">
            {selectedMailForOperation?.email}?
          </p>
          <div className="flex justify-content-between gap-2">
            <Button label="Yes" onClick={onSetOtherEmailAsPrimary}></Button>
            <Button
              label="No"
              onClick={() => setConfirmChangePrimaryVisible(false)}
              severity="danger"
              outlined
            ></Button>
          </div>
        </div>
      </Dialog>

      {
        // Confirm delete
      }
      <Dialog
        position={isMobile ? "bottom" : "center"}
        headerStyle={{ padding: "0" }}
        visible={confirmDeleteDialogVisible}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={onDeleteEmailCancel}
      >
        <div className="flex flex-column gap-3 text-center align-items-center ">
          <HighlightOffIcon className="text-red-500 opacity-20 w-full h-5rem " />
          <p className="font-bold text-2xl">
            Are you sure you want to delete this email alias?
          </p>
          <p className="font-bold">{selectedMailForOperation?.email}</p>
          <div className="flex justify-content-between gap-2">
            <Button
              label="Delete"
              severity="danger"
              onClick={onDeleteEmail}
              loading={loadingDelete}
            ></Button>
            <Button
              label="Cancel"
              onClick={onDeleteEmailCancel}
              severity="danger"
              outlined
            ></Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default ProfileOtherEmails;
