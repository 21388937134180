import React from "react";
import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../Logo";
import SARsidebarNav from "./SARsidebarNav";
import RisingSun from "./RisingSun";

function SidebarMobile({ performanceScore, fulfillmentScore }) {
  const [visible, setVisible] = useState(false);

  const customIcons = <Logo />;
  return (
    <>
      <Sidebar
        visible={visible}
        position="top"
        className="h-full"
        onHide={() => setVisible(false)}
        icons={customIcons}
      >
        <div className="h-full flex flex-column gap-5 align-content-between">
          <div className="flex flex-column gap-3 mt-5">
            <h1 className="text-base">SELF-AWARENESS REPORT</h1>
            <SARsidebarNav showHide={() => setVisible(false)} />
          </div>
          <RisingSun
            performanceScore={performanceScore}
            fulfillmentScore={fulfillmentScore}
          />
        </div>
      </Sidebar>
      <MenuIcon
        className="showSidebar"
        onClick={() => setVisible(true)}
      ></MenuIcon>
    </>
  );
}

export default SidebarMobile;
