import React from "react";
import footer from "../../assets/footer-logo.svg";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <footer
      className={`
        ${props.isFullWidth ? "w-full" : "max-w-6xl"}
        relative flex flex-column md:flex-row gap-3 align-items-center 
        justify-content-between text-xs px-3 md:px-5 py-5 mt-auto mx-auto 
        ${props.className}
      `}
    >
      <div className="opacity-70 flex-order-2 md:flex-order-1">
        All Rights Reserved © Mirror 360, Inc. 2023
      </div>
      <Link
        to="https://www.mymirror360.com/"
        target="_blank"
        className="flex-order-1 md:flex-order-2"
      >
        <img alt="" src={footer} />
      </Link>
      <div className="w-auto flex flex-row gap-3 align-item-center justify-content-between md:justify-content-center flex-order-3 mt-2 md:mt-0">
        <Link
          className="text-700 no-underline text-opacity-70"
          to="https://www.mymirror360.com/privacy-policy"
          target="_blank"
        >
          <span className="no-underline">Privacy Policy</span>
        </Link>{" "}
        |
        <Link
          className="text-700 no-underline text-opacity-70"
          to="https://www.mymirror360.com/terms-of-use"
          target="_blank"
        >
          Terms of Use
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
