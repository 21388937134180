import React from "react";
import RisingSun from "./RisingSun";
import SARsidebarNav from "./SARsidebarNav";

function SidebarDesktop({ performanceScore, fulfillmentScore }) {
  return (
    <aside className="self-awareness_sidebar h-full flex flex-column gap-5 align-content-between px-5 pt-8 pb-5">
      <div className="flex flex-column gap-3 mt-5">
        <h1 className="text-base">SELF-AWARENESS REPORT</h1>
        <SARsidebarNav />
      </div>
      <RisingSun
        performanceScore={performanceScore}
        fulfillmentScore={fulfillmentScore}
      />
    </aside>
  );
}

export default SidebarDesktop;
