import React, { useCallback, useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Slider from "./Slider";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import chroma from "chroma-js";
import Logo from "../Logo";
import ReflectionLayout from "./ReflectionLayout";
import ReflectionContainer from "./ReflectionContainer";
import ReflectionWrapper from "./ReflectionWrapper";
import ReflectionBody from "./ReflectionBody";
import backgroundImage from "../../assets/performance_bg-img.png";
import OverallScores from "./OverallScores";
import {
  performanceReflectionGetByTokenEndpoint,
  submitPerformanceReflectionEndpoint,
} from "../../service/api-temp";
import { AppHttp } from "../../service/app-http";
import { performanceReflectionSchema } from "../../service/validationschemas/validation-schemas";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";

function Reflection() {
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [error, setError] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [reflection, setReflection] = useState(null);
  const [areAllQuestionsTouched, setAreAllQuestionsTouched] = useState(false);
  const [width, setWidth] = useState("0%");
  const [fetchedFromApi, setFetchedFromApi] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const formik = useFormik({
    initialValues: {
      id: null,
      overallScore: null,
      noteForReflectee: "",
      q1Score: 500,
      q2Score: 500,
      q3Score: 500,
      q4Score: 500,
      q5Score: 500,
    },
    validationSchema: performanceReflectionSchema,
    onSubmit: (values) => {
      setLoading(true)
      AppHttp.post(submitPerformanceReflectionEndpoint, values).then(
        (result) => {
          if (result.success) {
            navigate("/thankyou", {
              state: {
                token: searchParams.get("token"),
              },
            });
          } else {
            setLoading(false)
            alert("Something went wrong! Please try again");
          }
        }
      );
    },
  });

  useEffect(() => {
    if (!searchParams.has("token")) {
      navigate("/dashboard/reflectors-overview");
    }
    async function getReflectionFromApi() {
      setFetchedFromApi(true);
      
      var result = await AppHttp.get(performanceReflectionGetByTokenEndpoint, {
        token: searchParams.get("token"),
      });

      if (result.success) {
        setReflection(result.data);
        formik.setFieldValue("id", result.data.id);
      } else {
        if (result.error.code === "filled") {
          navigate("/completed", {
            state: {
              token: searchParams.get("token"),
            },
          });
        } else if (result.error.code === "expired") {
          navigate("/sorry", {
            state: {
              token: searchParams.get("token"),
            },
          });
        } else {
          setError(result.error.message);
          setErrorDialogVisible(true);
        }
      }
    }

    if (!fetchedFromApi) {
      getReflectionFromApi();
    }
  }, [formik, navigate, reflection, searchParams,fetchedFromApi]);

  const checkIfQuestionAreTouched = useCallback(() => {
    const q1Touched = formik.initialValues.q1Score !== formik.values.q1Score;
    const q2Touched = formik.initialValues.q2Score !== formik.values.q2Score;
    const q3Touched = formik.initialValues.q3Score !== formik.values.q3Score;
    const q4Touched = formik.initialValues.q4Score !== formik.values.q4Score;
    const q5Touched = formik.initialValues.q5Score !== formik.values.q5Score;

    const touchedElements = [
      q1Touched,
      q2Touched,
      q3Touched,
      q4Touched,
      q5Touched,
    ];
    let width = 0;
    for (let i = 0; i < touchedElements.length; i++) {
      if (touchedElements[i]) {
        width = (i + 1) * 20 + "%";
      }
    }
    setWidth(width);

    if (q1Touched && q2Touched && q3Touched && q4Touched && q5Touched) {
      setAreAllQuestionsTouched(true);
    }
  }, [formik.values, formik.initialValues, setAreAllQuestionsTouched]);

  useEffect(() => {
    if (!areAllQuestionsTouched) {
      checkIfQuestionAreTouched();
    }
  }, [areAllQuestionsTouched, checkIfQuestionAreTouched]);

  function onSelectOverallScore(blockId) {
    formik.setFieldValue("overallScore", blockId);
  }

  function handleQuestionChanged(question, value) {
    const qIndex =
      reflection.questions.findIndex((q) => q.id === question.id) + 1;
    formik.setFieldValue(`q${qIndex}Score`, Number(value?.toFixed(0) ?? 0));
  }

  function handleNoteChanged(e) {
    formik.setFieldValue("noteForReflectee", e.target.value);
  }

  function submitFormik() {
    if (areAllQuestionsTouched) {
      formik.submitForm();
    }
  }

  const toggleIsActive = () => {
    setIsActive(true);
  };

  const customChroma = chroma
    .scale(["757575", "C8C4B7", "F1E8CA", "FFE493", "FFC539", "fbc02d"])
    .domain([0, 200, 400, 600, 800, 1000]);

  return (
    <ReflectionLayout>
      <div
        className="fixed z-0 top-0 bottom-0 left-0 right-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <Link
        to="https://www.mymirror360.com/"
        target="_blank"
        className="relative mt-5 mx-auto"
      >
        <Logo />
      </Link>
      <div className="w-full" />
      <ReflectionContainer>
        <div className="flex flex-column gap-3 px-3 mb-3 lg:mb-6">
          <h1 className="text-3xl sm: text-4xl md:text-5xl lg:text-6xl mirror-dark-blue-text">
            How is{" "}
            <span className="text-orange-500">
              {reflection?.firstNameAndInitial}
            </span>{" "}
            working lately?
          </h1>
          <p className="text-sm lg:text-base">
            <span className="font-bold">Honest</span> reflections are the best
            way to help {reflection?.firstName} improve. Remember, your
            reflections are always <span className="font-bold">anonymous!</span>
          </p>
        </div>
        <OverallScores
          isSelf={false}
          isActive={isActive}
          toggleIsActive={toggleIsActive}
          scoreSelected={onSelectOverallScore}
        />
        {isActive && (
          <ReflectionWrapper>
            <div className="mirror-card_background-blur" />
            <ReflectionBody>
              {reflection?.questions &&
                reflection?.questions?.map((q, index) => {
                  return (
                    <Slider
                      key={q.id}
                      question={q}
                      initial={formik.values[`q${index + 1}Score`]}
                      min={0}
                      max={1000}
                      formatFn={(number) => Math.round(number)}
                      onChange={handleQuestionChanged}
                      colorBarStart="bg-gray-600"
                      colorBarEnd="bg-yellow-500"
                      customChroma={customChroma}
                      isSelf={false}
                    />
                  );
                })}
              <InputTextarea
                value={formik.values.noteForReflectee}
                onChange={handleNoteChanged}
                className="w-full mirror-gray-bg my-5"
                placeholder="What behaviors drove your choices? This is a safe space and a direct channel to give honest feedback. No one else is going to read this but them!"
                style={{ fontSize: "0.85rem" }}
                rows={8}
              />
              <Button
                disabled={!areAllQuestionsTouched}
                onClick={submitFormik}
                label="Submit 360 Reflection"
                className="w-14rem"
                loading={loading}
              ></Button>
              {!areAllQuestionsTouched && (
                <small className="text-primary">
                  Complete all questions before submitting.
                </small>
              )}
              <small className="opacity-80">
                <span className="font-bold">Reflecting honestly</span> is the
                best way to help {reflection?.firstName} improve!
                <br />
                Our promise to you: your individual reflections are always{" "}
                <span className="font-bold">anonymous.</span>
              </small>
            </ReflectionBody>
          </ReflectionWrapper>
        )}
      </ReflectionContainer>
      <Footer></Footer>
      <Dialog
        headerStyle={{ padding: "0" }}
        visible={errorDialogVisible}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setErrorDialogVisible(false)}
        closeOnEscape={false}
        closable={false}
      >
        <div className="flex flex-column gap-3 text-center align-items-center p-5 pb-2 ">
          <p className="font-bold text-2xl">{error}</p>
          <div className="flex justify-content-between gap-2">
            <Button
              label="Home"
              onClick={() => navigate("/dashboard/reflectors-overview")}
            ></Button>
          </div>
        </div>
      </Dialog>
      <div className="reflection_progress-wrapper">
        <div
          style={{ width: `${width}` }}
          className="reflection_progress-line"
        ></div>
      </div>
    </ReflectionLayout>
  );
}

export default Reflection;
