import React, { useEffect, useRef, useState } from "react";
import Footer from "../footer/Footer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import thankYou from "../../assets/thank-you.svg";
import Logo from "../Logo";
import backgroundImage from "../../assets/bg-thankyou.png";
import { useLocation, useNavigate } from "react-router-dom";
import { AppHttp } from "../../service/app-http";
import { performanceReflectionGetDataForCompleted } from "../../service/api-temp";
import {
  getAuthData,
  getToken,
  isTokenExpired,
} from "../../service/UserAuthData";
import { Link } from "react-router-dom";
import Loading from "../Loading";

function ThankYou() {
  const [loading, setLoading] = useState(true)
  const reactLocation = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const isAuth = getToken() && !isTokenExpired();

  if (!reactLocation?.state?.token) {
    navigate("/dashboard/reflectors-overview");
  }

  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
  }

  useEffect(() => {
    async function getDataForReflection() {
      const data = await AppHttp.get(performanceReflectionGetDataForCompleted, {
        token: reactLocation.state.token,
      }).then((response) => {
        setLoading(false)
        if (response.success) {
          const date = new Date(response.data.expiresAt);
          const tokenExpirationDateInMs = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
          );
          const diffInMiliseconds = tokenExpirationDateInMs - Date.now();
          const msInMinute = 60000;
          const msInHour = msInMinute * 60;
          const msInDay = msInHour * 24;

          const daysDiff = Math.round(diffInMiliseconds / msInDay);

          setData({
            ...response.data,
            daysDiff: daysDiff,
          });
        } else {
          navigate("/dashboard/reflectors-overview");
        }
      });
    }

    if (!data) {
      getDataForReflection();
    }
  });

  function navigateToCode() {
    navigate("/auth/redeem-invite", {
      state: {
        code: data?.token,
      },
    });
  }

  return (
    <Loading visible={loading}>
    <div className="flex flex-column min-h-screen justify-content-between relative lg:gap-0 gap-5">
      <Link
        to="https://www.mymirror360.com/"
        target="_blank"
        className="relative z-2 mt-5 mx-auto mb-auto"
      >
        <Logo />
      </Link>
      <div
        className="absolute w-full h-full bg-cover lg:bg-left-bottom bg-center-bottom z-0"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className="my-auto align-items-center flex flex-column lg:flex-row gap-6 md:gap-5 justify-content-between z-2 px-5 max-w-6xl mx-auto">
        <div className="flex flex-column align-items-start gap-5 lg:mx-0 mx-auto max-w-lg">
          <img className="w-8" alt="Thank you" src={thankYou}></img>
          <div className="text-justify md:text-left text-sm max-w-md">
            This reflection{" "}
            <span className="font-bold">
              is helping <span>{data?.name}</span> be a better teammate.
            </span>{" "}
            <br className="hidden lg:block" />
            See you again in the future for timely feedback!
          </div>
        </div>

        {data?.daysDiff > 0 && !isAuth && (
          <div className="flex flex-column gap-4 gap-6 md:gap-4 mx-4 md:mx-0 max-w-lg">
            <div
              style={{ boxShadow: "0 0 8px #fccc55" }}
              className="bg-white border-solid border-round-xl p-5 border-yellow-400 border-none flex flex-column gap-4"
            >
              <div className="font-semibold text-left text-lg">
                YOU ARE NOW INVITED!
              </div>
              <div className="md:w-11 text-left text-sm opacity-80">
                As a token of our thanks, we're giving you a special invitation
                code for a free account with My Mirror 360
              </div>
              <div className="flex flex-column gap-2">
                <div className="flex flex-row gap-2 align-items-stretch flex-wrap">
                  <div className="flex flex-row gap-2 align-items-center">
                    <ContentCopyIcon onClick={copyToClipboard}  className="cursor-pointer" />
                    <div className="flex flex-column justify-content-start align-items-baseline gap-2">
                      <InputText
                        ref={textAreaRef}
                        className="p-inputtext-md"
                        defaultValue={data?.token}
                      />
                    </div>
                  </div>
                  <Button
                    className="flex-grow-1"
                    label="Activate Now"
                    onClick={navigateToCode}
                  ></Button>
                </div>
                <div className="font-bold text-xs lg:ml-5 ml-auto mr-auto">
                  Expires in:
                  <span className="text-green-600">
                    {" "}
                    {data?.daysDiff} days{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="max-w-2xl flex align-items-center justify-content-between flex-wrap gap-3">
              <div className="w-8 text-justify md:text-left text-xs opacity-80">
                My Mirror 360 is an innovative feedback tool that empowers you
                to drive your own growth.
              </div>
              <Link to="https://www.mymirror360.com/" target="_blank">
                <Button label="Learn More" outlined></Button>
              </Link>
            </div>
          </div>
        )}
      </div>
      <Footer className="z-2"></Footer>
    </div>
    </Loading>
  );
}

export default ThankYou;
