import { number, string } from "yup";
import {
  relationshipEmailExistsEndpoint,
  validateInviteCodeUrl,
} from "../api-temp";
import { AppHttp } from "../app-http";

export const inviteCodeValidator = string()
  .required("Invitation code is required")
  .test({
    name: "valid code",
    test: async (value, ctx) => {
      return await AppHttp.get(validateInviteCodeUrl, {
        code: value,
      }).then((response) => {
        if (response.success) {
          return true;
        } else {
          const errMsg = response.error.message;
          return ctx.createError({ message: errMsg });
        }
      });
    },
  });

export const emailValidator = string()
  .required("Email is requred")
  .email("Invalid email");

export const emailRelationshipvalidator = emailValidator.test({
  name: "unique-relationship-email",
  test: async (value, ctx) => {
    return await AppHttp.post(relationshipEmailExistsEndpoint, {
      email: value,
    }).then((response) => {
      if (response.success) {
        if (response.data) {
          // Email exists
          return ctx.createError({
            message: "A reflector with this email already exists",
          });
        }

        return true;
      } else {
        const errMsg = response.error.message;
        return ctx.createError({ message: errMsg });
      }
    });
  },
});

export const passwordValidator = string()
  .required("Password is requred")
  .min(8, "Password must be at least 8 characters");

  /**
   * 
   * @param {string} passwordFieldName - The name of the field containing the password value to match agains. "password" by default
   * @returns passwordValidator with added check for mathching password.
   */
export const passwordMatchValidator = (passwordFieldName = "password") => {
  return passwordValidator.test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent[passwordFieldName] === value;
    }
  );
};

export const nameValidator = string().required("Full name is required");

export const proximityValidator = number()
  .required("Select proximity")
  .integer();
export const overallScoreValidator = number()
  .required("Overall score is required")
  .integer();
export const questionValidator = number().min(0).max(1000);
