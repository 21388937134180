import React from 'react';

const ReflectionWrapper = ({ children }) => {
    return (
        <div className="reflection_wrapper animation-block relative w-full p-3 py-5 sm:p-5 lg:p-8 mt-5">
            {children}
        </div>
    );
};

export default ReflectionWrapper;
