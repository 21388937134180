import { Chart } from "primereact/chart";
import React, { useEffect, useState } from "react";

function Trends({ performanceTrends, fulfillmentTrends }) {
  const [newChartData, setNewChartData] = useState(null);

  function formatDate(date) {
    let year = date.getUTCFullYear();   // don't convert to local time
    let month = date.getUTCMonth() + 1; // 0 indexed; don't convert to local time
    let day = date.getUTCDate();        // don't convert to local time

    while (month.toString().length < 2) {
      month = "0" + month;
    }

    while (day.toString().length < 2) {
      day = "0" + day;
    }

    return year + "-" + month + "-" + day;
  }

  useEffect(() => {
    if (!performanceTrends || !fulfillmentTrends) {
      return;
    }

    const performanceDates = performanceTrends.map((p) => formatDate(p.date));
    const fulfilmentDates = fulfillmentTrends.map((p) => formatDate(p.date));

    let mergedDates = new Set(performanceDates.concat(fulfilmentDates));
    mergedDates = Array.from(mergedDates).sort(
      (a, b) => new Date(formatDate(new Date(a))) - new Date(formatDate(new Date(b)))
    );

    const sortedData1 = mergedDates.map((md) =>
      fulfillmentTrends.find((i) => formatDate(i.date) === formatDate(new Date(md)))
    );

    const sortedData2 = mergedDates.map((md) =>
      performanceTrends.find((i) => formatDate(i.date) === formatDate(new Date(md)))
    );

    setNewChartData({
      labels: mergedDates.map((entry) => {
        return formatDate(new Date(entry));
      }),
      datasets: [
        {
          label: "Fulfillment",
          data: sortedData1.map((entry) => entry?.score),
          //data: sortedData1,
          spanGaps: true,
          borderColor: "#d87025",
          backgroundColor: "rgba(216, 112, 37, 0.5)",
          fill: false,
          tension: 0.1,

        },
        {
          label: "Performance",
          data: sortedData2.map((entry) => entry?.score),
          //data: sortedData2,
          spanGaps: true,
          borderColor: "#ffae00",
          backgroundColor: "rgba(255, 174, 0, 0.5)",
          fill: false,
          tension: 0.1,

        },
      ],
    });
  }, [performanceTrends, fulfillmentTrends]);

  const newChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          boxWidth: 0,
          usePointStyle: true,
          font: {
            weight: "600",
          },
          color: "#d87025",
        },
      },
      title: {
        display: false,
      },
      tooltip: { // Updated to 'tooltip' for Chart.js v3
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {

              if (context.dataset.label === 'Fulfillment') {
                label += context.raw;
                label += '%'; // Append '%' only for 'Fulfillment' dataset
              } else {
                label += Math.floor((context.raw / 100) * 360);
              }
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        type: "time",
        time: {
          parser: "YYYY-MM-DD",
          tooltipFormat: "ll",
          unit: "day",
          displayFormats: {
            day: "MMM DD, yyyy",
          },
        },
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: false,
          drawTicks: true,
          zeroLineColor: "transparent",
          zeroLineWidth: 0,
        },
        ticks: {
          maxRotation: 90, // Rotate labels by 45 degrees
          minRotation: 90, // Rotate labels by 45 degrees
        },
      },
      y: {
        display: true,
        min: 0,
        max: 102,
        ticks: {
          display: false,
        },
        grid: {
          drawBorder: false,
          display: true,
          drawTicks: false,
        },
      },
    },
    layout: {
      padding: {
        left: 28
      }
    }
  };

  return (
    <section
      id="Trends"
      className="flex flex-column gap-5 mt-5 max-w-3xl mx-auto"
    >
      <div className="text-center flex flex-column gap-2 max-w-2xl mx-auto">
        <h2 className="md:text-2xl text-xl">Trend over Time</h2>
        <p className="text-xs opacity-80">
          This is how your M360 Score and Fulfillment at work has changed over
          time. Do you notice any correlation? Often your team's perception of
          your performance affects you satisfaction at work, and vice versa.
        </p>
      </div>
      <div className="trend-chart_wrapper mt-5">
        <div className="mirror-card_background-blur" />
        <div className="trend-chart_bg" />
        <div className="trend-chart_bar trend-chart_bar-fulfillment">
          <span className="trend-chart_bar-title">Fulfillment</span>
        </div>
        <div className="trend-chart_bar trend-chart_bar-performance">
          <span className="trend-chart_bar-title">Performance</span>
        </div>
        {newChartData && (
          <Chart
            type="line"
            data={newChartData}
            options={newChartOptions}
            className="trend-over-time-chart"
          />
        )}
      </div>
    </section>
  );
}

export default Trends;