import { Button } from "primereact/button";
import React from "react";
import { Link } from "react-router-dom";

function Allset() {
  return (
    <div className="max-w-sm flex flex-column gap-3  text-center">
      <h1 className="text-2xl">You are all set!</h1>
      <Link to="/auth/login" className="">
        <Button>Log in Now</Button>
      </Link>
    </div>
  );
}

export default Allset;
