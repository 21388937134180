import React, { useContext, useState } from "react";
import ReflectorsMobar from "./ReflectorsMbileBar";
import { Button } from "primereact/button";
import YouCircle from "./YouCircle";
import AddIcon from "@mui/icons-material/Add";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RelationshipProximity } from "../../service/enums";
import PersonIcon from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { UserDataState } from "../store_providers/user-data-store";
import { getFirstNameFromName } from "../../service/functions/user-name-functions";

export default function AddReflectorsMobile(props) {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [userData] = useContext(UserDataState);

  function show() {
    props.position("bottom");
    props.showAdd(true);
  }
  function showDialog() {
    props.position("bottom");
    props.show7Dialog(true);
  }
  function showEdit(param) {
    props.position("bottom");
    props.setEditRelationship(param);
  }
  function onCellClicked(e) {
    if (
      e?.value?.column?.props?.field === "reflectorAlias" ||
      e?.value?.column?.props?.field === "relationshipProximity"
    ) {
      props.setEditRelationship(e.value.rowData);
    }
  }
  const proximityBodyTemplate = (circle) => {
    if (circle.relationshipProximity === RelationshipProximity.Often) {
      return (
        <div className={`cursor-pointer flex flex-column align-items-end`}>
          <div className="flex ">
            <PersonIcon fontSize="inherit" />
            <PersonIcon fontSize="inherit" />
          </div>
        </div>
      );
    } else if (
      circle.relationshipProximity === RelationshipProximity.Occasionally
    ) {
      return (
        <div className={`cursor-pointer flex flex-column align-items-end`}>
          <div className="flex ">
            <PersonIcon fontSize="inherit" />
            <MoreHorizIcon fontSize="inherit" />
            <PersonIcon fontSize="inherit" />
          </div>
        </div>
      );
    } else {
      return (
        <div className={`cursor-pointer flex flex-column align-items-end`}>
          <div className="flex ">
            <PersonIcon fontSize="inherit" />
            <span className="flex p-0">
              <MoreHorizIcon fontSize="inherit" className="rightDots" />
              <MoreHorizIcon fontSize="inherit" />
            </span>
            <PersonIcon fontSize="inherit" />
          </div>
        </div>
      );
    }
  };
  const statusBodyTemplate = (circle) => {
    return (
      <div onClick={() => showEdit(circle)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M1.91667 14.3327H3.08333L10.2708 7.14518L9.10417 5.97852L1.91667 13.166V14.3327ZM13.8333 5.93685L10.2917 2.43685L11.4583 1.27018C11.7778 0.950738 12.1701 0.791016 12.6354 0.791016C13.1007 0.791016 13.4931 0.950738 13.8125 1.27018L14.9792 2.43685C15.2986 2.75629 15.4653 3.14171 15.4792 3.5931C15.4931 4.04449 15.3403 4.4299 15.0208 4.74935L13.8333 5.93685ZM12.625 7.16602L3.79167 15.9993H0.25V12.4577L9.08333 3.62435L12.625 7.16602Z"
            fill="#1C1B1F"
          />
        </svg>
      </div>
    );
  };
  return (
    <>
      <div className="reflectors_wrapper flex flex-column gap-5 px-3 md:px-5 pt-8 pb-5">
        <div className="text-center flex flex-column gap-1 pb-3">
          <p className="text-sm opacity-60">
            Hi, {getFirstNameFromName(userData.name)}
          </p>
          <h1 className="text-xl sm:text-2xl">ADD YOUR REFLECTORS</h1>
          <p className="text-sm opacity-60">
            You'll need seven to activate your 360° Mirror.
            <br />
            <strong>Whose opinion matters to you the most?</strong>
          </p>
        </div>
        <div className="reflectors_ellipse-wrapper flex align-items-center justify-content-center my-0 sm:my-5">
          <div className="reflectors_ellipse-background" />
          <div className="reflectors_circle-list">
            <div className=" n-circle flex align-items-center justify-content-center">
              {props.relationships?.map((reflector, index) => (
                <div
                  key={index}
                  className={`text-900 font-semibold circle mob-deg-${index * 30
                    } `}
                  onClick={() => showEdit(reflector)}
                >
                  <div
                    className={`reflectors_proximity-indicator-${reflector.relationshipProximity
                      } opacity-60 pointer-events-none absolute line-l line-${index + 1
                      } `}
                  />
                  <div className="text-xs">{reflector.initials}</div>
                </div>
              ))}
              {props.count < userData.reflectorsCap && (
                <div className={"flex flex-column align-items-center "}>
                  <Button
                    className={`circle bg-white border-500 text-900 flex align-items-center  m-circle-button mob-deg-${props.relationships?.length * 30
                      }  `}
                    disabled={props.count >= userData.reflectorsCap || props.count === 12}
                    onClick={show}
                    visible={props.count === 12 ? false : true}
                  >
                    <AddIcon className="text-500" />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <YouCircle count={props.count} maxValue={props.maxValue} />
        </div>
        <div className={`text-sm flex gap-2 flex-column pt-5 ${props.count >=7 ? 'mb-8' : 'mb-5'} align-items-start font-montserrat font-bold`}>
          <div className="max-w-sm flex flex-column gap-1 text-sm md:text-sm  ">
            <div className="flex flex-row gap-2 align-items-center">
              <div className="reflectors_proximity-indicator opacity-60" />
              <div className="opacity-60">Often</div>
            </div>
            <div className="flex flex-row gap-2 align-items-center">
              <div className="reflectors_proximity-indicator-occ opacity-60" />
              <div className="opacity-60">Occasionally</div>
            </div>
            <div className="flex flex-row gap-2 align-items-center">
              <div className="reflectors_proximity-indicator-spor opacity-60" />
              <div className="opacity-60">Sporadically</div>
            </div>
          </div>
          <div
            onClick={() => setVisible1(true)}
            className="flex gap-1 pb-3 align-items-center w-6"
          >
            <HelpOutlineIcon fontSize="inherit" />
            <small className="opacity-60 cursor-pointer z-3">
              What happens next?
            </small>
          </div>
        </div></div>
      <div className="fixed bottom-0 w-screen flex flex-column z-4 pb-3">
        <div className={`bg-white absolute border-top-1 surface-border ${props.count >= 7 ? 'h-7rem' : 'h-4rem' } bottom-0 w-full`}></div>
        <div className="flex w-screen justify-content-between px-2 pb-2 align-items-end z-2" >
          <div className='flex align-items-center'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <mask
                id="mask0_2872_3867"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="25"
              >
                <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2872_3867)">
                <path
                  d="M1 20.5V17.7C1 17.1333 1.14583 16.6125 1.4375 16.1375C1.72917 15.6625 2.11667 15.3 2.6 15.05C3.63333 14.5333 4.68333 14.1458 5.75 13.8875C6.81667 13.6292 7.9 13.5 9 13.5C10.1 13.5 11.1833 13.6292 12.25 13.8875C13.3167 14.1458 14.3667 14.5333 15.4 15.05C15.8833 15.3 16.2708 15.6625 16.5625 16.1375C16.8542 16.6125 17 17.1333 17 17.7V20.5H1ZM19 20.5V17.5C19 16.7667 18.7958 16.0625 18.3875 15.3875C17.9792 14.7125 17.4 14.1333 16.65 13.65C17.5 13.75 18.3 13.9208 19.05 14.1625C19.8 14.4042 20.5 14.7 21.15 15.05C21.75 15.3833 22.2083 15.7542 22.525 16.1625C22.8417 16.5708 23 17.0167 23 17.5V20.5H19ZM9 12.5C7.9 12.5 6.95833 12.1083 6.175 11.325C5.39167 10.5417 5 9.6 5 8.5C5 7.4 5.39167 6.45833 6.175 5.675C6.95833 4.89167 7.9 4.5 9 4.5C10.1 4.5 11.0417 4.89167 11.825 5.675C12.6083 6.45833 13 7.4 13 8.5C13 9.6 12.6083 10.5417 11.825 11.325C11.0417 12.1083 10.1 12.5 9 12.5ZM19 8.5C19 9.6 18.6083 10.5417 17.825 11.325C17.0417 12.1083 16.1 12.5 15 12.5C14.8167 12.5 14.5833 12.4792 14.3 12.4375C14.0167 12.3958 13.7833 12.35 13.6 12.3C14.05 11.7667 14.3958 11.175 14.6375 10.525C14.8792 9.875 15 9.2 15 8.5C15 7.8 14.8792 7.125 14.6375 6.475C14.3958 5.825 14.05 5.23333 13.6 4.7C13.8333 4.61667 14.0667 4.5625 14.3 4.5375C14.5333 4.5125 14.7667 4.5 15 4.5C16.1 4.5 17.0417 4.89167 17.825 5.675C18.6083 6.45833 19 7.4 19 8.5Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>

            <span className="pl-1 text-sm font-montserrat font-bold" onClick={() => setVisible(true)}>
              All Reflectors
            </span>
          </div>

          <div className="font-montserrat mr-7 text-center flex flex-row gap-2 align-items-center align-self-end ">
            <span className="font-bold font-montserrat text-primary text-2xl">
              {props.count}
            </span>
            <small className="font-montserrat">
              {" / "}
              {props.maxValue}
            </small>
          </div>
          <div className="flex flex-row align-items-end">
            <div className=" text-sm flex flex-column align-items-center gap-1">
              <Button
                className="p-button-rounded p-button-primary flex align-items-center justify-content-center m-circle-button"
                disabled={props.count >= userData.reflectorsCap || props.count === 12}
                onClick={show}
              >
                <AddIcon />
              </Button>
              <p className="font-bold">Add</p>
            </div>
          </div>
        </div>
        {props.count >= 7 && (
          <Button onClick={showDialog} className="flex mx-2" label="Activate Reflections"></Button>
        )}

      </div>

      <Dialog
        className="mobileDialogAllReflectors"
        style={{ minWidth: "20rem" }}
        header="All Reflectors"
        position="bottom"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <DataTable
          onSelectionChange={onCellClicked}
          value={props.relationships}
          tableStyle={{
            fontSize: "0.90rem",
          }}
          scrollable
          scrollHeight="20rem"
          style={{ fontFamily: "Montserrat" }}
          cellSelection
          selectionMode="single"
          filterDisplay="none"
          removableSort
          emptyMessage="No reflectors found."
        >
          <Column field="reflectorAlias" header="Name"></Column>
          <Column
            field="relationshipProximity"
            body={proximityBodyTemplate}
            header="Proximity"
            alignHeader="center"
          ></Column>
          <Column
            body={statusBodyTemplate}
            headerStyle={{ width: "15%", minWidth: "2rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        </DataTable>
      </Dialog>
      <Dialog
        position="bottom"
        visible={visible1}
        className="mobileWhatHappens"
        onHide={() => setVisible1(false)}
      >
        <p>
          By clicking on "activate reflections" you simply confirm your
          preliminary choices for whose opinion matters to you. The system will
          start to ODDly (occasionally, discretely, and delightfully) ask your
          teammates about their honest perspectives on you.
        </p>
        <p>
          Rest assured, you can always swap or add reflectors or pause
          reflections altogether (from your settings screen).
        </p>
      </Dialog>
    </>
  );
}
