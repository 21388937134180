import { Button } from "primereact/button";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { resendForgotPasswordUrl } from "../../service/api-temp";

function EmailSent() {
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const email = location?.state?.email;
  const [emailSent, setEmailSent] = useState();

  function resendEmail() {
    setEmailSent("");
    setLoading(true)

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    fetch(resendForgotPasswordUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ email: email }),
      
    }).then((response) => {
        setLoading(false);
        setEmailSent("Email resent!");
    });
  }

  return (
    <div className="max-w-sm flex flex-column gap-5 text-center">
      <div className="flex flex-column gap-2">
        <h1 className="text-2xl">Email Sent</h1>
        <p className="text-sm opacity-60">
          We sent an email to {email}. If this email is connected to a My Mirror 360
          account, you'll be able to reset your password.
        </p>
      </div>
      <div className="w-full button-group justify-content-center align-items-center">
        <Button
          onClick={resendEmail}
          severity="secondary"
          label="Resend Email"
          loading={loading}
        ></Button>
        <Link className="no-underline text-white" to="/auth/login">
          <Button className="w-full justify-content-center">
            Return To Login
          </Button>
        </Link>
      </div>
        <p className="text-red-600 text-sm">{emailSent}</p>
    </div>
  );
}

export default EmailSent;
