import React, { useContext, useEffect, useState } from "react";
import {
  clearAuthData,
  getAuthData,
  getToken,
  setAuthDataObj,
} from "../../service/UserAuthData";
import { AppHttp } from "../../service/app-http";
import { autoLoginUrl } from "../../service/api-temp";
import { UserDataState } from "../store_providers/user-data-store";

function AutoLogin({ children }) {
  const [finishedAutoLogin, setFinishedAutoLogin] = useState(false);
  const [userData, dispatch] = useContext(UserDataState);

  useEffect(() => {
    async function autoLogin() {
      if (getAuthData() && getToken()) {
        await AppHttp.get(autoLoginUrl)
          .then((response) => {
            if (response.success) {
              setAuthDataObj({
                token: response.data.token,
                expires: response.data.expires,
              });

              delete response.data.token;
              delete response.data.expires;

              dispatch({ type: "set", payload: response.data });
            } else {
              clearAuthData();
              dispatch({ type: "delete" });
            }
          })
          .catch(() => {
            clearAuthData();
            dispatch({ type: "delete" });
          });
      }

      setFinishedAutoLogin(true);
    }

    autoLogin();
  }, [dispatch]);

  return finishedAutoLogin && children;
}

export default AutoLogin;
