import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import PersonIcon from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, { useState } from "react";
import { RelationshipProximity, RelationshipType } from "../../service/enums";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { updateRelationshipSchema } from "../../service/validationschemas/validation-schemas";
import { Dialog } from "primereact/dialog";

function EditRelationshipForm(props) {
  const [loading, setLoading] = useState(false)
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const types = [
    { id: RelationshipType.Peer, name: "Peer" },
    { id: RelationshipType.Junior, name: "Junior" },
    { id: RelationshipType.Senior, name: "Senior" },
    { id: RelationshipType.Client, name: "Client" },
  ];

  const formik = useFormik({
    initialValues: {
      id: props.user.id,
      reflectorEmail: props.user.reflectorEmail,
      reflectorAlias: props.user.reflectorAlias,
      relationshipProximity: props.user.relationshipProximity,
      relationshipType: props.user.relationshipType,
    },
    validationSchema: updateRelationshipSchema,
    onSubmit: (values) => {
      setLoading(true)
      props.onSubmit(values);
      formik.resetForm({ values: "" });
    },
  });

  function deleteRelationship() {
    props.onDelete(props.user);
    setLoading(true)
  }

  function setRelationshipType(e) {
    formik.setFieldValue("relationshipType", e.value);
  }

  function handleSelection(number) {
    if (formik.values.relationshipProximity === number) {
      formik.setFieldValue("relationshipProximity", null);
    } else {
      formik.setFieldValue("relationshipProximity", number);
    }
  }

  const isFormFieldInvalid = (msg) =>
    !!(formik.touched[msg] && formik.errors[msg]);

  const getFormErrorMessage = (msg) => {
    return isFormFieldInvalid(msg) ? (
      <small className={`${formik.errors[msg] ? "block" : "hidden"} p-error`}>
        {formik.errors[msg]}
      </small>
    ) : (
      ""
    );
  };

  return (
    <form
      className="w-full h-full flex flex-column gap-5 text-left"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex flex-column gap-3">
        <div className="text-center text-sm">{formik.values.reflectorEmail}</div>
        <div className="flex flex-column gap-2">
          <label className="text-sm opacity-80" htmlFor="name">
            Full Name*
          </label>
          <InputText
            id="name"
            aria-describedby="name"
            value={formik.values.reflectorAlias}
            onChange={(e) => {
              formik.setFieldValue("reflectorAlias", e.target.value);
            }}
          />
          {getFormErrorMessage("reflectorAlias")}
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="email" className="text-sm opacity-80">
            Relation to you
          </label>
          <Dropdown
            value={formik.values.relationshipType}
            onChange={setRelationshipType}
            options={types}
            optionLabel="name"
            optionValue="id"
            placeholder="Choose one"
          />
          {getFormErrorMessage("relationshipType")}
        </div>
      </div>
      <div className="flex flex-column gap-3">
        <p className="text-sm opacity-80">
          How often do you work with this person? SELECT ONE*
        </p>
        <div className="flex align-items-center gap-5 text-500">
          <div
            onClick={() => handleSelection(RelationshipProximity.Often)}
            className={`${formik.values.relationshipProximity ===
              RelationshipProximity.Often
              ? "text-primary"
              : ""
              }  cursor-pointer flex flex-column align-items-center relationshipContainer`}
          >
            <div className="flex ">
              <PersonIcon fontSize="inherit" />
              <PersonIcon fontSize="inherit" />
            </div>
            <span className="text-sm">Often</span>
          </div>
          <div
            onClick={() => handleSelection(RelationshipProximity.Occasionally)}
            className={`${formik.values.relationshipProximity ===
              RelationshipProximity.Occasionally
              ? "text-primary"
              : ""
              }  cursor-pointer flex flex-column align-items-center relationshipContainer`}
          >
            <div className="flex ">
              <PersonIcon fontSize="inherit" />
              <MoreHorizIcon fontSize="inherit" />
              <PersonIcon fontSize="inherit" />
            </div>
            <span className="text-sm">Occasionally</span>
          </div>
          <div
            onClick={() => handleSelection(RelationshipProximity.Sporadically)}
            className={`${formik.values.relationshipProximity ===
              RelationshipProximity.Sporadically
              ? "text-primary"
              : ""
              }  cursor-pointer flex flex-column align-items-center relationshipContainer`}
          >
            <div className="flex ">
              <PersonIcon fontSize="inherit" />
              <span className="flex p-0">
                <MoreHorizIcon fontSize="inherit" className="rightDots" />
                <MoreHorizIcon fontSize="inherit" />
              </span>
              <PersonIcon fontSize="inherit" />
            </div>
            <span className="text-sm">Sporadically</span>
          </div>
        </div>
        {getFormErrorMessage("relationshipProximity")}
      </div>
      <div className="flex flex-column gap-2">
        <Button label="Confirm Changes" type="submit" loading={loading}></Button>
        <Button
          className="text-red-500 "
          outlined
          label="Delete Reflector"
          type="button"
          onClick={() => { setDeleteDialogVisible(true) }}
          loading={loading}
        ></Button>
        <Dialog
          closable={true}
          headerStyle={{ padding: "0" }}
          visible={deleteDialogVisible}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setDeleteDialogVisible(false)}
        >
          <div className="flex flex-column gap-3 text-center align-items-center md:p-5 pb-2 ">
            <p className="font-bold text-2xl">Are you sure you want to delete this reflector?</p>
            <div className="w-full flex justify-content-center">
              <Button label="Delete" className="bg-red-600 "  onClick={deleteRelationship}></Button>
            </div>
          </div>
        </Dialog>
      </div>
    </form>
  );
}

export default EditRelationshipForm;
