import { createContext, useReducer } from "react";
import {
  relationshipCreateEndpoint,
  relationshipDeleteEndpoint,
  relationshipGetEndpoint,
  relationshipUpdateEndpoint,
} from "../../service/api-temp";
import { AppHttp } from "../../service/app-http";
import { getNameInitialsFromName } from "../../service/functions/user-name-functions";

const relationships = [];

export const RelationshipsState = createContext(relationships);

export const RelationshipsStore = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "set":
        if (action && action.payload && Array.isArray(action.payload)) {
          action.payload = action.payload.map((u) => ({
            ...u,
            initials: getNameInitialsFromName(u.reflectorAlias),
          }));
        }

        return [...action.payload];

      case "add":
        return [
          {
            ...action.payload,
            initials: getNameInitialsFromName(action.payload.reflectorAlias),
          },
          ...state,
        ];

      case "update":
        return state.map((r) => {
          if (r.id === action.payload.id) {
            action.payload.initials = getNameInitialsFromName(
              action.payload.reflectorAlias
            );
            return action.payload;
          }

          return r;
        });

      case "delete":
        return [...state.filter((r) => r.id !== action.payload.id)];

      default:
        return state;
    }
  }, relationships);

  return (
    <RelationshipsState.Provider value={[state, dispatch]}>
      {children}
    </RelationshipsState.Provider>
  );
};

export async function loadRelationships() {
  return await AppHttp.get(relationshipGetEndpoint).then((response) => {
    if (response.success) {
      return response.data;
    }

    throw response;
  });
}

export async function createRelationship(relationship) {
  return await AppHttp.post(relationshipCreateEndpoint, relationship).then(
    (response) => {
      if (response.success) {
        return response.data;
      }

      return Promise.reject(response.error);
    }
  );
}

export async function updateRelationship(relationship) {
  return await AppHttp.put(relationshipUpdateEndpoint, relationship).then(
    (response) => {
      if (response.success) {
        return response.data;
      }

      throw new Error(response.error);
    }
  );
}

export async function deleteRelationship(id) {
  return await AppHttp.delete(relationshipDeleteEndpoint + "/" + id).then(
    (response) => {
      return response.success;
    }
  );
}
