import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from "primereact/tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AppHttp } from "../../service/app-http";
import { getSelfAwarenessReportProgressEndpoint } from "../../service/api-temp";
import { useScreenDetector } from "../../hooks/useScreenDetector";
import { Button } from "primereact/button";
import { UserDataState } from "../store_providers/user-data-store";

function MirrorImageProgress({ token }) {
  const [userData, dispatch] = useContext(UserDataState);
  const [data, setData] = useState(null);
  const [fetchedFromApi, setFetchedFromApi] = useState(false);
  const { isMobile } = useScreenDetector();

  useEffect(() => {
    function loadProgress() {
      AppHttp.get(getSelfAwarenessReportProgressEndpoint, {
        token: token,
      }).then((result) => {
        if (result.success) {
          setData(result.data);
          dispatch({ type: 'update', payload: { mirrorImageProgress: result.data.percent } })
        }

        setFetchedFromApi(true);
      });
    }

    if (!fetchedFromApi) {
      loadProgress();
    }
  }, [fetchedFromApi, token]);

  return (
    <div className="flex flex-column pb-7 gap-3 w-full max-w-md">
      <div className="flex flex-column gap-2">
        <div className="flex justify-content-between align-items-center">
          <div className="flex align-items-center font-bold flex gap-2 text-sm text-opacity-70">
            {data && data.percent === 100
              ? "Check your latest Mirror Image"
              : "Your Mirror Image is getting generated!"}
            {!isMobile && (<HelpOutlineIcon style={{fontSize:'16px'}} className="tooltipHelpMirrorImage flex flex-row gap-2" />)}
          </div>
          
          {data && data.percent === 100 && (
            <Link to="/dashboard/self-awareness-report" >
              <Button label="See it now" />
            </Link>
          )}

          <Tooltip
            position="top"
            target=".tooltipHelpMirrorImage"
            autoHide={true}
            style={{ width: "100%", maxWidth: "20rem" }}
          >
            <p className="p-2 text-xs text-left">
              A self-awareness report is generated once a minimum of 5 unique reflections have been submitted by your reflectors and you’ve completed your weekly self-reflection.
            </p>
          </Tooltip>
        </div>
        <ProgressBar
          value={data?.percent === 0 ? 15 : (data?.percent ?? 5)}
          className="w-full"
        ></ProgressBar>
      </div>
      {!isMobile && (
        <>
          <Link
            className="text-700 no-underline text-opacity-70"
            to="https://www.mymirror360.com/app-tours/self-awareness-report"
            target="_blank"
          >
            <div className="tooltipHelp flex flex-row align-items-center gap-2 text-xs">
              <HelpOutlineIcon />
              <p className="opacity-80">What will it look like?</p>
            </div>
          </Link>

          <Tooltip
            target=".tooltipHelp"
            autoHide={true}
            style={{ width: "100%", maxWidth: "20rem" }}
          >
            <p className="p-2 text-xs text-center">
              Click to see sample self-awareness report
            </p>
          </Tooltip>
        </>
      )}
    </div>
  );
}

export default MirrorImageProgress;
