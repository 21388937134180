import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

function Reflectors7Dialog() {
    return (
        <div className="flex flex-column my-5">
            <h1 className="text-2xl font-bold ">YOU'VE MADE A BIG STEP...</h1>
            <small>
                You can now improve on your mirror setup, or check out what's coming
                your way.
            </small>

            <div className="flex flex-column md:flex-row align-items-center justify-content-center gap-3 mt-3">
                <Link
                    to="https://www.mymirror360.com/app-tours/self-awareness-report"
                    target="_blank"
                >
                    <Button className="text-primary px-5" outlined>
                        Sample Insights
                    </Button>
                </Link>
                <Link to="/dashboard/reflectors-overview">
                    <Button className=' px-3'>Improve Your Mirror</Button>
                </Link>
            </div>
        </div>
    )
}

export default Reflectors7Dialog