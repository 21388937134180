import React from "react";
import StarIcon from '@mui/icons-material/Star';

function Contributors({ bestCategory, fulfillmentCategories }) {
  return (
    <section
      id="Contributors"
      className="flex flex-column gap-5 mt-5 mx-auto self-awareness_scroll-section"
    >
      <div className="text-center flex flex-column gap-2 max-w-xl mx-auto">
        <h2 className="md:text-2xl text-xl">Contributors and Detractors</h2>
        <p className="text-xs opacity-80">
          Based on your self-reflections, the areas of your job are arranged
          from those that contribute to your sense of fulfillment the most, to
          those that detract from it the most.
        </p>
      </div>
      <div className="motivators-grid_wrapper">
        <div className="motivators-grid_component">
          {bestCategory && (
            <div className="p-3 pt-7 flex flex-column gap-2 bg-white relative motivators-grid_motivator motivators-grid_primary-list-item">
              <div className={
                "flex pl-4 align-items-center h-3rem gap-2 h-auto motivators-grid_score motivators-grid_score-" +
                bestCategory.averageScore
              }>
                <StarIcon className="text-white text-md"  />
                <p className="font-bold text-white">Most fulfilling for you</p>
              </div>

              <h3 className="text-sm">{bestCategory.categoryName}</h3>
              <p className="text-xs opacity-80">
                {bestCategory.categoryDescription}
              </p>
            </div>
          )}
          <ul className="p-0 motivators-grid_list text-xs">
            {fulfillmentCategories &&
              fulfillmentCategories.map((fc, index) => (
                <li key={index} className="p-3 pt-4 flex flex-column gap-2 bg-white relative motivators-grid_list-item">
                  <div
                    className={
                      "motivators-grid_score motivators-grid_score-" +
                      fc.averageScore
                    }
                  />
                  <h3 className="text-sm">{fc.categoryName}</h3>
                  <p className="text-xs opacity-80">{fc.categoryDescription}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Contributors;
