import { Button } from "primereact/button";
import React from "react";
import { Link } from "react-router-dom";
import backgroundImage from "../../assets/congrats_bg-img.png";

function Congrats(props) {
  return (
    <div
      className="bgCongrats w-full h-full absolute flex flex-column gap-5 align-items-center justify-content-center text-center z-1 bg-cover bg-no-repeat bg-top p-3 md:p-5"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-column align-items-center">
        <h1 className="font-bold text-2xl sm:text-6xl md:text-7xl">
          CONGRATULATIONS
        </h1>
        <p className="text-sm opacity-60">
          You may now use your Mirror 360 account, free of charge!
        </p>
      </div>
      <Link
        to="/dashboard/reflectors-overview"
        className="no-underline text-white"
      >
        <Button className='px-4 py-3' label="Set up your Mirror 360"></Button>
      </Link>
    </div>
  );
}

export default Congrats;
