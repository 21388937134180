/**
 *
 * @param {string} name
 * @returns
 */
export function getNameInitialsFromName(name) {
  if (!name) {
    return name;
  }

  var parts = name.split(" ");

  if (parts && parts.length > 1) {
    const firstName = parts[0];
    const lastName = parts[1];

    return firstName.substring(0, 1) + lastName.substring(0, 1);
  }

  if (name.length > 2) {
    return name.substring(0, 2).toUpperCase();
  }

  return name;
}

/**
 *
 * @param {string} name
 * @returns
 */
export function getFirstNameFromName(name) {
  if (!name) {
    return name;
  }

  var parts = name.split(" ");

  if (parts && parts.length > 1) {
    const firstName = parts[0];

    return firstName;
  }

  return name;
}
