import React from 'react'
import imgNotFound from '../assets/page-not-found.svg'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'

function NotFoundPage() {
    return (
        <div className='w-full h-full flex bg-black-alpha-90 align-items-center pl-5 '>
            <div className="gap-4 text-white flex flex-column">
                <img src={imgNotFound} alt="Not Found Icon" className="w-2rem invert" />
                <div>
                    <h3>Sorry, nothing insightful here...</h3>
                    <p>The page you are looking for doesn't exist or has been moved.</p>
                </div>
                <Link to='/home'><Button label='Go Home' severity="secondary" /></Link>
            </div>
        </div>
    )
}

export default NotFoundPage