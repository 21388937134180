import { InputText } from "primereact/inputtext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import { Password } from "primereact/password";
import EmailIcon from "@mui/icons-material/Email";
import { Button } from "primereact/button";
import google from "../../assets/google.png";
import linkedin from "../../assets/linked-in.png";
import { useFormik } from "formik";
import classNames from "classnames";
import { setAuthDataObj } from "../../service/UserAuthData";
import { registerUrl, registerWithGoogleUrl, registerWithLinkedInUrl } from "../../service/api-temp";
import { useLocation, useNavigate } from "react-router-dom";
import { signupPageSchema } from "../../service/validationschemas/validation-schemas";
import { useContext, useRef, useState } from "react";
import { UserDataState } from "../store_providers/user-data-store";
import { useGoogleLogin } from "@react-oauth/google";
import { AppHttp } from "../../service/app-http";
import { useLinkedIn } from "react-linkedin-login-oauth2";

function SignupForm() {
  const navigate = useNavigate();
  const reactLocation = useLocation();
  const [userData, dispatch] = useContext(UserDataState);
  const [loading, setLoading] = useState(false);
  const [socialLoginError, setSocialLoginError] = useState(null);

  const preventMultipleRegisterSubmits = useRef(false);

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => registerWithGoogle(tokenResponse),
    flow: "auth-code",
  });

  const { linkedInLogin } = useLinkedIn({
    clientId: "77y30r1toybqpz",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile email",
    onSuccess: (code) => {
      if (preventMultipleRegisterSubmits.current) {
        return;
      }

      preventMultipleRegisterSubmits.current = true;

      setLoading(true);
      setSocialLoginError(null);

      AppHttp.post(registerWithLinkedInUrl, {
        code: reactLocation.state?.invitationCode,
        token: code,
      }).then((response) => {
        preventMultipleRegisterSubmits.current = false;

        if (response.success) {
          setAuthDataObj({
            token: response.data.token,
            expires: response.data.expires,
          });

          delete response.data.token;
          delete response.data.expires;

          dispatch({ type: "set", payload: response.data });

          navigate("/home");
        } else {
          setSocialLoginError(response.error.message);
          setLoading(false);
        }
      });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  function signUpWithGoogle() {
    googleLogin();
  }

  function registerWithGoogle(tokenResponse) {
    setLoading(true);
    setSocialLoginError(null);

    AppHttp.post(registerWithGoogleUrl, {
      code: reactLocation.state?.invitationCode,
      token: tokenResponse.code,
    }).then((response) => {
      if (response.success) {
        setAuthDataObj({
          token: response.data.token,
          expires: response.data.expires,
        });

        delete response.data.token;
        delete response.data.expires;

        dispatch({ type: "set", payload: response.data });

        navigate("/home");
      } else {
        setSocialLoginError(response.error.message);
        setLoading(false);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      inviteCode: reactLocation.state?.invitationCode,
      email: "",
      password: "",
      name: "",
    },
    validationSchema: signupPageSchema,
    onSubmit: (values) => {
      setLoading(true);
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      fetch(registerUrl, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(values),
      })
        .then((data) => data.json())
        .then((response) => {
          if (response.success) {
            setAuthDataObj({
              token: response.data.token,
              expires: response.data.expires,
            });

            delete response.data.token;
            delete response.data.expires;

            dispatch({ type: "set", payload: response.data });

            navigate("/auth/confirm-invite");
          } else {
            setLoading(false);
            formik.setFieldError("email", response.error.message);
          }
        })
        .catch((err) => console.log(err));
    },
  });

  const isFormFieldInvalid = (msg) =>
    !!(formik.touched[msg] && formik.errors[msg]);

  const getFormErrorMessage = (msg) => {
    return isFormFieldInvalid(msg) ? (
      <small className={`${formik.errors[msg] ? "block" : "hidden"} p-error`}>
        {formik.errors[msg]}
      </small>
    ) : (
      ""
    );
  };

  return (
    <div className="max-w-sm flex flex-column gap-2">
      <h1 className="text-center text-2xl mb-5">Let's get you in!</h1>
      <Button
        label="Sign up with Google"
        className="flex-none flex-row-reverse bg-white text-700 border-gray-500 border-1"
        onClick={signUpWithGoogle}
      >
        <img alt="google" className="w-1rem" src={google} />
      </Button>
      <Button
        label="Sign up with LinkedIn"
        className="flex flex-row-reverse bg-white text-700 border-gray-500 border-1"
        onClick={linkedInLogin}
      >
        <img alt="linked in" className="w-1rem " src={linkedin} />
      </Button>

      <div className="flex flex-row align-items-center justify-content-between my-3">
        <div className="line"></div>
        <p className="px-3 text-xs opacity-80">OR</p>
        <div className="line"></div>
      </div>
      {socialLoginError && <div className="p-error">{socialLoginError}</div>}

      <form
        className="flex flex-column gap-3 p-input-filled"
        onSubmit={formik.handleSubmit}
      >
        <div className="flex flex-column gap-2">
          <label htmlFor="Name" className="text-sm opacity-80">
            Name*
          </label>
          <span className="p-input-icon-left">
            <AccountCircleIcon fontSize="inherit" />
            <InputText
              className={classNames({
                "p-invalid": !!formik.errors.name && !!formik.touched.name,
              })}
              placeholder="Your full name"
              type="text"
              aria-label="name"
              name="name"
              value={formik.values.name}
              onChange={(e) => {
                formik.setFieldValue("name", e.target.value);
              }}
            />
          </span>
          {getFormErrorMessage("name")}
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="Email" className="text-sm opacity-80">
            Email*
          </label>
          <span className="p-input-icon-left">
            <EmailIcon fontSize="inherit" />
            <InputText
              className={classNames({
                "p-invalid": !!formik.errors.email && !!formik.touched.email,
              })}
              placeholder="name@company.com"
              type="email"
              aria-label="email"
              name="email"
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
              }}
            />
          </span>
          {getFormErrorMessage("email")}
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="Password" className="text-sm opacity-80">
            Password*
          </label>
          <span className="flex align-items-center p-input-icon-left">
            <Password
              className={classNames({
                "p-invalid":
                  !!formik.errors.password && !!formik.touched.password,
              })}
              placeholder="********"
              type="password"
              aria-label="password field"
              name="password"
              value={formik.values.password}
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value);
              }}
              hideIcon={<VisibilityOffIcon fontSize="inherit" />}
              showIcon={<VisibilityIcon fontSize="inherit" />}
              toggleMask
              promptLabel="Create password"
              weakLabel="Too simple"
              mediumLabel="Average complexity"
              strongLabel="Complex password"
              inputStyle={{ paddingLeft: "40px" }}
            />
            <LockIcon fontSize="inherit" />
          </span>
          {getFormErrorMessage("password")}
        </div>
        <Button
          loading={loading}
          type="submit"
          className="mt-3"
          label="Proceed"
        />
      </form>
    </div>
  );
}

export default SignupForm;
