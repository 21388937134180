import SignupLayout from "./componets/signup/SignupLayout";
import "primereact/resources/primereact.min.css";
import "./360theme.css";
import "primeflex/primeflex.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";
import LoginLayout from "./componets/login/LoginLayout";
import InviteCode from "./componets/InviteCode";
import Almost from "./componets/login/Almost";
import Reflection from "./componets/survey/Reflection";
import SelfReflection from "./componets/survey/SelfReflection";
import AddReflectors from "./componets/addReflectors/AddReflectors";
import Overlay from "./componets/addReflectors/Overlay";
import SettingsOverlay from "./componets/settings/SettingsOverlay";
import Profile from "./componets/settings/profile_components/Profile";
import PersonalInfo from "./componets/settings/PersonalInfo";
import PasswordUpdate from "./componets/settings/PasswordUpdate";
import Completed from "./componets/survey/Completed";
import ThankYou from "./componets/survey/ThankYou";
import ThankYouSelf from "./componets/survey/ThankYouSelf";
import Dashboard from "./componets/addReflectors/Dashboard";
import Congrats from "./componets/signup/Congrats";
import ImageLayout from "./componets/mirrorImage/ImageLayout";
import CompletedSelf from "./componets/survey/CompletedSelf";
import EmailSentLayout from "./componets/findAcc/EmailSentLayout";
import AutoLogin from "./componets/login/AutoLogin";
import Sorry from "./componets/survey/Sorry";
import FindAcc from "./componets/findAcc/FindAcc";
import NewPassword from "./componets/findAcc/NewPassword";
import Allset from "./componets/findAcc/Allset";
import EmailSent from "./componets/findAcc/EmailSent";
import ConfirmEmail from "./componets/signup/ConfirmEmail";
import { RelationshipsStore } from "./componets/store_providers/relationships-store";
import ThankYouForLettingUsKnow from "./componets/addReflectors/ThankYouForLettingUsKnow";
import { getToken, isTokenExpired } from "./service/UserAuthData";
import {
  UserDataState,
  UserDataStore,
} from "./componets/store_providers/user-data-store";
import { useContext } from "react";
import ConfirmOtherEmail from "./componets/settings/ConfirmOtherEmail";
import NotFoundPage from "./componets/NotFoundPage";
import Unsubscribe from "./componets/Unsubscribe";
import { useScreenDetector } from "./hooks/useScreenDetector";
import Maintenance from "./componets/Maintenance";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

const ProtectedRouteToken = ({ children }) => {
  if (!getToken() || isTokenExpired()) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};

const ProtectedRouteEmailConfirmed = ({ children }) => {
  const [userData, dispatch] = useContext(UserDataState);

  if (!userData.emailConfirmed) {
    return <Navigate to="/auth/confirm-invite" replace />;
  }
  return children;
};

const ProtectedRoutePassedTutorial = ({ children }) => {
  const { isMobile } = useScreenDetector();
  const [userData, dispatch] = useContext(UserDataState);

  if (!isMobile && !userData.hasPassedAddReflectorsTutorial) {
    return <Navigate to="/auth/sign-up-success/assitance" replace />;
  }
  return children;
};

const UnauthorizedProtection = ({ children }) => {
  if (!getToken() || isTokenExpired()) {
    return children;
  }

  return <Navigate to="/dashboard/reflectors-overview" replace />;
};

const router = createBrowserRouter([
  {
    element: <LinkedInCallback />,
    path: "/linkedin"
  },
  {
    element: (
      <UnauthorizedProtection>
        <Outlet />
      </UnauthorizedProtection>
    ),
    children: [
      {
        path: "/",
        element: <LoginLayout />,
      },
      {
        path: "/auth/sign-up",
        element: <SignupLayout />,
      },
      {
        path: "/auth/login",
        element: <LoginLayout />,
      },
      {
        path: "/auth/redeem-invite",
        element: <InviteCode />,
      },
      {
        path: "/auth/password-recovery",
        element: <EmailSentLayout />,
        children: [
          {
            path: "/auth/password-recovery/find-account",
            element: <FindAcc />,
          },
          {
            path: "/auth/password-recovery/recovery-email-success",
            element: <EmailSent />,
          },
          {
            path: "/auth/password-recovery/new-password",
            element: <NewPassword />,
          },
          {
            path: "/auth/password-recovery/all-set",
            element: <Allset />,
          },
        ],
      },
    ],
  },
  {
    path: "/auth/confirm-email",
    element: <ConfirmEmail />,
  },
  {
    element: (
      <ProtectedRouteToken>
        <Outlet />
      </ProtectedRouteToken>
    ),
    children: [
      {
        path: "/auth/confirm-invite",
        element: <Almost />,
      },
    ],
  },
  {
    element: (
      <ProtectedRouteToken>
        <ProtectedRouteEmailConfirmed>
          <Outlet />
        </ProtectedRouteEmailConfirmed>
      </ProtectedRouteToken>
    ),
    children: [
      {
        path: "/auth/sign-up-success/assitance",
        element: <Overlay />,
      },
      {
        path: "/auth/sign-up-success",
        element: <Congrats />,
      },
    ],
  },
  {
    element: (
      <ProtectedRouteToken>
        <ProtectedRouteEmailConfirmed>
          <ProtectedRoutePassedTutorial>
            <Outlet />
          </ProtectedRoutePassedTutorial>
        </ProtectedRouteEmailConfirmed>
      </ProtectedRouteToken>
    ),
    children: [
      {
        path: "/home",
        element: <Dashboard />,
      },
      {
        path: "/confirm-email-alias",
        element: <ConfirmOtherEmail />,
      },
      {
        path: "/dashboard/reflectors-setup",
        element: <AddReflectors />,
      },
      {
        path: "/dashboard/reflectors-overview",
        element: <Dashboard />,
      },
      {
        path: "/dashboard/self-awareness-report",
        element: <ImageLayout />,
      },
      {
        path: "/dashboard/settings",
        element: <SettingsOverlay />,
        children: [
          {
            path: "/dashboard/settings/profile-info",
            element: <Profile />,
          },
          {
            path: "/dashboard/settings/personal-info",
            element: <PersonalInfo />,
          },
          {
            path: "/dashboard/settings/password-change",
            element: <PasswordUpdate />,
          },
        ],
      },
    ],
  },
  {
    path: "/thank-you-for-letting-us-know/",
    element: <ThankYouForLettingUsKnow />,
  },
  {
    path: "/self-reflection",
    element: <SelfReflection />,
  },
  {
    path: "/completedSelf",
    element: <CompletedSelf />,
  },
  {
    path: "/thankyouself",
    element: <ThankYouSelf />,
  },
  {
    path: "/360-reflection/",
    element: <Reflection />,
  },
  {
    path: "/sorry",
    element: <Sorry />,
  },
  {
    path: "/thankyou",
    element: <ThankYou />,
  },
  {
    path: "/completed",
    element: <Completed />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/unsubscribe",
    element: <Unsubscribe />,
  },
  {
    path: "/maintenance",
    element: <Maintenance />,
  },
]);

function App() {
  return (
    <GoogleOAuthProvider clientId="404142812399-6imhuqg5864gepjdjihuhg5vsjuoo9u3.apps.googleusercontent.com">
      <UserDataStore>
        <AutoLogin>
          <RelationshipsStore>
            <RouterProvider router={router} />
          </RelationshipsStore>
        </AutoLogin>
      </UserDataStore>
    </GoogleOAuthProvider>
  );
}

export default App;
