import { getToken } from "./UserAuthData";

export const AppHttp = {
  get: async function (url, queryParams = null) {
    let requestUrl = checkParams(url, queryParams);

    return await this.request(requestUrl, {
      method: "GET",
    });
  },
  post: async function (url, body = null, queryParams = null) {
    let requestUrl = checkParams(url, queryParams);

    return await this.request(requestUrl, {
      method: "POST",
      body: body ? JSON.stringify(body) : "",
    });
  },
  put: async function (url, body = null, queryParams = null) {
    let requestUrl = checkParams(url, queryParams);

    return await this.request(requestUrl, {
      method: "PUT",
      body: body ? JSON.stringify(body) : "",
    });
  },
  delete: async function (url, queryParams = null) {
    let requestUrl = checkParams(url, queryParams);

    return await this.request(requestUrl, {
      method: "DELETE",
    });
  },
  request: async function (url, fetchConfiguration = null) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    if (getToken()) {
      headers.append("Authorization", "Bearer " + getToken());
    }

    return await fetch(url, {
      headers: headers,
      ...fetchConfiguration,
    }).then((response) => response.json());
  },
};

function checkParams(url, queryParams) {
  let requestUrl = url;
  if (queryParams) {
    requestUrl +=
      "?" +
      new URLSearchParams({
        ...queryParams,
      });
  }

  return requestUrl;
}
