import React from "react";
import LoginForm from "./LoginForm";
import LoginRightSide from "./LoginRightSide";
import Logo from "../Logo";
import { Link } from "react-router-dom";
import AuthLayout from "../Auth/AuthLayout";
import AuthWrapper from "../Auth/AuthWrapper";

function LoginLayout() {
  return (
    <>
      <AuthLayout>
        <AuthWrapper>
          <Link to="https://www.mymirror360.com/" target="_blank">
            <Logo className={"flex align-center"} />
          </Link>
          <LoginForm />
          <p className="text-center text-xs">
            Don't have an account yet?{" "}
            <Link className="no-underline" to="/auth/redeem-invite">
              <strong> Sign Up</strong>
            </Link>
          </p>
        </AuthWrapper>
        <LoginRightSide />
      </AuthLayout>
    </>
  );
}

export default LoginLayout;
