import React from 'react';

const ReflectionContainer = ({ children }) => {
    return (
        <div className="reflection_container relative w-full flex flex-column text-center justify-content-center align-items-center mt-auto mb-auto">
            {children}
        </div>
    );
};

export default ReflectionContainer;
