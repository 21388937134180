import React from "react";

const ReflectorsSidebar = ({ children }) => {
  return (
    <aside className="reflectors_sidebar p-input-filled flex flex-column gap-5 justify-content-between align-items-stretch pl-5 bg-white px-3 md:px-5 pt-8 pb-5">
      {children}
    </aside>
  );
};

export default ReflectorsSidebar;
