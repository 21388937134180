import { createContext, useReducer } from "react";

const userData = {};

export const UserDataState = createContext(userData);

export const UserDataStore = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "set":
        return {
          ...action.payload,
        };
      
      case "update":
        return {
          ...state,
          ...action.payload,
        };

      case "delete":
        return {};

      default:
        return state;
    }
  }, userData);

  return (
    <UserDataState.Provider value={[state, dispatch]}>
      {children}
    </UserDataState.Provider>
  );
};
