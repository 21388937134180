import React from "react";
import Logo from "../Logo";
import backgroundImage from "../../assets/auth_bg-full-img.png";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";

function EmailSentLayout() {
  return (
    <div
      className="h-full flex flex-column gap-5 align-items-center justify-content-between text-center bg-cover bg-bottom p-3 md:p-5"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Link to="https://www.mymirror360.com/" target="_blank">
        <Logo className={"flex align-center"} />
      </Link>
      <Outlet />
      <div className="w-full" />
    </div>
  );
}

export default EmailSentLayout;
