import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

function SARsidebarNav(props) {
  const [activeSection, setActiveSection] = useState("mirorImage");

  const handleLinkClick = (section) => {
    setActiveSection(section);
    scrollToSection(section);

    if (props.showHide) {
      props.showHide();
    }
  };

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    element.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop - 50 && // Adjust the offset as needed
          scrollPosition < sectionTop + sectionHeight - 50 // Adjust the offset as needed
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleUrlAnchorChange = () => {
      const sectionFromUrl = window.location.hash.substr(1);
      setActiveSection(sectionFromUrl);
    };

    window.addEventListener("hashchange", handleUrlAnchorChange);
    return () => {
      window.removeEventListener("hashchange", handleUrlAnchorChange);
    };
  }, []);

  return (
    <nav>
      <ul className="flex flex-column list-none p-0 text-sm">
        <li className="flex">
          <Link
            onClick={() => handleLinkClick("mirorImage")}
            to="/dashboard/self-awareness-report#mirorImage"
            className={
              "self-awareness_sidebar-link no-underline w-full p-2 " +
              (activeSection === "mirorImage" && "active")
            }
          >
            Mirror Image
          </Link>
        </li>
        <li className="flex">
          <Link
            onClick={() => handleLinkClick("strengthAndWeaknesses")}
            to="/dashboard/self-awareness-report#strengthAndWeaknesses"
            className={
              "self-awareness_sidebar-link no-underline w-full p-2 " +
              (activeSection === "strengthAndWeaknesses" && "active")
            }
          >
            Strenghts and Weaknesses
          </Link>
        </li>
        <li className="flex">
          <Link
            onClick={() => handleLinkClick("Feedback")}
            to="/dashboard/self-awareness-report#Feedback"
            className={
              "self-awareness_sidebar-link no-underline w-full p-2 " +
              (activeSection === "Feedback" && "active")
            }
          >
            Recent Feedback
          </Link>
        </li>
        <li className="flex">
          <Link
            onClick={() => handleLinkClick("Trends")}
            to="/dashboard/self-awareness-report#Trends"
            className={
              "self-awareness_sidebar-link no-underline w-full p-2 " +
              (activeSection === "Trends" && "active")
            }
          >
            Trend over Time
          </Link>
        </li>
        <li className="flex">
          <Link
            onClick={() => handleLinkClick("Contributors")}
            to="/dashboard/self-awareness-report#Contributors"
            className={
              "self-awareness_sidebar-link no-underline w-full p-2 " +
              (activeSection === "Contributors" && "active")
            }
          >
            Contributors and Detractors
          </Link>
        </li>
        {/* <li className="flex">
          <Link
            onClick={() => handleLinkClick("Tips")}
            to="/dashboard/self-awareness-report#Tips"
            className={
              "self-awareness_sidebar-link no-underline w-full p-2 " +
              (activeSection === "Tips" && "active")
            }
          >
            Tips to Better Yourself
          </Link>
        </li> */}
      </ul>
    </nav>
  );
}

export default SARsidebarNav;
