const auth_ls_key = "mirror360_auth";
let userAuthData = getAuthDataFromStorage();

export function setAuthDataObj(newFieldsObj) {
  userAuthData = {
    ...userAuthData,
    ...newFieldsObj,
  };

  if (userAuthData.expires) {
    userAuthData.expires = new Date(userAuthData.expires);
  }

  storeAuthData(userAuthData.rememberMe);
}

export function getAuthData() {
  return {
    ...userAuthData,
  };
}

export function logOut() {
  clearAuthData();
  window.location.reload();
}

export function clearAuthData() {
  localStorage.removeItem(auth_ls_key);
  sessionStorage.removeItem(auth_ls_key);
  getAuthDataFromStorage();
}

export function getToken() {
  return userAuthData.token;
}

export function isTokenExpired() {
  if (!userAuthData || !userAuthData.expires) {
    return true;
  }
  return userAuthData.expires.getTime() < Date.now();
}

export function getExpires() {
  return userAuthData.expires;
}

function storeAuthData(rememberMe = false) {
  localStorage.removeItem(auth_ls_key);
  localStorage.setItem(auth_ls_key, JSON.stringify(userAuthData));
}

function getAuthDataFromStorage(rememberMe = null) {
  const authDataFS = localStorage.getItem(auth_ls_key);

  if (authDataFS) {
    const authData = JSON.parse(authDataFS);

    if (authData) {
      authData.expires = new Date(authData.expires);
      return authData;
    }
  }

  return {
    token: "",
    expires: null,
  };
}
