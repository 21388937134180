import React from "react";
import headline from "../../assets/headline_login.svg";
function LoginRightSideContent() {
  return (
    <>
      <img className="flex w-16rem align-items-center" alt="For a Better You at Work" src={headline} />
    </>
  );
}

export default LoginRightSideContent;
