import { ProgressSpinner } from 'primereact/progressspinner'
import React from 'react'

const Loading = ({ visible, children }) => {
    return (
        <>
            {visible ? <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" className="w-50 h-full flex justify-content-center align-items-center" /> : children}
        </>
    )
}

export default Loading


