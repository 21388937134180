import React, { useEffect, useRef, useState } from "react";
import Footer from "../footer/Footer";
import completed from "../../assets/completed.png";
import done from "../../assets/done.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Logo from "../Logo";
import backgroundImage from "../../assets/performance_bg-img.png";
import { useLocation, useNavigate } from "react-router-dom";
import { AppHttp } from "../../service/app-http";
import { performanceReflectionGetDataForCompleted } from "../../service/api-temp";
import { getToken, isTokenExpired } from "../../service/UserAuthData";
import { Link } from "react-router-dom";

function Completed() {
  const textAreaRef = useRef(null);
  const completedStyle = {
    maxWidth: "40rem",
  };
  const reactLocation = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const isAuth = getToken() && !isTokenExpired();

  if (!reactLocation?.state?.token) {
    navigate("/dashboard/reflectors-overview");
  }

  useEffect(() => {
    async function getDataForReflection() {
      const data = await AppHttp.get(performanceReflectionGetDataForCompleted, {
        token: reactLocation.state.token,
      }).then((response) => {
        if (response.success) {
          const date = new Date(response.data.expiresAt);
          const tokenExpirationDateInMs = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
          );
          const diffInMiliseconds = tokenExpirationDateInMs - Date.now();
          const msInMinute = 60000;
          const msInHour = msInMinute * 60;
          const msInDay = msInHour * 24;

          const daysDiff = Math.round(diffInMiliseconds / msInDay);

          setData({
            ...response.data,
            daysDiff: daysDiff,
          });
        } else {
          navigate("/dashboard/reflectors-overview");
        }
      });
    }

    if (!data) {
      getDataForReflection();
    }
  });

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
  }

  function navigateToCode() {
    navigate("/auth/redeem-invite", {
      state: {
        code: data?.token,
      },
    });
  }

  return (
    <div className="min-h-screen flex flex-column align-items-center gap-5 ">
      <div
        className="absolute w-full h-full bg-cover lg:bg-left-bottom bg-center-bottom z-0"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <Link
        to="https://www.mymirror360.com/"
        target="_blank"
        className="relative mt-5 mx-auto mb-auto"
      >
        <Logo />
      </Link>
      <div className="relative w-full flex flex-column align-items-center gap-8 my-auto px-5 z-2">
        <div className="flex flex-column gap-3">
          <img
            style={completedStyle}
            className="hidden w-full xl:block relative"
            alt="Completed"
            src={completed}
          ></img>
          <img
            style={completedStyle}
            className="w-full block relative xl:hidden"
            alt="Done"
            src={done}
          ></img>
          <div className="text-center text-sm">
            You have <span className="font-bold">already submitted</span> your
            reflection on {data?.name}!
          </div>
        </div>
        <div className="flex flex-column gap-5">
          {data?.daysDiff > 0 && !isAuth && (
            <div
              style={{ boxShadow: "0 0 8px #fccc55" }}
              className="bg-white border-solid border-round-xl p-5 border-yellow-400 border-none flex flex-column gap-4 max-w-lg"
            >
              <div className="font-semibold text-left text-lg">
                A BIG THANK YOU!
              </div>
              <div className="md:w-11 text-left text-sm opacity-80">
                Here is your Personal Invitation Code to setup your own 360°
                Mirror for free!
              </div>
              <div className="flex flex-column gap-2">
                <div className="flex flex-row gap-2 align-items-stretch flex-wrap">
                  <div className="flex flex-row gap-2 align-items-center">
                    <ContentCopyIcon onClick={copyToClipboard} />
                    <div className="flex flex-column justify-content-start align-items-baseline gap-2">
                      <InputText
                        className="p-inputtext-md"
                        defaultValue={data?.token}
                      />
                    </div>
                  </div>
                  <Button
                    className="flex-grow-1"
                    label="Activate Now"
                    onClick={navigateToCode}
                  ></Button>
                </div>
                <div className="font-bold text-xs lg:ml-5 ml-auto mr-auto">
                  Expires in:
                  <span className="text-green-600">
                    {" "}
                    {data?.daysDiff} days{" "}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="max-w-lg flex align-items-center justify-content-between flex-wrap gap-3">
            <div className="w-8 text-justify md:text-left text-xs opacity-80">
              My Mirror 360 is an innovative feedback tool that empowers you to
              drive your own growth.
            </div>
            <Link to="https://www.mymirror360.com/" target="_blank">
              <Button label="Learn More" outlined></Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer className="w-full z-2"></Footer>
    </div>
  );
}

export default Completed;
