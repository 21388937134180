export const RelationshipType = {
    None: 0,
    Peer: 1,
    Junior: 2,
    Client: 3,
    Senior: 4
}

export const RelationshipProximity = {
    None: 0,
    Often: 1,
    Occasionally: 2,
    Sporadically: 3,
}