import React from 'react';

const ReflectorsContainer = ({ children, variant }) => {
    const className = variant === 'setup' ? "reflectors_container setup" : "reflectors_container";
    
    return (
        <main className={`${className} w-full`}>
            {children}
        </main>
    );
};

export default ReflectorsContainer;
