import React, { useEffect, useState } from "react";
import Logo from "../Logo";
import LoginRightSide from "./LoginRightSide";
import { Button } from "primereact/button";
import { getMyEmailUrl, resendConfirmEmailUrl } from "../../service/api-temp";
import { getToken } from "../../service/UserAuthData";
import AuthLayout from "../Auth/AuthLayout";
import AuthWrapper from "../Auth/AuthWrapper";
import { Link } from "react-router-dom";
import { AppHttp } from "../../service/app-http";

function Almost() {
  const [email, setEmail] = useState();
  const [emailSent, setEmailSent] = useState();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getMyEmail() {
      AppHttp.get(getMyEmailUrl).then((response) => {
        if (response.success) {
          setEmail(response.data);
        }
      });
    }

    getMyEmail();
  }, []);

  function resendEmail() {
    setLoading(true);
    setEmailSent("");

    AppHttp.get(resendConfirmEmailUrl).then((response) => {
      if (response.success) {
        setLoading(false);
        setEmailSent("Confirmation email resent!");
      } else {
        setEmailSent("There was an error resending your email!");
      }
    });
  }

  return (
    <AuthLayout>
      <AuthWrapper>
        <Link to="https://www.mymirror360.com/" target="_blank">
          <Logo className={"flex align-center"} />
        </Link>
        <div className="max-w-sm justify-content-center flex flex-column gap-5 text-center">
          <h1 className="text-2xl">Almost there...</h1>
          <p className="text-sm opacity-80">
            We sent an email to {email} to confirm your identity. Check your
            inbox
          </p>
          <Button
            className="r-button"
            label="Resend Email"
            onClick={resendEmail}
            loading={loading}
          ></Button>
          <p className="text-red-600 text-sm">{emailSent}</p>
        </div>
        <div className="w-full" />
      </AuthWrapper>
      <LoginRightSide />
    </AuthLayout>
  );
}

export default Almost;
