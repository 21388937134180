import * as d3 from "d3";
import {scoreToColor, scoreToLabel} from "../helpers/chartHelpers";

class RadialChart {
  constructor(chartGroup, outerRadius) {

    this.chartGroup = chartGroup;
    this.padRadius = outerRadius;

    this.ybis = d3.scaleRadial()
      .range([outerRadius, 0])
      .domain([0, 200]);
    this.prepareDefs();
  }

  prepareDefs() {
    this.chartGroup.append('defs')
      .append('filter')
      .attr('id', 'blur')
      .attr('width', '190%')
      .attr('x', '-10%')
      .append('feGaussianBlur')
      .attr('stdDeviation', "0.5 1.0");
  }

  addGradient(defs, data) {
    const gradientID = `gradient-${data.id}`;
    const gradient = defs.append('linearGradient')
      .attr('id', gradientID)
      .attr('x1', '100')
      .attr('y1', this.ybis(0))
      .attr('x2', '200')
      .attr('y2', this.ybis(1100));

    // Gradient stop values can be extracted to a configuration for reusability
    const stops = [
      {offset: '0%', opacity: '1.0'},
      {offset: '60%', opacity: '0.7'},
      {offset: '80%', opacity: '0.6'},
      {offset: '100%', opacity: '0.10'}
    ];

    stops.forEach(stop => {
      gradient.append('stop')
        .attr('offset', stop.offset)
        .attr('stop-opacity', stop.opacity)
        .attr('stop-color', scoreToColor(data.score));
    });

    return gradientID;
  }

  draw(data) {
    const defs = this.chartGroup.append('defs');
    this.chartGroup.append("g")
      .selectAll("path")
      .data(data)
      .join("path")
      //.attr("fill", d => d.isSpacer ? "none" : `url(#${this.addGradient(defs, d)})`)
      .attr("fill", d => d.isSpacer ? "none" : scoreToColor(d.score))
      .attr('filter', d => d.isSpacer ? 'none' : 'url(#blur)')
      .attr(
        "d",
        d3.arc()
          .innerRadius(this.ybis(0))
          .outerRadius(d => d.isSpacer ? this.ybis(140) : this.ybis(d.recency))
          .startAngle(d => d.startAngle)
          .endAngle(d => d.endAngle)
          .padAngle(0)
          .padRadius(this.padRadius)
      )
      .append("title")
      .text(d => d.isSpacer ? '' : scoreToLabel(d.score));
  }
}

/**
 * Prepares radial chart data.
 *
 * @param {Array} data - The input data arrays.
 * @param {Array} arcAngles - The angles for each data set.
 * @param {number} offsetAngle - The initial angle offset.
 * @param {number} spacerRadians - The size of spacer in radians.
 * @returns {Array} The prepared radial chart data.
 */
const prepareRadialChartData = (data, arcAngles, offsetAngle, spacerRadians) => {
  let idCounter = 0;
  let currentStartAngle = offsetAngle;
  const radialChartData = [];

  // Helper function to check if there is only one slice
  const isSingleSlice = data =>
    Object.values(data.categories).filter(innerArray => innerArray.length > 0).length === 1;

  if (isSingleSlice(data)) {
    spacerRadians = 0.0;
  }

  // Helper function to add spacer
  const addSpacer = (startAngle) => {
    radialChartData.push({
      id: idCounter++,
      isSpacer: true,
      startAngle,
      endAngle: startAngle + spacerRadians
    });
    return startAngle + spacerRadians;
  };

  // Helper function to calculate sum of credence values in array
  const sumCredence = (array) => array.reduce((a, b) => a + b.credence, 0);

  Object.values(data.categories).forEach((innerArray, index) => {
    if(innerArray.length === 0) return;
    const totalCredence = sumCredence(innerArray);
    const availableAngle = arcAngles[index] - (spacerRadians * 2);

    // Add a spacer before each data set
    currentStartAngle = addSpacer(currentStartAngle);

    innerArray.forEach(element => {
      const elementAngle = (element.credence / totalCredence) * availableAngle;
      radialChartData.push({
        ...element,
        id: idCounter++,
        recency: 200 - element.recency,
        startAngle: currentStartAngle,
        endAngle: currentStartAngle + elementAngle
      });
      currentStartAngle += elementAngle;
    });

    // Add a spacer after each data set
    currentStartAngle = addSpacer(currentStartAngle);
  });

  return radialChartData;
};



export const drawRadialChart = (chartGroup, inputData, offsetAngle, arcAngles, dimensions) => {
  const spacerRadians = 3 * Math.PI / 180;
  const radius = Math.min(dimensions.width, dimensions.height) / 2 * 0.8;
  const data = prepareRadialChartData(inputData, arcAngles, offsetAngle, spacerRadians);
  const radialChart = new RadialChart(chartGroup, radius);
  radialChart.draw(data);
};