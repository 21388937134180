import React from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'

function YouCircle({count,maxValue}) {
    return (
        <div className="w-5rem md:w-8rem relative z-4">
            <CircularProgressbarWithChildren
                value={count}
                maxValue={maxValue && 7}
                background
                backgroundPadding={0}
                styles={buildStyles({
                    backgroundColor: "#000",
                })}
            >
                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontSize: "0.875rem",
                        color: "#fff",
                        fontWeight:"700"
                    }}
                >
                    You
                </div>
            </CircularProgressbarWithChildren>
        </div>
    )
}

export default YouCircle