import { Button } from "primereact/button";
import { Password } from "primereact/password";
import React, { useState } from "react";
import classNames from "classnames";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPasswordUrl } from "../../service/api-temp";

function NewPassword() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false)

  if (!searchParams.has("token")) {
    navigate("/auth/login");
  }

  const loginSchema = object({
    password: string()
      .required("Password is requred")
      .min(8, "Password must be 8 symbols"),
    passwordConfirmation: string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true)
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      fetch(resetPasswordUrl, {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
          newPassword: values.password,
          confirmPassword: values.passwordConfirmation,
          token: searchParams.get("token"),
        }),
      }).then((response) => {
        if (response.ok) {
          navigate("/auth/password-recovery/all-set");
        } else {
          setLoading(false)
          alert("there was an error, wops");
        }
      });
    },
  });

  const isFormFieldInvalid = (msg) =>
    !!(formik.touched[msg] && formik.errors[msg]);

  const getFormErrorMessage = (msg) => {
    return isFormFieldInvalid(msg) ? (
      <small className={`${formik.errors[msg] ? "block" : "hidden"} p-error`}>
        {formik.errors[msg]}
      </small>
    ) : (
      ""
    );
  };

  return (
    <form
      className="p-input-filled max-w-sm gap-5 justify-content-center flex flex-column"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex flex-column gap-2">
        <h1 className="text-2xl">New Password</h1>
        <p className="text-sm opacity-60">
          Password must contain at least 7 letters and 1 number.
        </p>
      </div>
      <div className="flex flex-column gap-3">
        <div className="flex flex-column gap-2">
          <label className="text-left text-xs opacity-60">New Password</label>
          <div className="p-input-icon-left">
            <Password
              className={classNames({
                "w-full p-invalid": !!formik.errors.password,
              })}
              placeholder="********"
              type="password"
              aria-label="password field"
              name="password"
              value={formik.values.password}
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value);
              }}
              hideIcon={<VisibilityOffIcon fontSize="inherit" />}
              showIcon={<VisibilityIcon fontSize="inherit" />}
              feedback={false}
              inputStyle={{ paddingLeft: "40px" }}
              toggleMask
            />
            <LockIcon fontSize="inherit" />
          </div>
          {getFormErrorMessage("password")}
        </div>
        <div className="flex flex-column gap-2">
          <label
            htmlFor="Confirm Password"
            className="text-left text-xs opacity-60"
          >
            Confirm New Password
          </label>
          <div className="p-input-icon-left">
            <Password
              className={classNames({
                "w-full p-invalid": !!formik.errors.password,
              })}
              placeholder="********"
              type="password"
              aria-label="password field"
              name="passwordConfirmation"
              value={formik.values.passwordConfirmation}
              onChange={(e) => {
                formik.setFieldValue("passwordConfirmation", e.target.value);
              }}
              hideIcon={<VisibilityOffIcon fontSize="inherit" />}
              showIcon={<VisibilityIcon fontSize="inherit" />}
              feedback={false}
              toggleMask
              inputStyle={{ paddingLeft: "40px" }}
            />
            <LockIcon fontSize="inherit" />
          </div>
          {getFormErrorMessage("passwordConfirmation")}
        </div>
      </div>
      <Button type="submit" loading={loading} label="Reset Password"></Button>
    </form>
  );
}

export default NewPassword;
