import React from "react";
import logo from "../assets/logo.svg";
import logoWhite from "../assets/logo-white.svg";
import logoM from "../assets/logo-mobile.svg";
import logoMWhite from "../assets/logo-mobile-white.svg"
import { Tag } from 'primereact/tag';
import { useScreenDetector } from '../hooks/useScreenDetector'

function Logo(props) {
 const { isMobile } = useScreenDetector();
  let defaultLogo = logo;
  let mobileLogo = logoM;

  // If the variant prop is provided and its value is "white", use the white logo
  if (props.variant === "white") {
    defaultLogo = logoWhite;
    mobileLogo = logoMWhite;
  }

  return (
    <div className={`flex gap-2 align-items-center ${props.isHeader ? '' : 'justify-content-center'}`}>
      <img alt="logo" className='w-4rem md:w-5' src={isMobile ? mobileLogo : defaultLogo} />
      <Tag className='w-3rem surface-700' value="Beta" ></Tag>
    </div>
  );
}

export default Logo;
