import React, { useContext, useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Dialog } from "primereact/dialog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import SettingsTabWrapper from "../SettingsTabWrapper";
import { AppHttp } from "../../../service/app-http";
import {
  addGoogleLoginUrl,
  addLinkedInLoginUrl,
  changeUserTimeZoneEndpoint,
  getAppTimeZonesEndpoint,
  getProfileInfoEndpoint,
  removeGoogleLoginUrl,
  removeLinkedInLoginUrl,
} from "../../../service/api-temp";
import { Dropdown } from "primereact/dropdown";
import ProfileOtherEmails from "./ProfileOtherEmails";
import { UserDataState } from "../../store_providers/user-data-store";
import { getFirstNameFromName } from "../../../service/functions/user-name-functions";
import { useScreenDetector } from "../../../hooks/useScreenDetector";
import Loading from "../../Loading";
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";

function Profile() {
  const [data, setData] = useState({});
  const [attemptedToFetchCodeFromApi, setAttemptedToFechtCodeFromApi] =
    useState(false);
  const [userData] = useContext(UserDataState);

  const [deleteAccountDialogVisible, setDeleteAccountDialogVisible] =
    useState(false);
  const [
    confirmEmailForDeletionDialogVisible,
    setConfirmForDeletionDialogVisible,
  ] = useState(false);

  const { isMobile } = useScreenDetector();

  const [timezones, setTimezones] = useState([]);

  const textAreaRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);

  const [socialLoginError, setSocialLoginError] = useState(null);
  const [
    confirmRemoveGoogleLoginDialogVisible,
    setConfirmRemoveGoogleLoginDialogVisible,
  ] = useState(false);
  const [
    confirmRemoveLinkedInLoginDialogVisible,
    setConfirmRemoveLinkedInLoginDialogVisible,
  ] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => addGoogleLogin(tokenResponse),
    flow: "auth-code",
  });

  const preventMultipleRegisterSubmits = useRef(false);
  const { linkedInLogin } = useLinkedIn({
    clientId: "77y30r1toybqpz",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile email",
    onSuccess: (code) => {
      if (preventMultipleRegisterSubmits.current) {
        return;
      }

      preventMultipleRegisterSubmits.current = true;

      setSocialLoginError(null);

      AppHttp.post(addLinkedInLoginUrl, {
        token: code,
      }).then((response) => {
        if (response.success) {
          loadData();
        } else {
          setSocialLoginError(response.error.message);
        }
      });
    },
    onError: (err) => {
      setSocialLoginError(err);
    },
  });

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
  }

  async function loadData() {
    await AppHttp.get(getProfileInfoEndpoint).then((result) => {
      setIsLoading(false);
      if (result.success) {
        setData(result.data);
      }
    });
  }

  async function loadTimeZones() {
    await AppHttp.get(getAppTimeZonesEndpoint).then((result) => {
      if (result.success) {
        setTimezones(result.data);
      }
    });
  }

  useEffect(() => {
    if (attemptedToFetchCodeFromApi) {
      return;
    }

    if (!attemptedToFetchCodeFromApi) {
      loadData();
      loadTimeZones();

      setAttemptedToFechtCodeFromApi(true);
    }
  }, [attemptedToFetchCodeFromApi]);

  function onTimeZoneChanged(e) {
    const oldValue = data.timeZoneId;

    AppHttp.post(changeUserTimeZoneEndpoint, {
      timeZoneId: e.target.value,
    }).then((result) => {
      setData((val) => ({
        ...val,
        timeZoneId: result.success ? e.target.value : oldValue,
      }));
    });
  }

  function onOtherEmailAdded() {
    loadData();
  }

  function onPrimaryEmailChanged() {
    loadData();
  }

  function onDeleteSecondaryEmail() {
    loadData();
  }

  function onToggleUseGoogleAccount(e) {
    if (e.checked) {
      googleLogin();
    } else {
      setConfirmRemoveGoogleLoginDialogVisible(true);
    }
  }

  function addGoogleLogin(tokenResponse) {
    setSocialLoginError(null);

    AppHttp.post(addGoogleLoginUrl, {
      token: tokenResponse.code,
    }).then((response) => {
      if (response.success) {
        loadData();
      } else {
        setSocialLoginError(response.error.message);
      }
    });
  }

  function removeGoogleLogin() {
    setSocialLoginError(null);
    setConfirmRemoveGoogleLoginDialogVisible(false);

    AppHttp.post(removeGoogleLoginUrl, {}).then((response) => {
      if (response.success) {
        loadData();
      } else {
        setSocialLoginError(response.error.message);
      }
    });
  }

  function removeLinkedInLogin() {
    setSocialLoginError(null);
    setConfirmRemoveGoogleLoginDialogVisible(false);

    AppHttp.post(removeLinkedInLoginUrl, {}).then((response) => {
      if (response.success) {
        loadData();
      } else {
        setSocialLoginError(response.error.message);
      }
    });
  }

  function onToggleUserLinkedInAccount(e) {
    if (e.checked) {
      linkedInLogin();
    } else {
      setConfirmRemoveLinkedInLoginDialogVisible(true);
    }
  }

  function onDeleteAccount() {
    setDeleteAccountDialogVisible(true);
  }

  function onDeleteAccountConfirmed() {
    setDeleteAccountDialogVisible(false);
    setConfirmForDeletionDialogVisible(true);
  }

  return (
    attemptedToFetchCodeFromApi && (
      <SettingsTabWrapper>
        <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
          <label className="w-full lg:w-16rem font-semibold">
            You can gift My Mirror 360!
            <br />
            Your invitation code this month is:
          </label>
          <div className="w-full lg:w-20rem max-w-md flex gap-3 align-items-center">
            <InputText
              ref={textAreaRef}
              value={data?.monthlyCode ?? ""}
              readOnly={true}
            />
            <ContentCopyIcon
              onClick={copyToClipboard}
              className="cursor-pointer"
            />
          </div>
        </div>

        <hr className="w-full px-5 opacity-20" />

        <div className="flex flex-column lg:flex-row gap-3 lg:gap-5 lg:align-items-center">
          <label className="w-full lg:w-16rem font-semibold">
            Login Email Address
          </label>
          <div className="w-full max-w-md">{data.primaryEmail}</div>
        </div>

        <hr className="w-full px-5 opacity-20" />

        <ProfileOtherEmails
          otherEmails={data.otherEmails}
          onNewAdded={onOtherEmailAdded}
          onPrimaryChanged={onPrimaryEmailChanged}
          onDeleted={onDeleteSecondaryEmail}
        />

        <hr className="w-full px-5 opacity-20" />

        <div className="flex flex-column lg:flex-row gap-3 lg:gap-5 lg:align-items-center">
          <label className="w-full lg:w-16rem font-semibold">Time zone</label>
          <div className="w-full max-w-md">
            <Dropdown
              value={data.timeZoneId}
              onChange={onTimeZoneChanged}
              options={timezones}
              optionLabel="description"
              optionValue="id"
            />
          </div>
        </div>

        <hr className="w-full px-5 opacity-20" />

        <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
          <label className="w-full md:w-16rem font-semibold">
            Login options
          </label>

          <div className="flex flex-column gap-3">
            <div className="flex align-items-center">
              <Checkbox
                inputId="checkbox-has-google"
                onChange={(e) => {
                  onToggleUseGoogleAccount(e);
                }}
                checked={data.hasGoogleAccount}
                disabled={data.hasGoogleAccount && !data.canEditGoogleLogin}
              ></Checkbox>
              <label htmlFor="checkbox-has-google" className="ml-2">
                Use your Google Account
              </label>
            </div>
            <div className="flex align-items-center">
              <Checkbox
                onChange={(e) => {
                  onToggleUserLinkedInAccount(e);
                }}
                checked={data.hasLinkedInAccount}
                disabled={data.hasLinkedInAccount && !data.canEditLinkedInLogin}
              ></Checkbox>
              <div className="ml-2">Use your LinkedIn Account</div>
            </div>
            {socialLoginError && (
              <div className="p-error">{socialLoginError}</div>
            )}
          </div>
        </div>

        <hr className="w-full px-5 opacity-20" />

        <Dialog
          position={isMobile ? "bottom" : "center"}
          headerStyle={{ padding: "0" }}
          visible={confirmRemoveGoogleLoginDialogVisible}
          style={{ width: "15rem" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setConfirmRemoveGoogleLoginDialogVisible(false)}
        >
          <div className="flex flex-column gap-3 text-center align-items-center ">
            <p className="font-bold text-xl">Remove google login?</p>
            <div className="w-full flex justify-content-between">
              <Button
                label="Close"
                severity="danger"
                onClick={() => setConfirmRemoveGoogleLoginDialogVisible(false)}
                outlined
              ></Button>

              <Button
                label="Confirm"
                onClick={() => removeGoogleLogin(false)}
              ></Button>
            </div>
          </div>
        </Dialog>

        <Dialog
          position={isMobile ? "bottom" : "center"}
          headerStyle={{ padding: "0" }}
          visible={confirmRemoveLinkedInLoginDialogVisible}
          style={{ width: "15rem" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setConfirmRemoveLinkedInLoginDialogVisible(false)}
        >
          <div className="flex flex-column gap-3 text-center align-items-center ">
            <p className="font-bold text-xl">Remove LinkedIn login?</p>
            <div className="w-full flex justify-content-between">
              <Button
                label="Close"
                severity="danger"
                onClick={() =>
                  setConfirmRemoveLinkedInLoginDialogVisible(false)
                }
                outlined
              ></Button>

              <Button
                label="Confirm"
                onClick={() => removeLinkedInLogin(false)}
              ></Button>
            </div>
          </div>
        </Dialog>

        {/* <div className="w-20rem lg:w-full flex flex-column lg:flex-row gap-3 align-items-start lg:gap-5 lg:align-items-center">
          <label className="w-full md:w-16rem font-semibold">
            Delete your account and data
          </label>
          <Button
            label="Delete Account"
            onClick={onDeleteAccount}
            severity="danger"
            className="w-full lg:w-auto"
          />
          <Dialog
            position={isMobile ? "bottom" : "center"}
            headerStyle={{ padding: "0" }}
            visible={deleteAccountDialogVisible}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            onHide={() => setDeleteAccountDialogVisible(false)}
          >
            <div className="flex gap-3 flex-column text-center align-items-center ">
              <HighlightOffIcon className="text-red-500 opacity-20 w-full h-5rem " />
              <p className="font-bold text-2xl">
                Are you sure you want to delete your account?
              </p>
              <p className="text-sm">
                {getFirstNameFromName(userData.name)}, if you're ready to leave
                forever, we'll send you an email with the final step to:
              </p>
              <p className="font-bold">{data.primaryEmail}</p>
              <div className="flex justify-content-between gap-2">
                <Button
                  label="Send Email"
                  onClick={onDeleteAccountConfirmed}
                  severity="danger"
                ></Button>
                <Button
                  label="Cancel"
                  onClick={() => setDeleteAccountDialogVisible(false)}
                  severity="danger"
                  outlined
                ></Button>
              </div>
            </div>
          </Dialog>
          <Dialog
            position={isMobile ? "bottom" : "center"}
            headerStyle={{ padding: "0" }}
            visible={confirmEmailForDeletionDialogVisible}
            style={{ width: "24rem" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            onHide={() => setConfirmForDeletionDialogVisible(false)}
          >
            <div className="flex flex-column gap-3 text-center align-items-center ">
              <p className="font-bold text-2xl">
                We've sent you email to confirm your deletion request.
              </p>
              <Button
                label="Close"
                severity="danger"
                onClick={() => setConfirmForDeletionDialogVisible(false)}
                outlined
              ></Button>
            </div>
          </Dialog>
        </div> */}
      </SettingsTabWrapper>
    )
  );
}

export default Profile;
