import React, { useState } from 'react'
import Block from './Block';
import { useScreenDetector } from '../../hooks/useScreenDetector';

function OverallScores({ isSelf, isActive, toggleIsActive, scoreSelected }) {
    const [selectedBlockId, setSelectedBlockId] = useState(false);
    const { isMobile } = useScreenDetector();

    const blocksReflection = [
        {
            id: 1,
            text: "Terribly",
            color: "bg-gray-600",
            value: -50,
        },
        {
            id: 2,
            text: "Not Well",
            color: "bg-gray-400",
            value: 0,
        },
        {
            id: 3,
            text: "So So",
            color: "bg-yellow-100",
            value: 100,
        },
        {
            id: 4,
            text: "Well",
            color: "bg-yellow-200",
            value: 180,
        },
        {
            id: 5,
            text: "Great",
            color: "bg-yellow-400",
            value: 300,
        },
        {
            id: 6,
            text: "Outstanding",
            color: "bg-yellow-500",
            value: 400,
        },
    ];

    const blocksSelfReflection = [
        {
            id: 1,
            text: "Blue",
            color: "fulfillment-bg-1",
            textColor: "fulfillment-text-1",
            border: true,
            value: 0,
        },
        {
            id: 2,
            text: "",
            color: "fulfillment-bg-2",
            textColor: "",
            border: true,
            value: 200,
        },
        {
            id: 3,
            text: "",
            color: "fulfillment-bg-3",
            textColor: "",
            border: true,
            value: 400,
        },
        {
            id: 4,
            text: "",
            color: "fulfillment-bg-4",
            textColor: "",
            border: true,
            value: 600,
        },
        {
            id: 5,
            text: "",
            color: "fulfillment-bg-5",
            textColor: "",
            border: true,
            value: 800,
        },
        {
            id: 6,
            text: "Happy",
            color: "fulfillment-bg-6",
            textColor: "fulfillment-text-6",
            border: true,
            value: 1000,
        },
    ];

    function handleToggle(id) {
        setSelectedBlockId(id);
        toggleIsActive(false);

        if (scoreSelected) {
            let blocks = blocksReflection;

            if (isSelf) {
                blocks = blocksSelfReflection;
            }

            const selectedBlock = blocks.find(b => b.id === id);
            scoreSelected(selectedBlock.value);
        }

    }

    let blockList = null
    if (isSelf) {
        blockList = blocksSelfReflection.map((block) => {
            return (
                <Block
                    key={block.id}
                    id={block.id}
                    text={block.text}
                    color={block.color}
                    active={isActive}
                    bordered={selectedBlockId === block.id}
                    click={handleToggle}
                    circle={block.border}
                    textColor={block.textColor}
                />
            );
        });
    } else {
        blockList = blocksReflection.map((block) => {
            return (
                <Block
                    key={block.id}
                    id={block.id}
                    text={block.text}
                    color={block.color}
                    active={isActive}
                    bordered={selectedBlockId === block.id}
                    click={handleToggle}
                    circle={block.border}

                />
            );
        });
    }
    return (
        <div className={`md:sticky top-20 z-1 blocks-container md:flex justify-content-around  ${isActive ? "gap-0 md:px-7 " : "py-5 px-5"}`}>
            {(!isActive && !isMobile) && <div className="mirror-card_background-blur" />}
            {blockList}
            <div className={`hidden md:block absolute left-0 right-0 px-8 ${isActive ? 'top-15' : 'top-70'}`} >
                <div style={{ height: "1px", backgroundColor: "#dcdcdc" }}></div>
            </div>
            <div className={`block md:hidden animation-block ${isSelf
                ? isActive
                    ? "circles-overall-scrore_line-wrapper-active"
                    : "circles-overall-scrore_line-wrapper"
                : isActive
                    ? "overall-scrore_line-wrapper-active"
                    : "overall-scrore_line-wrapper"
                }`}
            >
                <div className="overall-score_line"></div>
            </div>
        </div>
    )
}

export default OverallScores