import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import PersonIcon from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, { useState } from "react";
import { RelationshipProximity, RelationshipType } from "../../service/enums";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { addRelationshipSchema } from "../../service/validationschemas/validation-schemas";

function AddRelationshipForm(props) {
  const [loading, setLoading] = useState(false);

  const types = [
    { id: RelationshipType.Peer, name: "Peer" },
    { id: RelationshipType.Junior, name: "Junior" },
    { id: RelationshipType.Senior, name: "Senior" },
    { id: RelationshipType.Client, name: "Client" },
  ];

  const formik = useFormik({
    initialValues: {
      id: 0,
      reflectorEmail: "",
      reflectorAlias: "",
      relationshipProximity: null,
      relationshipType: RelationshipType.Peer,
    },
    validationSchema: addRelationshipSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      props.onSubmit(values);
      setLoading(true);
      formik.resetForm({ values: "" });
    },
  });

  function handleSelection(number) {
    if (formik.values.relationshipProximity === number) {
      formik.setFieldValue("relationshipProximity", null);
    } else {
      formik.setFieldValue("relationshipProximity", number);
    }
  }
  const isFormFieldInvalid = (msg) =>
    !!(formik.touched[msg] && formik.errors[msg]);

  const getFormErrorMessage = (msg) => {
    return isFormFieldInvalid(msg) ? (
      <small className={`${formik.errors[msg] ? "block" : "hidden"} p-error`}>
        {formik.errors[msg]}
      </small>
    ) : (
      ""
    );
  };

  function setRelationshipType(e) {
    formik.setFieldValue("relationshipType", e.value);
  }

  return (
    <form
      className="w-full h-full flex flex-column gap-5 text-left"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex flex-column gap-3">
        <div className="flex flex-column gap-2">
          <label className="text-sm opacity-80" htmlFor="name">
            Full Name*
          </label>
          <InputText
            id="name"
            aria-describedby="name"
            value={formik.values.reflectorAlias}
            onChange={(e) => {
              formik.setFieldValue("reflectorAlias", e.target.value);
            }}
          />
          {getFormErrorMessage("reflectorAlias")}
        </div>
        <div className="flex flex-column gap-2">
          <label className="text-sm opacity-80" htmlFor="email">
            Email*
          </label>
          <InputText
            id="email"
            aria-describedby="email"
            value={formik.values.reflectorEmail}
            onChange={(e) => {
              formik.setFieldValue("reflectorEmail", e.target.value);
            }}
          />
          {getFormErrorMessage("reflectorEmail")}
        </div>
        {props.allowEditingType ? (
          <div className="flex flex-column gap-2">
            <label className="text-sm opacity-80" htmlFor="reflectorEmail">
              Relation to you
            </label>
            <Dropdown
              value={formik.values.relationshipType}
              onChange={setRelationshipType}
              options={types}
              optionLabel="name"
              optionValue="id"
              placeholder="Choose one"
            />
            {getFormErrorMessage("relationshipType")}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="flex flex-column gap-3">
        <p className="text-sm opacity-80">
          How often do you work with this person? SELECT ONE*
        </p>
        <div className="flex align-items-center gap-5 text-500">
          <div
            onClick={() => handleSelection(RelationshipProximity.Often)}
            className={`${
              formik.values.relationshipProximity ===
              RelationshipProximity.Often && "text-primary"}
               cursor-pointer flex flex-column align-items-center relationshipContainer`}
          >
            <div className="flex ">
              <PersonIcon fontSize="inherit" />
              <PersonIcon fontSize="inherit" />
            </div>
            <span className="text-sm">Often</span>
          </div>
          <div
            onClick={() => handleSelection(RelationshipProximity.Occasionally)}
            className={`${
              formik.values.relationshipProximity ===
              RelationshipProximity.Occasionally
                ? "text-primary"
                : ""
            }  cursor-pointer flex flex-column align-items-center relationshipContainer`}
          >
            <div className="flex ">
              <PersonIcon fontSize="inherit" />
              <MoreHorizIcon fontSize="inherit" />
              <PersonIcon fontSize="inherit" />
            </div>
            <span className="text-sm">Occasionally</span>
          </div>
          <div
            onClick={() => handleSelection(RelationshipProximity.Sporadically)}
            className={`${
              formik.values.relationshipProximity ===
              RelationshipProximity.Sporadically
                ? "text-primary"
                : ""
            }  cursor-pointer flex flex-column align-items-center relationshipContainer`}
          >
            <div className="flex ">
              <PersonIcon fontSize="inherit" />
              <span className="flex p-0">
                <MoreHorizIcon fontSize="inherit" className="rightDots" />
                <MoreHorizIcon fontSize="inherit" />
              </span>
              <PersonIcon fontSize="inherit" />
            </div>
            <span className="text-sm">Sporadically</span>
          </div>
        </div>
        {getFormErrorMessage("relationshipProximity")}
      </div>
      <Button loading={loading} label="Add" type="submit"></Button>
    </form>
  );
}

export default AddRelationshipForm;
