import React, { useContext, useEffect } from "react";

import { ProgressSpinner } from "primereact/progressspinner";
import { setAuthDataObj } from "../../service/UserAuthData";
import { confirmEmailUrl } from "../../service/api-temp";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppHttp } from "../../service/app-http";
import { UserDataState } from "../store_providers/user-data-store";

function ConfirmEmail() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [userData, dispatch] = useContext(UserDataState);

  if (!searchParams.has("userId") || !searchParams.has("token")) {
    navigate("/auth/login");
  }

  useEffect(() => {
    async function cfmEmail() {
      AppHttp.get(confirmEmailUrl, {
        userId: searchParams.get("userId"),
        token: searchParams.get("token"),
      }).then((response) => {
        if (response.success) {
          setAuthDataObj({
            token: response.data.token,
            expires: response.data.expires,
          });

          delete response.data.token;
          delete response.data.expires;

          dispatch({ type: "set", payload: response.data });

          navigate("/auth/sign-up-success");
        } else {
          navigate("/auth/login");
        }
      });
    }

    cfmEmail();
  }, [navigate, searchParams,dispatch]);

  return (
    <div className="w-full h-full flex justify-content-center align-items-center">
      <ProgressSpinner />
    </div>
  );
}

export default ConfirmEmail;
