import React from "react";
import { Link } from "react-router-dom";

function Tips() {
  return (
    <section
      id="Tips"
      className="flex flex-column gap-5 mt-5 max-w-3xl mx-auto"
    >
      <div className="text-center flex flex-column gap-2 max-w-xl mx-auto">
        <h2 className="md:text-2xl text-xl">Tips to Better Yourself</h2>
        <p className="text-xs opacity-80">
          Below is some "food for thought" on how to do better at work! These
          typically address areas of your performance which your coworkers
          highlight as weaker.
        </p>
      </div>
      <div className="mx-auto text-3xl font-bold">Coming soon</div>
      {/* <div className="flex md:flex-row flex-column">
        <div className="flex flex-column justify-content-between gap-3 w-full md:w-7 p-5 bg-white">
          <h3 className="text-sm">
            You may have every intent to meet and exceed your workplace
            standards, and yet at the end of the day it feels as if you fall
            short.
          </h3>
          <p className="text-xs opacity-80">
            Oftentimes the biggest barrier to reaching those goals you and your
            employer set are the goals themselves. They may be unclear,
            unreasonable, and unrelated to the work you're doing. It may be time
            to revisit your goals and reframe them into SMART goals. That means
            they are specific, measurable, achievable, relevant, and time-bound.
            This will help you focus on the tasks at hand and reach each goal.
          </p>
        </div>
        <div className="flex flex-column justify-content-between gap-3 w-full md:w-5 p-5 betteryourself_cta">
          <h3 className="text-sm">Want to go deeper?</h3>
          <p className="text-xs opacity-80">
            Here is a closer look at SMART goals and how to use them to achieve
            higher standards in work and life:
          </p>
          <Link
            to="https://www.mindtools.com/pages/article/smart-goals.html"
            target="_blank"
            className="text-color-fulfillment text-xs"
          >
            www.mindtools.com/pages/article/smart-goals.html
          </Link>
        </div>
      </div> */}
    </section>
  );
}

export default Tips;
