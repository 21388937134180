import React, { useCallback, useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Slider from "./Slider";
import { Button } from "primereact/button";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import chroma from "chroma-js";
import Logo from "../Logo";
import backgroundImage from "../../assets/fulfillment_bg-img.png";
import ReflectionLayout from "./ReflectionLayout";
import ReflectionContainer from "./ReflectionContainer";
import ReflectionWrapper from "./ReflectionWrapper";
import ReflectionBody from "./ReflectionBody";
import OverallScores from "./OverallScores";
import { AppHttp } from "../../service/app-http";
import {
  selfReflectionGetByTokenEndpoint,
  submitSelfReflectionEndpoint,
} from "../../service/api-temp";
import { useFormik } from "formik";
import { selfReflectionSchema } from "../../service/validationschemas/validation-schemas";
import { Dialog } from "primereact/dialog";
import { useScreenDetector } from "../../hooks/useScreenDetector";

function Reflection() {
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [
    comingFromSelfAwarenessReportInfoDialogVisible,
    setComingFromSelfAwarenessReportInfoDialogVisible,
  ] = useState(false);
  const [error, setError] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const toggleIsActive = () => {
    setIsActive(true);
  };
  let navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useScreenDetector();
  const [searchParams] = useSearchParams();
  const [reflection, setReflection] = useState(null);
  const [areAllQuestionsTouched, setAreAllQuestionsTouched] = useState(false);
  const [width, setWidth] = useState("0%");
  const [fetchedFromApi, setFetchedFromApi] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: null,
      overallScore: null,
      q1Score: 500,
      q2Score: 500,
      q3Score: 500,
      q4Score: 500,
      q5Score: 500,
      q6Score: 500,
      q7Score: 500,
    },
    validationSchema: selfReflectionSchema,
    onSubmit: (values) => {
      setLoading(true)
      AppHttp.post(submitSelfReflectionEndpoint, values).then((result) => {
        if (result.success) {
          navigate("/thankyouself", {
            state: {
              token: searchParams.get("token"),
            },
          });
        } else if (result) {
          setLoading(false)
          alert("Something went wrong! Please try again");
        }
      });
    },
  });

  useEffect(() => {
    if (location?.state?.isFromAwarenessReport) {
      setComingFromSelfAwarenessReportInfoDialogVisible(true);
    }
  }, [location]);

  useEffect(() => {
    if (!searchParams.has("token")) {
      navigate("/dashboard/reflectors-overview");
    }
    setFetchedFromApi(true);
    async function getReflectionFromApi() {
      var result = await AppHttp.get(selfReflectionGetByTokenEndpoint, {
        token: searchParams.get("token"),
      });

      if (result.success) {
        setReflection(result.data);
        formik.setFieldValue("id", result.data.id);
      } else {
        if (result.error.code === "filled") {
          navigate("/completedSelf", {
            state: {
              token: searchParams.get("token"),
            },
          });
        } else {
          setError(result.error.message);
          setErrorDialogVisible(true);
        }
      }
    }

    if (!fetchedFromApi) {
      getReflectionFromApi();
    }
  }, [formik, navigate, reflection, searchParams,fetchedFromApi]);

  const checkIfQuestionAreTouched = useCallback(() => {
    const q1Touched = formik.initialValues.q1Score !== formik.values.q1Score;
    const q2Touched = formik.initialValues.q2Score !== formik.values.q2Score;
    const q3Touched = formik.initialValues.q3Score !== formik.values.q3Score;
    const q4Touched = formik.initialValues.q4Score !== formik.values.q4Score;
    const q5Touched = formik.initialValues.q5Score !== formik.values.q5Score;
    const q6Touched = formik.initialValues.q6Score !== formik.values.q6Score;
    const q7Touched = formik.initialValues.q7Score !== formik.values.q7Score;

    const touchedElements = [
      q1Touched,
      q2Touched,
      q3Touched,
      q4Touched,
      q5Touched,
      q6Touched,
      q7Touched,
    ];
    let width = 0;
    for (let i = 0; i < touchedElements.length; i++) {
      if (touchedElements[i]) {
        width = (i + 1) * 15 + "%";
      }
    }
    setWidth(width);

    if (
      q1Touched &&
      q2Touched &&
      q3Touched &&
      q4Touched &&
      q5Touched &&
      q6Touched &&
      q7Touched
    )
      setAreAllQuestionsTouched(true);
  }, [
    formik.initialValues.q1Score,
    formik.initialValues.q2Score,
    formik.initialValues.q3Score,
    formik.initialValues.q4Score,
    formik.initialValues.q5Score,
    formik.initialValues.q6Score,
    formik.initialValues.q7Score,
    formik.values.q1Score,
    formik.values.q2Score,
    formik.values.q3Score,
    formik.values.q4Score,
    formik.values.q5Score,
    formik.values.q6Score,
    formik.values.q7Score,
  ]);

  useEffect(() => {
    if (!areAllQuestionsTouched) {
      checkIfQuestionAreTouched();
    }
  }, [formik, checkIfQuestionAreTouched, areAllQuestionsTouched]);

  function onSelectOverallScore(blockId) {
    formik.setFieldValue("overallScore", blockId);
  }

  function handleQuestionChanged(question, value) {
    const qIndex =
      reflection.questions.findIndex((q) => q.id === question.id) + 1;
    formik.setFieldValue(`q${qIndex}Score`, Number(value?.toFixed(0) ?? 0));
  }

  function submitFormik() {
    if (areAllQuestionsTouched) {
      formik.submitForm();
    }
  }

  const customChroma = chroma
    .scale(["#2594CC", "#83C0E5", "#D3BAB5", "#E6B07E", "#E1935B", "#D87025"])
    .domain([0, 200, 400, 600, 800, 1000]);

  return (
    <ReflectionLayout>
      <div
        className="fixed z-0 top-0 bottom-0 left-0 right-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <Link
        to="https://www.mymirror360.com/"
        target="_blank"
        className="relative mt-5 mx-auto"
      >
        <Logo />
      </Link>
      <div className="w-full" />
      <ReflectionContainer>
        <div className="flex flex-column gap-3 px-3 mb-3 lg:mb-6">
          <h1 className="text-3xl sm: text-4xl md:text-5xl lg:text-6xl mirror-dark-blue-text">
            How are <span className="fulfillment-text-6">You</span> feeling at
            work this week?
          </h1>
          <p className="text-sm lg:text-base">
            <span className="font-bold">Honest self-reflections</span> are key
            to a better you at work. They help improve your self-awareness{" "}
            <span className="font-bold">about what fulfills you at work.</span>
          </p>
        </div>
        <OverallScores
          isSelf={true}
          isActive={isActive}
          toggleIsActive={toggleIsActive}
          scoreSelected={onSelectOverallScore}
        />
        {isActive && (
          <ReflectionWrapper>
            <div className="mirror-card_background-blur" />
            <ReflectionBody>
              {reflection?.questions &&
                reflection?.questions?.map((q, index) => {
                  return (
                    <Slider
                      key={q.id}
                      question={q}
                      initial={formik.values[`q${index + 1}Score`]}
                      min={0}
                      max={1000}
                      formatFn={(number) => number?.toFixed(2)}
                      onChange={handleQuestionChanged}
                      colorBarStart="fulfillment-bg-1"
                      colorBarEnd="fulfillment-bg-6"
                      customChroma={customChroma}
                      isSelf={true}
                    />
                  );
                })}
              <Button
                disabled={!areAllQuestionsTouched}
                type="button"
                onClick={submitFormik}
                label="Submit Self Reflection"
                className="w-14rem"
                loading={loading}
              ></Button>
              {!areAllQuestionsTouched && (
                <small className="text-primary">
                  Complete all questions before submitting.
                </small>
              )}
              <small className="opacity-80">
                <span className="font-bold">Honest self-reflections</span> are
                key to a better you at work.
                <br />
                They help improve your self-awareness{" "}
                <span className="font-bold">
                  about what fulfills you at work.
                </span>
              </small>
            </ReflectionBody>
          </ReflectionWrapper>
        )}
      </ReflectionContainer>
      <Footer></Footer>

      <Dialog
        position={isMobile ? "bottom" : "center"}
        headerStyle={{ padding: "0" }}
        visible={comingFromSelfAwarenessReportInfoDialogVisible}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setComingFromSelfAwarenessReportInfoDialogVisible(false)}
        closeOnEscape={false}
        closable={false}
      >
        <div className="flex flex-column gap-3 text-center align-items-center p-5 pb-2 ">
          <p className="font-bold text-xl">
            You must self-reflect before being able to view your self-awareness
            report.
          </p>
          <div className="flex justify-content-between gap-2">
            <Button
              label="Ok"
              onClick={() =>
                setComingFromSelfAwarenessReportInfoDialogVisible(false)
              }
            ></Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        position={isMobile ? "bottom" : "center"}
        headerStyle={{ padding: "0" }}
        visible={errorDialogVisible}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setErrorDialogVisible(false)}
        closeOnEscape={false}
        closable={false}
      >
        <div className="flex flex-column gap-3 text-center align-items-center p-5 pb-2 ">
          <p className="font-bold text-2xl">{error}</p>
          <div className="flex justify-content-between gap-2">
            <Button
              label="Home"
              onClick={() => navigate("/dashboard/reflectors-overview")}
            ></Button>
          </div>
        </div>
      </Dialog>
      <div className="reflection_progress-wrapper">
        <div
          style={{ width: `${width}` }}
          className="reflection_progress-line"
        ></div>
      </div>
    </ReflectionLayout>
  );
}

export default Reflection;
