import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../service/UserAuthData';
import { useScreenDetector } from '../../hooks/useScreenDetector';

import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import { UserDataState } from '../store_providers/user-data-store';
import { getSelfAwarenessReportProgressEndpoint } from '../../service/api-temp';
import { AppHttp } from '../../service/app-http';

function HeaderMenu() {
  const [userData, dispatch] = useContext(UserDataState);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [fetchedFromApi, setFetchedFromApi] = useState(false);

  const [items, setItems] = useState([
    {
      id: 1,
      label: 'Reflectors',
      command: () => { navigate('/dashboard/reflectors-overview') }
    },
    {
      id: 3,
      label: 'Settings',
      command: () => { navigate('/dashboard/settings/profile-info') }
    },
    {
      id: 4,
      label: 'Log Out',
      command: (e) => {
        logOut();
      }
    }
  ])

  useEffect(() => {
    function loadProgress() {
      AppHttp.get(getSelfAwarenessReportProgressEndpoint, {
      }).then((result) => {
        if (result.success) {
          dispatch({ type: 'update', payload: { mirrorImageProgress: result.data.percent } })
        }

        setFetchedFromApi(true);
      });
    }

    if (!fetchedFromApi) {
      loadProgress();
    }
  }, []);

  useEffect(() => {
    const index = items.findIndex(i => i.id === 2);
    if (userData && userData.mirrorImageProgress === 100) {
      setItems([
        {
          id: 1,
          label: 'Reflectors',
          command: () => { navigate('/dashboard/reflectors-overview') }
        },
        {
          id: 2,
          label: 'Self Awareness report',
          command: () => { navigate('/dashboard/self-awareness-report') }
        },
        {
          id: 3,
          label: 'Settings',
          command: () => { navigate('/dashboard/settings/profile-info') }
        },
        {
          id: 4,
          label: 'Log Out',
          command: (e) => {
            logOut();
          }
        }
      ])
    } else {
      setItems([{
        id: 1,
        label: 'Reflectors',
        command: () => { navigate('/dashboard/reflectors-overview') }
      },
      {
        id: 3,
        label: 'Settings',
        command: () => { navigate('/dashboard/settings/profile-info') }
      },
      {
        id: 4,
        label: 'Log Out',
        command: (e) => {
          logOut();
        }
      }])
    }
  }, [userData]);



  const { isMobile } = useScreenDetector();


  return (
    <>
      {isMobile ?
        <MobileMenu />
        :
        <DesktopMenu items={items} />
      }
    </>


  )
}

export default HeaderMenu

