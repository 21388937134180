import React from "react";
import SignupForm from "./SignupForm";
import SignupRightSide from "./SignupRightSide";
import Logo from "../Logo";
import { Link } from "react-router-dom";
import AuthLayout from "../Auth/AuthLayout";
import AuthWrapper from "../Auth/AuthWrapper";

function SignupLayout() {
  return (
    <>
      <AuthLayout>
        <AuthWrapper>
          <Link to="https://www.mymirror360.com/" target="_blank">
            <Logo className={"flex align-center"} />
          </Link>
          <SignupForm />
          <div className="w-full" />
        </AuthWrapper>
        <SignupRightSide />
      </AuthLayout>
    </>
  );
}

export default SignupLayout;
