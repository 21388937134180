import Lottie from "lottie-react";
import React, { useContext } from "react";
import ReflectorsWrapper from "./ReflectorsWrapper";
import { Tooltip } from "primereact/tooltip";
import YouCircle from "./YouCircle";
import ReflectorsSidebar from "./ReflectorsSidebar";
import { Button } from "primereact/button";
import light from "../../lottie/light.json";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { UserDataState } from "../store_providers/user-data-store";

function AddReflectorsDesktop(props) {
  const [userData] = useContext(UserDataState);

  return (
    <>
      <ReflectorsWrapper>
        <div className="circle_wrapper flex flex-column align-items-center justify-content-center">
          <div className=" n-circle flex align-items-center justify-content-center">
            {props.relationships?.map((reflector, index) => (
              <div
                key={index}
                className={`tooltip-button-${index} text-900 font-semibold circle deg-${
                  index * 30
                } translate-${reflector.relationshipProximity * 100 + 100}`}
                onClick={() => {
                  props.setEditRelationship(reflector);
                }}
              >
                <Lottie
                  className={`pointer-events-none absolute light-l light-${
                    index + 1
                  } `}
                  animationData={light}
                  loop={true}
                />
                <Tooltip
                  event="both"
                  target={`.tooltip-button-${index}`}
                  autoHide={false}
                >
                  <div className="flex flex-column align-items-center ">
                    <div className="font-bold text-xs">
                      {reflector.reflectorAlias}
                    </div>
                    <div
                      onClick={() => {
                        props.setEditRelationship(reflector);
                      }}
                      className="cursor-pointer text-primary"
                    >
                      (Click to edit)
                    </div>
                  </div>
                </Tooltip>
                <div className="text-sm">{reflector.initials}</div>
              </div>
            ))}
          </div>
          <div className="often text-xs opacity-60">Often</div>
          <div className="big-circle big-circle-1"></div>
          <div className="occ text-xs opacity-60">Occasionally</div>
          <div className="big-circle big-circle-2"></div>
          <div className="spor text-xs opacity-60">Sporadically</div>
          <div className="big-circle big-circle-3 text-xs"></div>
          <YouCircle maxValue={props.maxValue} count={props.count} />
        </div>
      </ReflectorsWrapper>
      <ReflectorsSidebar>
        <p className="text-sm opacity-80">
          Welcome to your
          <br />
          very own 360° Mirror.
        </p>
        <div className="flex flex-column gap-4">
          <h1 className="text-2xl">
            {props.maxValue === 7 ? (
              "ADD YOUR REFLECTORS"
            ) : (
              <>
                <div className="text-5xl font-bold">GREAT!</div>
                <div className="text-3xl font-normal">
                  YOUR 360° MIRROR IS SET
                </div>
              </>
            )}
          </h1>
          <p className="text-sm opacity-80 max-w-sm">
            {props.maxValue === 7
              ? "You will need seven to activate your 360 Mirror. Whose opinion matters to you the most?"
              : "You have set enough perspectives to activate your 360° mirror. Add more (up to 12 for now) for a higher resolution mirror image."}
          </p>
          <div className="flex align-items-center text-xl">
            <Button
              className="p-button-rounded p-button-primary flex align-items-center justify-content-center m-circle-button"
              disabled={props.count >= userData.reflectorsCap}
              onClick={() => props.showAdd(true)}
            >
              <AddIcon />
            </Button>
            <span className="font-bold font-montserrat ml-3 mr-1 text-primary text-2xl">
              {props.count}
            </span>
            <small className="font-montserrat">
              {" / "}
              {props.maxValue}
            </small>
          </div>
        </div>
        <div className="flex-column flex gap-3 align-items-start">
          <div className="tooltip-help flex align-items-center">
            <HelpOutlineIcon fontSize="small" className="cursor-pointer mr-2" />
            <Tooltip
              event="both"
              position="top"
              target=".tooltip-help"
              autoHide={true}
              style={{ width: "100%", maxWidth: "20rem" }}
            >
              <p className="p-2 text-xs">
                By clicking on “activate reflections” you simply confirm your
                preliminary choices for whose opinion matters to you. The system
                will start to ODDly (occasionally, discretely, and delightfully)
                ask your teammates about their honest perspectives on you.
                <br />
                <br />
                Rest assured, you can always swap or add reflectors or pause
                reflections altogether (from your settings screen).
              </p>
            </Tooltip>
            <small className="cursor-pointer">What happens next?</small>
            {props.count >= 55 ? (
              <div className="absolute">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="25"
                  viewBox="0 0 40 25"
                  fill="none"
                >
                  <path
                    d="M3.52506 21.2226C3.3131 20.1851 2.22635 11.7471 0.850758 11.8815C0.0468147 11.96 0.206686 13.049 0.263364 13.4862C0.674561 16.6473 1.13697 19.506 1.80021 22.6332C2.29929 24.9873 4.39363 24.9621 6.49358 24.8413C9.69961 24.657 10.0123 24.4437 13.3283 24.1326C13.8748 24.0812 14.4374 24.0717 14.9596 23.965C15.5737 23.8393 16.3065 23.4232 16.1827 22.7256C16.0424 21.935 15.3187 21.7968 14.6433 21.8948C14.5023 21.9155 14.3604 21.9312 14.2182 21.9435C12.6563 22.0783 5.45927 23.0973 5.36581 22.5784C11.0459 11.8815 26.0514 3.27798 38.1047 2.63824C39.3714 2.63865 39.4346 0.384405 38.2826 0.384448C20.9338 0.914417 7.9585 14.2969 3.52506 21.2226Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
                <p className="font-bold">You can proceed anytime</p>
              </div>
            ) : (
              ""
            )}
          </div>
          <Button
            disabled={props.count < 7}
            onClick={() => props.show7Dialog(true)}
            label="Activate Reflections"
          />
        </div>
      </ReflectorsSidebar>
    </>
  );
}

export default AddReflectorsDesktop;
