import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import completed from "../../assets/fulfillment-thank-you_bg-img.png";
import { Button } from "primereact/button";
import Logo from "../Logo";
import backgroundImage from "../../assets/fulfillment_bg-img.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MirrorImageProgress from "../addReflectors/MirrorImageProgress";
import { AppHttp } from "../../service/app-http";
import { isSelfAwarenessReportReadyEndpoint } from "../../service/api-temp";
import Loading from "../Loading";

function ThankYouSelf() {
  const [loading, setLoading] = useState(true)
  const completedStyle = {
    maxWidth: "100%",
    objectFit: "cover",
    objectPosition: "top",
  };

  const [isSelfAwarenessReportReady, setIsSelfAwarenessReportReady] =
    useState(false);
  let navigate = useNavigate();
  const location = useLocation();

  if (!location?.state?.token) {
    navigate("/dashboard/reflectors-overview");
  }

  useEffect(() => {
    AppHttp.get(isSelfAwarenessReportReadyEndpoint, {
      token: location?.state?.token,
    }).then((result) => {
      setLoading(false)
      if (result.success) {
        setIsSelfAwarenessReportReady(result.data);
      }
    });
  }, [location?.state?.token]);

  return (
    <Loading visible={loading}>
    <div className="min-h-screen flex flex-column align-items-center gap-5 ">
      <div
        className="absolute w-full h-full bg-cover lg:bg-left-bottom bg-center-bottom z-0"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <Link
        to="https://www.mymirror360.com/"
        target="_blank"
        className="relative z-2 mt-5 mx-auto mb-auto"
      >
        <Logo />
      </Link>
      <div className="relative w-full flex flex-column align-items-center gap-5 mt-auto sm:mb-auto px-5 z-2">
        <div className="relative h-26rem max-w-4xl">
          <img
            style={completedStyle}
            className="absolute w-full sm:relative w-full h-full"
            alt="Thank You"
            src={completed}
          ></img>
        </div>
        <div className="flex flex-column md:flex-row gap-2 mt-auto sm:mt-0">
          <Link to="/dashboard/reflectors-overview">
            <Button label="Your Reflectors" outlined></Button>
          </Link>
          {isSelfAwarenessReportReady && (
            <Link to="/dashboard/self-awareness-report">
              <Button label="See your Mirror Image"></Button>
            </Link>
          )}
        </div>
        {!isSelfAwarenessReportReady && (
          <div className="max-w-sm mx-auto">
            <MirrorImageProgress token={location?.state?.token} />
          </div>
        )}
      </div>
      <div className="sm:mt-auto z-2 w-full">
        <Footer className="w-full z-2"></Footer>
      </div>
    </div>
    </Loading>
  );
}

export default ThankYouSelf;
