import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { drawRadialChart } from "./subcharts/radialBarChart";
import { drawDonutChart } from "./subcharts/donutChart";
import { drawScoreCircle } from "./subcharts/scoreCircle";
import { Accordion, AccordionTab } from "primereact/accordion";
import moment from 'moment';

const RadialMirrorImage = ({ data, lastUpdatedDate, analyses }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      drawChart();
    }
  }, [data]);

  const drawBackgroundCircle = (chartGroup, dimensions) => {
    // Draw circle in the middle of the radial chart with score ot of total
    //const radius = dimensions.height * 0.424;
    const radiusOffset = Math.min(dimensions.width, dimensions.height) * 0.1;
    const radius = (Math.min(dimensions.width, dimensions.height) / 2) - radiusOffset;
    const scoreGroup = chartGroup.append('g');
    scoreGroup.append('circle')
      .attr('class', 'bgCircle')
      .attr('r', radius)
      .attr('fill', '#ffffff');
  }

  const getParentDimensions = () => {
    const container = chartRef.current.getBoundingClientRect();
    return {
      width: container.width,
      height: container.height,
    };
  };

  const drawChart = () => {
    const dimensions = getParentDimensions();
    const width = +dimensions.width;
    const height = +dimensions.height;

    const svg = d3.select(chartRef.current)
      .attr('width', dimensions.width)
      .attr('height', dimensions.height)
      .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet');

    svg.selectAll('*').remove(); // Clear any existing rendering in svg

    const group = svg.append('g')
      .attr('class', 'bottomChart')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    drawBackgroundCircle(group, dimensions);
    const donutDrawingData = drawDonutChart(group, data, dimensions);
    drawRadialChart(
      group,
      data,
      donutDrawingData.offsetAngle,
      donutDrawingData.arcAngles,
      dimensions
    );
    drawScoreCircle(group, data, dimensions.height * 0.12);
  };

  return (
    <>
      <section
        id="mirorImage"
        className="flex flex-column gap-5 mt-5 max-w-4xl mx-auto"
      >
        <div className="text-center flex flex-column gap-1">
          <h2 className="text-2xl">Your latest Mirror Image</h2>
          <p className="text-xs opacity-80">
            Last update: {moment(lastUpdatedDate).format("MMM D, yyyy")}
          </p>
        </div>
        <div className='flex flex-column md:flex-row align-items-center gap-4 justify-content-between'>
          <div className="flex flex-column w-4">
            {/*button for dialogs*/}
          </div>
          <svg
            ref={chartRef}
            id='radialChart'
            style={{ fontFamily: "Montserrat", width: '100vw', height: '50vh' }}
          />
          <div className='flex flex-column-reverse md:flex-column text-sm gap-4' >
            <div className='flex flex-row align-items-center justify-content-center flex-wrap md:align-items-start md:flex-column gap-2 w-19rem md:w-auto '>
              <div className='flex gap-2 align-items-center'>
                <div className='orangeColorMirrorImage w-1rem h-1rem text-grey-200 '></div>
                <p>Outstanding</p>
              </div>
              <div className='flex gap-2 align-items-center'>
                <div className='yellowColorMirrorImage w-1rem h-1rem text-grey-200 '></div>
                <p>Great</p>
              </div>
              <div className='flex gap-2 align-items-center'>
                <div className='jasmineColorMirrorImage w-1rem h-1rem text-grey-200 '></div>
                <p>Well</p>
              </div>
              <div className='flex gap-2 align-items-center'>
                <div className='beigeColorMirrorImage w-1rem h-1rem text-grey-200 '></div>
                <p>So So</p>
              </div>
              <div className='flex gap-2 align-items-center'>
                <div className='greyColorMirrorImage w-1rem h-1rem text-grey-200 '></div>
                <p>Not Well</p>
              </div>
              <div className='flex gap-2 align-items-center'>
                <div className='darkGreyColorMirrorImage w-1rem h-1rem text-grey-200 '></div>
                <p>Terribly</p>
              </div>
            </div>
            <div className='w-full'>
              <p>*Rays closer to the center represent more recent reflections</p>
            </div>
          </div>
        </div>
        <Accordion className="max-w-lg mx-auto">
          <AccordionTab header="Analysis" headerClassName="font-semibold">
            <div className="flex flex-column gap-3 text-sm opacity-80 pb-3 m-0">
              {analyses && analyses.map((a, index) => <p key={index}>- {a}</p>)}
            </div>
          </AccordionTab>
        </Accordion>
      </section>

    </>
  )

};

export default RadialMirrorImage;