import { useEffect, useState } from "react";
import { useScreenDetector } from "../../hooks/useScreenDetector";

function Block(props) {
  const [width, setWidth] = useState("9rem"); // Initial width
  const [opacity, setOpacity] = useState(1);
  const startScroll = 200; // Adjust this value to determine when the animation should start
  const limit = 500;
  const { isMobile } = useScreenDetector();

  useEffect(() => {
    const handleScroll = () => {
      const st = window.scrollY;
      if (st > startScroll && st <= limit) {
        // Calculate the new width based on scroll position
        const newWidth = `${Math.min(10.7, (9.1 + (st - startScroll) / (limit - startScroll) * 2).toFixed(1))}rem`;
        setWidth(newWidth);
        setOpacity(1 - (st - startScroll) / (limit - startScroll));
      } else if (st <= startScroll) {
        setWidth("9rem"); // Reset to initial width
        setOpacity(1);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function click_handle(ev) {
    props.click(props.id);
  }

  return (
    <div className="z-1 flex flex-column align-items-center">
      <div
        onClick={click_handle}
        className={`${isMobile && 'w-full'} block-section animation-block ${props.circle && !props.active ? "border-circle w-5rem " : ""
          } ${props.bordered && "border-solid border-900 border-3"} ${props.active
            ? `animated-block  border-noround`
            : "h-5rem border-round-sm"
          } ${props.color}`} style={!isMobile && props.active ? { width: `${width}` } : {width:'5rem'}}
      ></div>
      <span
        style={{ opacity: opacity }}
        className={`my-3 text-sm ${props.circle ? "" : ""}`}
      >
        <p className={`${props.textColor} ${props.bordered && 'font-bold'}`}>{props.text}</p>
      </span>
    </div>
  );
}

export default Block;
