import React from "react";
import sorryDesktop from "../assets/sorry-desktop.png";
import sorryMoible from "../assets/sorry-mobile.png";
import { Button } from "primereact/button";
import Logo from "./Logo";
import backgroundImage from "../assets/auth_bg-full-img.png";
import { Link } from "react-router-dom";

function Maintenance() {
    return (
        <div className="flex h-full flex-column min-h-screen justify-content-between relative lg:gap-0 gap-5">
            <div
                className="absolute w-full h-full bg-cover lg:bg-left-bottom bg-center-bottom z-0"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            ></div>
            <Link
                to="https://www.mymirror360.com/"
                target="_blank"
                className="relative z-2 mt-5 mx-auto mb-auto"
            >
                <Logo />
            </Link>
            <div className="mb-auto align-items-center flex flex-column md:flex-row justify-content-between z-2 gap-8 max-w-6xl mx-auto z-2">
                <div className="flex gap-2 flex-column">
                    <picture>
                        <source
                            media="(max-width: 768px)"
                            srcSet={sorryMoible}
                            sizes="768px"
                        />
                        <source srcSet={sorryDesktop} sizes="1280px" />
                        <img alt="" src={sorryDesktop} />
                    </picture>
                    <p className='text-3xl' sstyle={{fontFamily:'Montserrat'}}>We are under maintenance</p>
                </div>
                <div className="max-w-2xl flex flex-column pl-4 md:pl-auto justify-content-between flex-wrap gap-3 max-w-lg">
                    <div className="w-8 text-base text-justify md:text-left text-xs opacity-80 ">
                        <p className="font-bold">Please bear with us…</p>
                        <p>We’re still in beta and the mirrors needed some quick repairs. We’ll be back shortly!</p>
                    </div>
                    <Link to="https://www.mymirror360.com/" target="_blank">
                        <Button label="Go to Website" outlined></Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Maintenance