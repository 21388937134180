/**
 *
 * @param {Date | string} from
 * @param {Date | string} to
 */
export function calculateDaysDiff(from, to) {
  if (!from || !to) {
    return null;
  }

  const toDate = new Date(to);
  const toDateInUtcMs = Date.UTC(
    toDate.getFullYear(),
    toDate.getMonth(),
    toDate.getDate(),
    toDate.getHours(),
    toDate.getMinutes(),
    toDate.getSeconds(),
    toDate.getMilliseconds()
  );

  const fromDate = new Date(from);
  const fromDateInUtcMs = Date.UTC(
    fromDate.getFullYear(),
    fromDate.getMonth(),
    fromDate.getDate(),
    fromDate.getHours(),
    fromDate.getMinutes(),
    fromDate.getSeconds(),
    fromDate.getMilliseconds()
  );

  const diffInMiliseconds = toDateInUtcMs - fromDateInUtcMs;
  const msInMinute = 60000;
  const msInHour = msInMinute * 60;
  const msInDay = msInHour * 24;

  const daysDiff = Math.round(diffInMiliseconds / msInDay);

  return daysDiff;
}