import { date, object, string } from "yup";
import {
  emailRelationshipvalidator,
  emailValidator,
  inviteCodeValidator,
  nameValidator,
  overallScoreValidator,
  passwordMatchValidator,
  passwordValidator,
  proximityValidator,
  questionValidator,
} from "./field-validators";

export const inviteCodePageSchema = object({
  invitationCode: inviteCodeValidator,
});
export const forgotPasswordSchema = object({
  email: emailValidator,
});
export const loginPageSchema = object({
  email: emailValidator,
  password: passwordValidator,
});
export const signupPageSchema = object({
  email: emailValidator,
  password: passwordValidator,
  name: nameValidator,
});
/**
 *
 * @param {string} passwordFieldName - The name of the field containing the password value to match agains. "password" by default
 * @returns passwordValidator with added check for mathching password.
 */
export const changePasswordSchema = (passworFieldName = "password") =>
  object({
    password: passwordValidator,
    newPassword: passwordValidator,
    confirmPassword: passwordMatchValidator(passworFieldName),
  });
export const addRelationshipSchema = object({
  reflectorEmail: emailRelationshipvalidator,
  reflectorAlias: nameValidator,
  relationshipProximity: proximityValidator,
});
export const updateRelationshipSchema = object({
  reflectorAlias: nameValidator,
  relationshipProximity: proximityValidator,
});
export const selfReflectionSchema = object({
  overallScore: overallScoreValidator,
  q1Score: questionValidator,
  q2Score: questionValidator,
  q3Score: questionValidator,
  q4Score: questionValidator,
  q5Score: questionValidator,
  q6Score: questionValidator,
  q7Score: questionValidator,
});
export const performanceReflectionSchema = object({
  overallScore: overallScoreValidator,
  noteForReflectee: string(),
  q1Score: questionValidator,
  q2Score: questionValidator,
  q3Score: questionValidator,
  q4Score: questionValidator,
  q5Score: questionValidator,
});
export const addOtherEmailSchema = object({
  email: emailValidator,
});
