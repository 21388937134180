import React from "react";
import arrow from "../../assets/arrow-slider.svg";
import QuestionsContainer from "./QuestionsContainer";

const getPercentage = (current, min, max) =>
  (((current - min) / (max - min)) * 100);

const getValue = (percentage, min, max) =>
  ((max - min) / 100) * percentage + min;

const getLeft = (percentage) => `calc(${percentage}% - 10px)`;

const getWidth = (percentage) => `${percentage}%`;

const Slider = ({
  initial,
  min = 0,
  max,
  formatFn = (number) => number.toFixed(0),
  onChange,
  colorBarStart,
  colorBarEnd,
  customChroma,
  isSelf,
  question,
}) => {
  const initialPercentage = getPercentage(initial, min, max);
  const [dragged, setDragged] = React.useState("surface-200");
  const rangeRef = React.useRef();
  const rangeProgressRef = React.useRef();
  const thumbRef = React.useRef();
  const currentRef = React.useRef();
  const diff = React.useRef();

  const handleUpdate = React.useCallback(
    (value, percentage) => {
      thumbRef.current.style.left = getLeft(percentage);
      rangeProgressRef.current.style.width = getWidth(percentage);
      currentRef.current.textContent = formatFn(value);
    },
    [formatFn]
  );

  const handleMove = React.useCallback(
    (clientX) => {
      if (!clientX) {
        clientX = 0;
      }

      const rangeRect = rangeRef.current.getBoundingClientRect();
      let newX = clientX - rangeRect.left;

      const end = rangeRect.width;
      const start = 0;

      if (newX < start) {
        newX = start;
      }

      if (newX > end) {
        newX = end;
      }

      const newPercentage = getPercentage(newX, start, end);
      const newValue = getValue(newPercentage, min, max);

      handleUpdate(newValue, newPercentage);
      onChange(question, newValue);

      const ranges = {};

      for (let i = 0; i <= 1000; i += 1) {
        ranges[i] = customChroma(i).hex();
      }

      const divStyle = {
        backgroundColor: "#EEEEEE",
      };
      for (const range in ranges) {
        const startRange = parseInt(range);
        const endRange = startRange + 1;
        if (newValue >= startRange && newValue <= endRange) {
          divStyle.backgroundColor = ranges[range];
          break;
        }
      }

      rangeRef.current.style.backgroundColor = divStyle.backgroundColor;
    },
    [handleUpdate, min, max, onChange, customChroma, question]
  );

  const handleMouseOrTouchMove = (event) => {
    if (window.innerHeight < window.innerWidth) {
      event.preventDefault();
    }
    setDragged(null);
    handleMove(event.clientX || (event.touches && event.touches[0].clientX));
  };

  const handleMouseOrTouchUp = () => {
    document.removeEventListener("mouseup", handleMouseOrTouchUp);
    document.removeEventListener("touchend", handleMouseOrTouchUp);
    document.removeEventListener("mousemove", handleMouseOrTouchMove, {
      passive: false,
    });
    document.removeEventListener("touchmove", handleMouseOrTouchMove, {
      passive: false,
    });
  };

  const handleMouseDownOrTouchStart = (event) => {
    if (window.innerHeight < window.innerWidth) {
      event.preventDefault();
    }

    const clientX = event.clientX || (event.touches && event.touches[0].clientX);
    setDragged(null);
    handleMove(clientX);
    //diff.current = clientX - thumbRef.current.getBoundingClientRect().left;

    document.addEventListener("mousemove", handleMouseOrTouchMove, {
      passive: false,
    });
    document.addEventListener("touchmove", handleMouseOrTouchMove, {
      passive: false,
    });
    document.addEventListener("mouseup", handleMouseOrTouchUp);
    document.addEventListener("touchend", handleMouseOrTouchUp);
  };

  React.useLayoutEffect(() => {
    handleUpdate(initial, initialPercentage);
  }, [initial, initialPercentage, handleUpdate]);

  return (
    <div className="w-full flex flex-column align-center gap-2 lg:gap-3">
      <QuestionsContainer
        isSelf={isSelf}
        text={question?.content}
        questionId={question?.id}
      />
      <div className="hidden">{formatFn(min)}</div>
      <div className="hidden">
        <strong ref={currentRef} />
        &nbsp;/&nbsp;
        {formatFn(max)}
      </div>
      <input
        className="hidden"
        value={formatFn(max)}
        onChange={(e) => {
          const formattedValue = formatFn(max);
          // console.log(formattedValue);
        }}
      ></input>
      <div
        ref={rangeRef}
        onMouseDown={handleMouseDownOrTouchStart}
        onTouchStart={handleMouseDownOrTouchStart}
        className={`relative w-full h-1rem flex ${dragged}`}
      >
        <div className={`w-2rem ${colorBarStart}`} />
        <div ref={rangeProgressRef} id="test" className="flex-1" />
        <div className={`w-2rem ${colorBarEnd}`} />
        <img
          ref={thumbRef}
          alt="Slider Arrow"
          src={arrow}
          className="slider-arrow"
          onMouseDown={handleMouseDownOrTouchStart}
          onTouchStart={handleMouseDownOrTouchStart}
        />
      </div>
      <div className="w-full flex justify-content-between text-xs lg:text-sm">
        <div>Not at all</div>
        <div>Definitely</div>
      </div>
    </div>
  );
};

export default Slider;
