import React, { useContext, useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
import { UserDataState } from "../store_providers/user-data-store";

function DesktopMenu(props) {
  const [rotate, setRotate] = useState(false);
  const [userData] = useContext(UserDataState);
  const triggerRotate = () => {
    setRotate((prevState) => {
      return !prevState;
    });
  };
  const menuRight = useRef(null);

  return (
    <div
      onClick={triggerRotate}
      className=" cursor-pointer flex align-items-center"
    >
      <div
        className="flex align-items-center"
        onClick={(event) => menuRight.current.toggle(event)}
      >
        <Menu
          model={props.items}
          show={"true"}
          ref={menuRight}
          popup
          id="popup_menu_right"
          popupalignment="right"
          className="text-xs"
        />
        <Avatar
          label={userData.nameInitials}
          className="nav_dropdown-avatar text-xs mr-1 border-circle"
          style={{padding:'1.3rem'}}
          shape="circle"
          aria-controls="popup_menu_right"
          aria-haspopup
        />
        <span className={`${rotate ? "" : "rotate-90"} nav_dropdown-arrow`}>&#9660;</span>
      </div>
    </div>
  );
}

export default DesktopMenu;
