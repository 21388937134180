import React from "react";
import LoginRightSideContent from "./LoginRightSideContent";
import backgroundImage from "../../assets/auth_bg-img.webp";

function LoginRightSide() {
  return (
    <div
      className="w-6 hidden lg:flex flex-column align-items-center justify-content-center bg-cover bg-right-bottom"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <LoginRightSideContent />
    </div>
  );
}

export default LoginRightSide;
