import React from 'react'
import Logo from '../Logo'
import HeaderMenu from './HeaderMenu'
import { Link } from 'react-router-dom'

function Header(props) {
  return (
    <nav style={{backdropFilter: 'blur(2px)'}} className='nav_bar fixed top-0 left-0 right-0 flex align-items-center justify-content-between py-2 px-3 md:px-5'>
      {props.children}
      <Link to='/dashboard/reflectors-overview'><Logo isHeader={true} /></Link>
      <HeaderMenu />
    </nav>
  )
}

export default Header