import { Password } from "primereact/password";
import React, { useEffect, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useFormik } from "formik";
import classNames from "classnames";
import SettingsTabWrapper from "./SettingsTabWrapper";
import { changePasswordSchema } from "../../service/validationschemas/validation-schemas";
import { Button } from "primereact/button";
import { AppHttp } from "../../service/app-http";
import { changePasswordUrl } from "../../service/api-temp";
import { Dialog } from "primereact/dialog";
import { useScreenDetector } from "../../hooks/useScreenDetector";

function PasswordUpdate() {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { isMobile } = useScreenDetector();
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema("newPassword"),
    onSubmit: (values) => {
      setLoading(true)
      AppHttp.post(changePasswordUrl, values).then((result) => {
        if (result.success) {
          setDialogVisible(true);
          formik.resetForm();
          setLoading(false)
        } else {
          setLoading(false)
          console.log(result);
        }
      });
    },
  });

  function onCancelClick() {
    formik.resetForm();
  }

  const isFormFieldInvalid = (msg) =>
    !!(formik.touched[msg] && formik.errors[msg]);

  const getFormErrorMessage = (msg) => {
    return isFormFieldInvalid(msg) ? (
      <small className={`${formik.errors[msg] ? "block" : "hidden"} p-error`}>
        {formik.errors[msg]}
      </small>
    ) : (
      ""
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} className="h-full w-full">
      <SettingsTabWrapper>
        <h2 className="py-0 lg:py-2 text-sm font-normal">
          Update Your Password
        </h2>
        <hr className="w-full px-5 opacity-20" />
        <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
          <label className="w-full lg:w-16rem font-semibold">
            Current Password
          </label>
          <div className="w-full max-w-md flex flex-column gap-3 align-items-start p-input-icon-left">
            <Password
              className={
                "w-full " +
                classNames({
                  "p-invalid": !!formik.errors.password,
                })
              }
              inputId="OldPassword"
              type="password"
              aria-label="password field"
              name="OldPassword"
              value={formik.values.password}
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value);
              }}
              hideIcon={<VisibilityOffIcon className="text-base" />}
              showIcon={<VisibilityIcon className="text-base" />}
              feedback={false}
              toggleMask
              onFocus={function (e) {
                var val = e.target.value;
                e.target.value = "";
                e.target.value = val;
              }}
            />
            {getFormErrorMessage("password")}
          </div>
        </div>
        <hr className="w-full px-5 opacity-20" />
        <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
          <label className="w-full lg:w-16rem font-semibold">
            New Password
          </label>
          <div className="w-full max-w-md flex flex-column gap-3 align-items-start p-input-icon-left">
            <Password
              className={
                "w-full " +
                classNames({
                  "p-invalid": !!formik.errors.newPassword,
                })
              }
              inputId="NewPassword"
              type="password"
              aria-label="password field"
              name="NewPassword"
              value={formik.values.newPassword}
              onChange={(e) => {
                formik.setFieldValue("newPassword", e.target.value);
              }}
              hideIcon={<VisibilityOffIcon className="text-base" />}
              showIcon={<VisibilityIcon className="text-base" />}
              feedback={false}
              toggleMask
              onFocus={function (e) {
                var val = e.target.value;
                e.target.value = "";
                e.target.value = val;
              }}
            />
            {getFormErrorMessage("newPassword")}
          </div>
        </div>
        <hr className="w-full px-5 opacity-20" />
        <div className="flex flex-column lg:flex-row gap-3 lg:gap-5">
          <label className="w-full lg:w-16rem font-semibold">
            Confirm New Password
          </label>
          <div className="w-full max-w-md flex flex-column gap-3 align-items-start p-input-icon-left">
            <Password
              className={
                "w-full " +
                classNames({
                  "p-invalid": !!formik.errors.confirmPassword,
                })
              }
              inputId="ConfirmNewPassword"
              type="password"
              aria-label="password field"
              name="ConfirmNewPassword"
              value={formik.values.confirmPassword}
              onChange={(e) => {
                formik.setFieldValue("confirmPassword", e.target.value);
              }}
              hideIcon={<VisibilityOffIcon className="text-base" />}
              showIcon={<VisibilityIcon className="text-base" />}
              feedback={false}
              toggleMask
              onFocus={function (e) {
                var val = e.target.value;
                e.target.value = "";
                e.target.value = val;
              }}
            />
            {getFormErrorMessage("confirmPassword")}
          </div>
        </div>
        <hr className="w-full px-5 opacity-20 mt-4 lg:mt-0" />
        <div className="button-group max-w-xs gap-2 lg:ml-auto">
          <Button
            type="button"
            label="Cancel"
            outlined
            onClick={onCancelClick}
            
          ></Button>
          <Button type="submit"  loading={loading} label="Save"></Button>
        </div>
      </SettingsTabWrapper>
      <Dialog
        position={isMobile ? "bottom" : "center"}
        closable={true}
        headerStyle={{ padding: "0" }}
        visible={dialogVisible}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setDialogVisible(false)}
      >
        <div className="flex flex-column gap-3 text-center align-items-center p-5 pb-2 ">
          <p className="font-bold text-2xl">Your password has been reset</p>
          <div className="w-full flex justify-content-center">
            <Button label="Ok"  onClick={() => setDialogVisible(false)}></Button>
          </div>
        </div>
      </Dialog>
    </form>
  );
}

export default PasswordUpdate;
